.hero-conversation-home {
    position: relative;

    .hero__title {
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 1.1;
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
            font-size: 4.5rem;
        }
        @media screen and (min-width: 1560px) {
            font-size: 5.6rem;
        }
    }
    .hero__subtitle {
        font-size: 1.8rem;
        display: inline-block;
        font-weight: 600;
        margin-top: 1.7rem;
        line-height: 1.44;
        @include media-breakpoint-up(md) {
            max-width: 59.2rem;
            margin-top: 2.4rem;
            font-size: 2.4rem;
        }
    }
    .btn {
        margin-top: 2.4rem;
        margin-bottom: 0rem;
    }
    .illustration-wrapper {
        @include media-breakpoint-up(lg) {
            height: 540px;
        }
    }
    .btn-scroll-wrapper {
        bottom: -2.4rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
        display: none;
        position: absolute;
        @include media-breakpoint-up(md) {
            display: flex;
        }
    }
}
.circle {
    position: absolute;
    &--hero {
        width: 100%;
        height: 600px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.icon-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.4rem;
    transition: $transition-time;
    &--dark {
        background-color: $purple-color;
    }
    &--light {
        background-color: $violet-lightest;
    }
    &--green {
        background-color: $green-light;
    }
    img {
        width: 25px;
        height: 25px;
    }
}

.ill-home {
    margin-top: 7.2rem;
}

.ill-fuel {
    width: 200%;
    position: relative;
    left: -50%;
    @include media-breakpoint-up(md) {
        width: 100%;
        left: 0;
    }
}

.summary-items {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include media-breakpoint-up(md) {
        margin-top: 4.8rem;
    }
    &__single {
        width: 50%;
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 2.4rem;
        @include media-breakpoint-up(md) {
            width: 33.33%;
        }
        @include media-breakpoint-up(lg) {
            width: 20%;
        }
        &:first-of-type {
            width: 100%;
            @include media-breakpoint-up(md) {
                width: 33.33%;
            }
            @include media-breakpoint-up(lg) {
                width: 20%;
            }
        }
        &:nth-of-type(2) {
            .summary-items__dots {
                div {
                    &:nth-of-type(-n + 3) { 
                        display: none;
                        @include media-breakpoint-up(md) {
                            display: block;
                        }
                    }
                }
                             
            }
        }
        &:nth-of-type(4) {
            .summary-items__dots {
                div {
                    &:nth-of-type(-n + 3) { 
                        display: none;
                        @include media-breakpoint-up(lg) {
                            display: block;
                        }
                    }
                }
                              
            }
        }
        &:nth-of-type(3) {
            .summary-items__dots {
                div {
                    &:nth-of-type(-n + 7) { 
                        display: none;
                        @include media-breakpoint-up(lg) {
                            display: block;
                        }
                    }
                }
                              
            }
        }
        &:last-of-type {
            .summary-items__dots {
                div {
                    &:last-of-type { 
                        display: none;
                        @include media-breakpoint-up(lg) {
                            display: block;
                        }
                    }
                }
                              
            }
        }
    }

    span {
        font-weight: 600;
        text-align: center;
        display: block;
        max-width: 22rem;
        line-height: 1.44;
    }

    &__dots {
        margin-bottom: 2.4rem;
        div {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            &:not(:last-of-type) {
                margin-bottom: 4px;
            }
            &:nth-of-type(1) {
                animation: sumDotFlashing 1s infinite linear alternate;
                animation-delay: 0.5s;
            }
            &:nth-of-type(2) {
                animation: sumDotFlashing 1s infinite linear alternate;
                animation-delay: 1s;
            }
            &:nth-of-type(3) {
                animation: sumDotFlashing 1s infinite linear alternate;
                animation-delay: 1.5s;
            }
            &:nth-of-type(4) {
                animation: sumDotFlashing 1s infinite linear alternate;
                animation-delay: 2s;
            }
            &:nth-of-type(5) {
                animation: sumDotFlashing 1s infinite linear alternate;
                animation-delay: 2.5s;
            }
            &:nth-of-type(6) {
                animation: sumDotFlashing 1s infinite linear alternate;
                animation-delay: 3s;
            }
            &:nth-of-type(7) {
                animation: sumDotFlashing 1s infinite linear alternate;
                animation-delay: 3.5s;
            }
            &:nth-of-type(8) {
                animation: sumDotFlashing 1s infinite linear alternate;
                animation-delay: 4s;
            }
            &:nth-of-type(9) {
                animation: sumDotFlashing 1s infinite linear alternate;
                animation-delay: 4.5s;
            }
        }
    }

}

@keyframes sumDotFlashing {
    0% {
        background-color: #F2C2DA;
    }
    50%,
    100% {
        background-color: $violet-lightest;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.conversations { 
    position: relative;
    margin-top: 4.8rem;
    @include media-breakpoint-up(md) {
        padding-top: 6.4rem;
        margin-top: 7.2rem;
        margin-bottom: 6.4rem;
    }
    @include media-breakpoint-up(lg) {
        padding-top: 12rem;
        margin-top: 12rem;
        margin-bottom: 9.6rem;
    }
    &::before {
        content: '';
        width: calc(100% - 16px);
        max-width: 50.3rem;
        height: 38.5rem;
        position: absolute;
        top: -16px;
        background-color: #F1F3FA;
        border-radius: 0.8rem;
        left: 8px;
        @include media-breakpoint-up(lg) {
            left: -6.4rem;
            top: 0;
        }
        @include media-breakpoint-up(xl) {
            width: 50%;
            height: 70.5rem;
        }

        @media screen and (min-width: 1367px) {
            left: -11.4rem;

        }
    }
    a {
        margin-bottom: 2.4rem;
        display: block;
    }
    &__link {

        &:first-of-type {
            .conversations__box {
                border-color: $lightest;
                box-shadow: 0px 12px 32px 0px rgba(61, 67, 89, 0.08);
            }
            .conversations__link-wrapper {
                div {
                    @include media-breakpoint-down(md) {
                        background-color: $primary;
                    }
                }
            }
        }
        &:nth-child(even) {
            .conversations__box {
                @include media-breakpoint-down(lg) {
                    margin-right: auto;
                }
            }
        }  
        &:nth-child(odd) {
            .conversations__box {
                @include media-breakpoint-down(lg) {
                    margin-right: auto;
                }
            }
        }        
        &:nth-child(3n+2) {
            .conversations__box {
                @include media-breakpoint-up(lg) {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
        &:nth-child(3n+3) {
            .conversations__box {
                @include media-breakpoint-up(lg) {
                    margin-left: auto;
                    margin-right: unset;
                }
            }
        }
        &:hover {
            text-decoration: none;
        }
    }
    &__box {
        background-color: $lightest;
        padding: 3.2rem;
        border-radius: 8px;
        border: 1px solid #EBEEF9;
        transition: 0.3s;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include media-breakpoint-up(lg) {
            width: calc(100% - 1.4rem);
        }
        @include media-breakpoint-up(lg) {
            padding: 4.8rem;
        }
        &:hover {
            @include media-breakpoint-up(md) {
                border-color: $lightest;
                box-shadow: 0px 12px 32px 0px rgba(61, 67, 89, 0.08);
            }
            .conversations__link-wrapper {
                div {
                    @include media-breakpoint-up(md) {
                        background-color: $primary;
                    }
                }
            }
        }
        .box-soul {
            width: 100%;
            height: 100%;
        }
    }
    &__box-soul {
        transition: 0.3s;
        width: 100%;
        height: 100%;

        @include media-breakpoint-up(lg) {
            width: calc(100% - 1.4rem);
        }

        .box-soul {
            width: 100%;
            height: 100%;
        }
    }
    &__box-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3.2rem;
        @include media-breakpoint-up(lg) {
            margin-bottom: 4.8rem;
        }
    }
    &__box-img {
        position: relative;
        padding-right: 4rem;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            display: block;
            background-image: url("../images/blockquote.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            width: 4.8rem;
            height: 4.8rem;
            z-index: 2;
            right: 0;
        }
        img {
            width: 8rem;
            height: 8rem;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    &__box-time {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: flex-end;
        .tag-time,
        .tag-new {
            font-size: 1.2rem;
            letter-spacing: 0.16rem;
            font-weight: 700;
            padding: 0.85rem 1.6rem;
            border-radius: 0.8rem;
            line-height: 1;
        }
        .tag-time {
            color: #808080;
            border: 1px solid #EBEEF9;
        }
        .tag-new {
            color: $lightest;
            border: 1px solid $primary;
            background-color: $primary;
            margin-left: 0.8rem;
            display: none;
            @include media-breakpoint-up(lg) {
                display: flex;
            }
        }
    }
    &__box-text {
        font-size: 2.4rem;
        line-height: 1.25;
        position: relative;
        color: $main-font-color;
        font-style: italic;
        margin-bottom: 3.2rem;
        font-weight: 500;
        p {
            margin-bottom: 0;
        }
        @include media-breakpoint-up(lg) {
            padding-left: 2.4rem;
            margin-bottom: 4.8rem;
        }
        &::before {
            content: '';
            width: 4px;
            height: 100%;
            background-color: $green-light;
            left: 0;
            position: absolute;
            display: block;
            border-radius: 0.8rem;
            display: none;
            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
    }
    &__box-name {
        .name {
            font-size: 2.4rem;
            line-height: 1.25;
            font-weight: 700;
            color: $main-font-color;
            margin-bottom: 0.8rem;
        }
        .occupation {
            color: $font-lighter;
            font-size: 1.2rem;
            letter-spacing: 0.16rem;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
    &__link-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        span {
            font-weight: 600;
            color: $main-font-color;
            font-size: 1.8rem;
            padding-left: 2.4rem;
            position: relative;
            &::before {
                content: '';
                background-image: url("../images/arrow-black.svg");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 1.6rem;
                height: 1.6rem;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        div {
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-self: center; 
            background-color: #7386E5;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-image: url('../images/arrow-right-white.svg');
            background-position: center;
            background-size: 16px;
            transition: 0.4s;
        }
    }
}

.soul-boxes {
    @include media-breakpoint-up(md) {
        margin-top: 9.6rem;
    }
    & > div {
        &:nth-of-type(1),
        &:nth-of-type(2) {
            display: none;
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
        &:nth-of-type(3) {
            @include media-breakpoint-up(md) {
                display: none;
            }
            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
        &:nth-of-type(1) {
            .conversations__box-soul {
                @include media-breakpoint-up(md) {
                    margin-right: auto;
                }
            }
        }
        &:nth-of-type(2) {
            .conversations__box-soul {
                @include media-breakpoint-up(md) {
                    margin-left: auto;
                }
                @include media-breakpoint-up(lg) {
                    margin-right: auto;
                }
            }
        }
        &:nth-of-type(3) {
            .conversations__box-soul {
                @include media-breakpoint-up(md) {
                    margin-left: auto;
                }
            }
        }
    }
    
}

.fuel-boxes {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    padding-bottom: 12rem;
    position: relative;    

    &:before {
        content: '';
        width: calc(100% - 48px);
        height: 2px;
        bottom: 15.6rem;
        position: absolute;
        background-color: #f1f3fa;
        left: 50%;
        transform: translateX(-50%);
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }
    &__single {
        position: relative;
        border: none;
        background-color: none;
        box-shadow: none;
        padding: 0;
        display: block;
        text-align: left;
        width: 100%;
        max-width: 44.1rem;
        margin-left: auto;
        margin-right: auto;
        background-color: transparent;
        @include media-breakpoint-up(lg) {
            width: calc(50% - 1.2rem);
            max-width: unset;
            margin-left: unset;
            margin-right: unset;
        }

        
        &:first-child {
            margin-bottom: 2.4rem;
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
            .fuel-wrapper {
                background-image: url("../images/fuel_ill_1_mobile.svg");
                background-position: calc(100% - 28px) 21px;
                background-repeat: no-repeat;
                background-size: 115px;

                @include media-breakpoint-up(xl) {
                    background-size: 35%;
                }

                @media screen and (min-width: 1760px) {
                    background-image: url("../images/fuel_ill_1.svg");
                }
            }

        }
        &:nth-child(2) {
            .fuel-wrapper {
                background-image: url("../images/fuel_ill_2.svg");
                background-position: calc(100% - 28px) 21px;
                background-repeat: no-repeat;
                background-size: 112px;

                @include media-breakpoint-up(md) {
                    background-size: 35%;
                }
            }
        }
        .section__subtitle {
            color: black;
            font-weight: 700;
        }

        .fuel-wrapper {
            width: 100%;
            height: 100%;
            z-index: 6;
            box-shadow: 0px 12px 32px 0px rgba(61, 67, 89, 0.08);
            border-radius: 0.8rem;
            background-color: $lightest;
            padding: 3.2rem;
            width: 100%;
            @include media-breakpoint-up(lg) {
                padding: 4.8rem;
            }
        }

        .bottom-layer1 {
            bottom: -16px;
            height: 7px;
            width: calc(100% - 16px);
            @include media-breakpoint-up(md) {
                bottom: -20px;
                height: 13px;
            }
            position: absolute;
            bottom: -7px;
            background-color: #e5d0a7;
            border-radius: 3px 3px 8px 8px;
            width: calc(100% - 16px);
            left: 50%;
            transform: translateX(-50%);
            height: 7px;
            transition: 0.3s;
            z-index: -2;
            opacity: 0;

            @include media-breakpoint-up(md) {
                bottom: -10px;
                height: 13px;
            }
        }
        .bottom-layer2 {
            bottom: -22px;
            width: calc(100% - 24px);
            @include media-breakpoint-up(md) {
                height: 12px;
                bottom: -29px;
            }
            position: absolute;
            height: 7px;
            background-color: rgba(255, 187, 51, 0.1);
            border-radius: 3px 3px 8px 8px;
            bottom: -14px;
            width: calc(100% - 28px);
            left: 50%;
            transform: translateX(-50%);
            transition: 0.3s;
            z-index: -3;
            opacity: 0;

            @include media-breakpoint-up(md) {
                height: 12px;
                bottom: -18px;
            }
        }
        &:hover {
            text-decoration: none;
            .btn {
                background-color: $primary;
            }
            .icon-wrapper {
                background-color: #7386e5;
            }
            .section__subtitle {
                color: black;
            }
            .bottom-layer1,
            .bottom-layer2 {
                @include media-breakpoint-up(md) {
                    opacity: 1;
                }
            }
        }
    }
}

.new-conversations {
    @include media-breakpoint-up(xl) {
        margin-top: 21.6rem;
    }
}


// CONVERSATION SINGLE
.conversations-single {
    padding-top: 0;

    .btn-scroll-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    }
    .footer {
        &--buttons {
            margin-top: 2.4rem;
            div {
                display: flex;
                align-items: center;
                justify-content: center;
                &:first-of-type {

                    @include media-breakpoint-up(md) {
                        justify-content: flex-end;
                        padding-right: 2.4rem;
                    }
                }
                &:nth-of-type(2) {
                    margin-top: 2.4rem;
                    transform: translateX(1.5rem);
                    // margin-bottom: 3.2rem;

                    @include media-breakpoint-up(md) {
                        margin-top: 0;
                        justify-content: flex-start;
                        padding-left: 2.4rem;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .border-row {
            &:before {
                bottom: 5.6rem;
                top: unset;
                @include media-breakpoint-up(lg) {
                    display: block;
                    top: unset;
                    bottom: 16.6rem;
                }
            }

        }
        
    }

    .dol {
        margin-bottom: 5.4rem;
  
        @include media-breakpoint-up(lg) {
            margin-bottom: 11.8rem;
        }
    }

    .fuel-boxes {
        padding-top: 2.4rem;
        @include media-breakpoint-up(lg) {
            margin-top: 9.6rem;
        }
        &:before {
            bottom: 5.6rem;
        }
    }
}

.conversation-hero {
    padding-top: 9.6rem;
    position: relative;

    @include media-breakpoint-up(xl) {
        padding-top: 19.2rem;
    }

    .fp-tableCell {
        @include media-breakpoint-up(lg) {
            display: block!important;
        }
    }

    &__img {
        width: 9.6rem;
        height: 9.6rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        border-radius: 50%;

        @include media-breakpoint-up(lg) {
            width: 16.2rem;
            height: 16.2rem;
        }

        @media screen and (min-width: 1760px) {
            width: 19.2rem;
            height: 19.2rem;
        }

    }
    .hero__title {
        margin-bottom: 0.8rem;
        text-align: center;
        font-weight: bold;
        @include media-breakpoint-up(md) {
            margin-bottom: 2.4rem;
        }
    }
    .section__subtitle {
        font-size: 1.8rem;
        line-height: 1.44;
        text-align: center;
        margin-bottom: 2.4rem;
        span {
            color: #7386E5;
        }

        @include media-breakpoint-up(md) {
            font-size: 3.2rem;
            line-height: 1.125;
            margin-bottom: 6.4rem;
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;

        }
    }
    &--circles {
        top: -11rem;
        z-index: -1;
        width: 57.6rem!important;
        height: 57.6rem!important;
        left: calc(50% - 28.8rem);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: -15rem;
            width: 85rem!important;
            height: 85rem!important;
            left: 50%;
            transform: translateX(-50%);
        }

        @include media-breakpoint-up(xl) {
            top: -36rem;
            width: 110rem!important;
            height: 110rem!important;
        }

        // @media screen and (min-width: 1920px) and (min-height: 768px) {
        //     top: -50rem;
        //     // top: -36rem;
        //     width: 100%!important;
        //     height: auto !important;
        //     min-height: 100%!important;
        //     max-height: 1360px!important;
        //     max-width: 1360px!important;
        // }

        @media screen and (min-width: 1920px) and (min-height: 1080px) {
            // top: -50rem;
            top: -42rem;
            width: 100%!important;
            height: auto !important;
            min-height: 100%!important;
            max-height: 1360px!important;
            max-width: 1360px!important;
        }

        svg {
            height: unset!important;
        }
    }
    &__text {
        position: relative;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__items {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 4.4rem;
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }
    &__items-half {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @include media-breakpoint-up(md) {
            align-items: flex-start;
            margin-bottom: 2.4rem;
        }
        @include media-breakpoint-up(lg) {
            width: calc(50% - 4.8rem);
            align-items: flex-start;
        }
        &:first-of-type {
            .conversation-hero__items-single {
                &:nth-of-type(1) {
                    .conversation-hero__number {
                        background-color: $primary;
                    }
                }
                &:nth-of-type(2) {
                    @include media-breakpoint-up(lg) {
                        margin-top: 4.8rem;
                    }
                    @media screen and (min-width: 1460px) and (min-height: 1080px) {
                        margin-top: 10.8rem;
                    }
                    .conversation-hero__number {
                        background-color: $purple-color;
                    } 
                } 
            }
        }

        &:nth-of-type(2) {
            .conversation-hero__items-single {
                &:nth-of-type(1) {
                    @include media-breakpoint-up(lg) {
                        margin-top: 6.6rem;
                    }
                    @media screen and (min-width: 1460px) and (min-height: 1080px) {
                        margin-top: 12.6rem;
                    }
                    .conversation-hero__number {
                        background-color: $green-light;
                    }
                }
                &:nth-of-type(2) {
                    .conversation-hero__number {
                        background-color: $violet-light;
                    } 
                }               
            }
        }
    }
    &__items-single {
        width: 100%;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding: 0 1.6rem;
        font-size: 1.8rem;
        line-height: 1.44;
        margin-bottom: 2.4rem;
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(md) {
            width: calc(50% - 24px);
            max-width: 29.2rem;
            min-height: 21rem;
            font-size: 2rem;
            background-color: $lightest;
            box-shadow: 0px 12px 32px 0px rgba(61, 67, 89, 0.08);
            border-radius: 0.8rem;
            line-height: 1.3;
            flex-direction: column;
            // align-items: center;
            padding: 1.6rem;
            height: 100%;
        }
        
        @include media-breakpoint-up(lg) {
            align-items: flex-start;
            height: auto;
        }

        @media screen and (min-width: 1199px) and (max-height: 768px) {
            padding: 1.6rem;
        }

        @include media-breakpoint-up(xl) {
            padding: 3.2rem;
        }

        @media screen and (min-width: 1560px) {
            padding: 4.8rem;
        }

        &:not(:last-of-type) {
            @include media-breakpoint-up(md) {
                margin-bottom: 1.6rem;
            }
        }

        p {
            padding-left: 1.6rem;
            width: calc(100% - 5.6rem);
            margin-bottom: 0;
            @include media-breakpoint-up(md) {
               text-align: center;
            }
            @include media-breakpoint-up(lg) {
                text-align: left;
                padding-left: 0;
                width: 100%;
            }
        }
    }
    &__number {
        color: $lightest;
        width: 4rem;
        height: 4rem;
        font-size: 1.8rem;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        @include media-breakpoint-up(md) {
            margin-bottom: 2.4rem;
        }
    }

    .dots-wrapper--fullpage {
        bottom: 3.6rem;
    }
}

.conversation-content-wrapper {
    position: relative;
    padding-top: 25rem;
    height: 100%;
    background-image: url('../images/conversation-mountains.svg');
    background-position: bottom center;
    background-size: 200%;
    background-repeat: no-repeat;
    padding-bottom: 9.6rem;
    max-width: 1360px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(lg) {
        padding-top: 36rem;
        background-size: 150%;
    }

    @include media-breakpoint-up(xl) {
        background-size: 100%;
        padding-bottom: 15rem;
    }

    @media screen and (min-width: 1199px) and (max-height: 768px) {
        padding-top: 30rem;
    }

    @media screen and (min-width: 1199px) and (max-height: 1080px) {
        padding-top: 33rem;
    }

    @media screen and (min-width: 1920px) and (min-height: 1080px) {
        padding-top: 40.6rem;
    }

    .container {
        height: 100%;
        @media screen and (min-width: 1921px) {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

    }
    
}

.conversation-top {
    overflow: hidden;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-up(lg) {
        min-height: 100vh;

        height: 100%;
        width: 100%;
    }
}

.fp-tableCell {
    vertical-align: top;
}

.row--interview {
    @media screen and (min-width: 992px) and (max-height: 768px) {
        padding-top: 12rem;
        // padding-bottom: 14rem;
        padding-bottom: 25rem;
    }
    @media screen and (min-width: 992px) and (min-height: 768px) {
        padding-top: 12rem;
        // padding-bottom: 18rem;
        padding-bottom: 30rem;
    }

    @media screen and (min-width: 1920px) {
        padding-top: 16rem;
    }
}

.interview {
    padding-top: unset;
    .bg-wrapper {
        right: 0;
        position: absolute;
    }
    .conversations__box {
        max-width: 29.2rem;
        position: relative;
        border: none;
        box-shadow: 0px 12px 32px 0px rgba(61, 67, 89, 0.08);
        height: auto;
        &::before {
            content: '';
            display: block;
            background-color: #F1F3FA;
            border-radius: 8px;
            width: 115%;
            left: -11.4rem;
            position: absolute;
            height: calc(100% + 9.6rem);
            top: 50%;
            z-index: -1;
            transform: translateY(-50%);
        }
    }

    .conversations__box-header {
        @include media-breakpoint-up(lg) {
            margin-bottom: 2.4rem;
        }
    }

    .conversations__link-wrapper {
        margin-top: 2.4rem;
    }
    .question {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1.44;
        border: 2px solid #EBEEF9;
        border-radius: 0.8rem;
        margin-bottom: 4rem;
        position: relative;
        max-width: 90.7rem;
        background-color: white;
        padding: 3.2rem;
        @include media-breakpoint-up(xl) {
            margin-bottom: 6.4rem;
            padding: 4.8rem 5rem 6.4rem 14.4rem;
            font-size: 2rem;
            font-weight: 600;
            line-height: 1.3;
        }
        &:after {
            content: '';
            width: 10px;
            height: 10px;
            display: block;
            width: 27px;
            height: 24px;
            bottom: -21px;
            left: 21px;
            position: absolute;
            background-image: url('../images/chat.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        p {
            margin-bottom: 0;
            @include media-breakpoint-up(lg) {
                margin-top: 1rem;
            }
        }
    }

    .logo {
        width: 48px;
        height: 48px;
        background-color: #F1F3FA;
        background-image: url('../images/logo_atlantis_blue.svg');
        background-size: 24px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        position: relative;
        top: 0;
        left: 0;
        margin-bottom: 1.6rem;
        @include media-breakpoint-up(xl) {
            position: absolute;
            top: 4.8rem;
            left: 4.8rem;
            margin-bottom: 0;
        }
    }

    .answer {
        position: relative;
        line-height: 1.25;
        font-size: 1.8rem;
        line-height: 1.44;
        padding-left: 3.2rem;
        font-weight: 600;
        @include media-breakpoint-up(xl) {
            padding-left: 4.8rem;
            font-size: 2rem;
            font-weight: 500;
        }
        @media screen and (min-width: 1760px) {
            font-size: 2.4rem;
        }
        &:before {
            content: '';
            width: 4px;
            height: 100%;
            background-color: #62d9d9;
            left: 0;
            position: absolute;
            display: block;
            border-radius: 0.8rem;
        }
        &__wrapper {
            @include media-breakpoint-up(xl) {
                display: flex;
                padding-right: 9.6rem;
            }

            @media screen and (min-width: 1366px) {
                padding-right: 0;
            }
        }

        &__photo {
            padding: 0 3.2rem; 
            margin-bottom: 1.6rem;
            @include media-breakpoint-up(xl) {
                text-align: center;
                margin-bottom: 0;
                padding: 0 4.8rem; 

            }
            img {
                width: 48px;
                height: 48px;
                border-radius: 50%;
            }
        }
        p {
            &:not(:last-of-type) {
                margin-bottom: 3.2rem;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

}

#fp-nav.fp-right{
    @include media-breakpoint-up(lg) {
        right: 3.2rem;
    }
    @media screen and (min-width: 1920px) {
        right: 7.2rem;
    }
    ul {
        position: relative;

        li {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: rgba(115, 134, 229, 0.5);
            margin: 16px;
            transition: 0.3s;
            &:hover {
                background-color: $primary;
            }
            span {
                opacity: 0;
            }
            &:first-of-type {
                display: none;
            }
            &:last-child {
                position: relative;

                a {
                    position: relative;
                }

                a:after {
                    content: '';
                    display: block;
                    width: 40px;
                    height: 40px;
                    background-image: url("../images/icons/icon_flag2.svg");
                    background-size: 16px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-color: #EBEEF9;
                    bottom: -30px;
                    left: 50%;
                    transform: translateX(-50%);
                    position: absolute;
                    border-radius: 50%;
                    transition: 0.3s;

                }

                .active:before {
                    display: none;
                }

                .active::after {
                    background-image: url("../images/icons/icon_flag.svg");
                    background-color: #7386E5;
               
                }
            }

        }
        
        .active {
            position: relative;
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 1;
                width: 16px;
                height: 16px;
                background-color: #7386E5;
                border-radius: 50%;
            }
        
        }
    }

}

.waves-wrapper {
    position: absolute;
    bottom: 0;
    width: 280%;
    bottom: 0;
    left: -100%;
    @include media-breakpoint-up(sm) {
        width: 200%;
        left: -50%;
        bottom: 15%;
    }
    @include media-breakpoint-up(md) {
        width: 100%;
        left: -2%;
        bottom: 0;
    }
}

.interview {
    position: relative;
    .dots-wrapper {
        position: relative;
        @media screen and (min-width: 992px) and (min-height: 768px) {
            top: 100%;
        }
    }
}

.btn-wrapper,
.footer--buttons {
    .btn--darkest {
        position: relative;
        padding-left: 6.4rem;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 4.5rem;
            display: block;
            width: 13px;
            height: 9px;
            background-image: url('../images/arrow-left-small.svg');
            background-repeat: no-repeat;
            background-size: contain;
            transition: $transition-time;
        }
        &:hover {
            &:before {
                left: 4rem;
            }
        }
    }
    .btn--person {
        position: relative;
        padding-right: 6.4rem;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
            right: 4.5rem;
            display: block;
            width: 13px;
            height: 9px;
            background-image: url('../images/arrow-left-small.svg');
            background-repeat: no-repeat;
            background-size: contain;
            transition: $transition-time;
        }

        img {
            display: inline-block;
            width: 6rem;
            height: 6rem;
            border-radius: 50%;
            position: absolute;
            object-fit: cover;
            z-index: 1;
            left: -3rem;
            top: 50%;
            transform: translateY(-50%);
        }

        &:hover {
            &:before {
                right: 4rem;
            }
        }
    }

    .btn {
        @include media-breakpoint-up(lg) {
            padding: 1.7rem 5.8rem;
        }
        @include media-breakpoint-up(xl) {
            padding: 2.25rem 5.8rem;
        }

    }
}

.btn-wrapper {
    justify-content: space-between;
    position: fixed;
    align-items: center;
    bottom: 0;
    padding-top: 2.4rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    background-color: transparent;
    min-width: 100%;
    transition: 0.3s;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 2.4rem;
    display: none;

    @include media-breakpoint-up(lg) {
        display: flex;
        padding-left: 45px;
        padding-right: 45px;
    }

    // @media (min-width: 1460px) and (min-height: 768px) {
    //     padding-bottom: 5.6rem;
    // }

    @media (min-width: 1460px) and (min-height: 1080px) {
        padding-bottom: 5.6rem;
    }

    &--mobile {
        left: 50%;
        transform: translateX(-50%);
        animation: bounce 2s ease infinite;
        display: flex;
        justify-content: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        @include media-breakpoint-up(sm) {
            margin-top: 3.2rem;
        }
        .btn-scroll-wrapper {
            animation: none;
            
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

#fp-nav {
    display: block;
}

.fp-viewing-0 {
    #fp-nav {
        opacity: 0;
        pointer-events: none;
        display: none;
    }
}

@keyframes iconosani {
    0% {
        height: 0;
        opacity: 1;
        bottom: 0;
    } 
    99% {
        height: 100%;
    }
    100% {
        opacity: 1;
        bottom: 100%;
        // -webkit-transform: translate(0, -100%);
        // -ms-transform: translate(0, -100%);
        // -moz-transform: translate(0, -100%);
        // transform: translate(0, -100%);
    }
}

.curtain {
    position: fixed;
    bottom: 0;
    z-index: 100;
    background-color: #7386E5;
    width: 100%;
    transition: 1.5s;
    &.active {
        animation-name: iconosani;
        animation-fill-mode: forwards;
        animation-duration: 1.5s;
    }
  }


  .fullpage-wrapper {
      .footer {
          margin-top: 0;
      }

      .container-content {
        padding-top: 7.2rem;
    }

      .section {

        &:not(:first-of-type) {

            &:before {
                content:'';
                top: 3.4rem;
                width: 8px;
                height: 8px;
                position: absolute;
                background-color: #7386E5;
                opacity: 0.5;
                border-radius: 50%;
                z-index: 2;
                left: calc(50% - 4px);
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
        }

        &:nth-last-child(1) {
            .container-content {
                padding-top: 9.2rem;
                @include media-breakpoint-up(lg) {
                    padding-top: 6.4rem;
                }
            }
            &:before {
                content: '';
                display: block;
                width: 40px;
                height: 40px;
                background-image: url("../images/icons/icon_flag2.svg");
                background-size: 16px;
                background-repeat: no-repeat;
                background-position: center;
                background-color: #EBEEF9;
                left: 50%;
                top: 2.4rem;
                transform: translateX(-50%);
                position: absolute;
                border-radius: 50%;
                transition: 0.3s;
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
        }

          &:nth-of-type(2) {
            .container-content {
                padding-top: 9.2rem;
                @include media-breakpoint-up(lg) {
                    padding-top: 4.8rem;
                }
            }
          }

          &:last-of-type {
              .row--interview {
                  padding-bottom: 5rem;
              };
          }
      }

      .interview {
        .conversations__box {
            display: none;
            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
      }
  }


.fp-responsive .conversation-hero.fp-section.fp-auto-height-responsive {
    @include media-breakpoint-down(lg) {
        margin-bottom: 0;
    }
}

.fp-responsive .conversation-hero.fp-auto-height-responsive .fp-tableCell {
    @include media-breakpoint-down(lg) {
        display: block !important;
    }
}

.polygon-mobile {
    position: absolute;
    top: 0;
    right: -20%;
    height: 350px;
    width: 400px;
}


.page-conversations {
    .fuel-boxes:before {
        bottom: 5.6rem;
    }
}

