.hero-pricing {
  margin-bottom: 12.5rem;
  .dots-wrapper {
    @include media-breakpoint-down(lg) {
      bottom: 0;
    }
  }
}
.icon-box-list {
  margin-top: 4.5rem;
  &__item {
    margin-bottom: 2.5rem;
  }
  &__title {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 1.2rem;
    margin-top: 6px;
  }
  &__text {
    @include media-breakpoint-up(xl) {
      margin-left: 2rem;
    }
    p {
      font-weight: 600;
      font-size: 1.8rem;
    }
  }
}
.icon-list-top {
  .icon-box-list__item {
    max-width: 530px;
    .icon-wrapper {
      background-color: $primary;
    }
    .icon-box-list__text {
      @include media-breakpoint-up(xl) {
        width: calc(100% - 64px);
      }
    }
  }
}
.page-pricing {
  .line-items {
    margin-top: 10rem;

    @include media-breakpoint-up(xl) {
      margin-top: 12rem;
    }
  }
}
.page-pricing {
  .line-items {
    &__single {
      @include media-breakpoint-down(lg) {
        text-align: center;
        margin-bottom: 5.2rem;
      }
      &:first-of-type {
        @include media-breakpoint-up(lg) {
          width: calc(100% - 15px);
        }
        .line-items__single-title {
          .single-line {
            left: 50%;
            transform: translateX(-50%) !important;
            @include media-breakpoint-up(lg) {
              transform: none !important;
              max-width: 100%;
              left: 0;
            }
            background-color: $purple-color;
          }
        }
      }
      &:nth-of-type(2) {
        @include media-breakpoint-up(lg) {
          padding-left: 28px;
        }
        .line-items__single-title {
          .single-line {
            left: 50%;
            transform: translateX(-50%) !important;
            @include media-breakpoint-up(lg) {
              transform: none !important;
              max-width: 100%;
              left: 0;
            }
            background-color: $violet-light;
          }
        }
      }
      &:nth-of-type(3) {
        @include media-breakpoint-up(lg) {
          padding-left: 55px;
        }
        .line-items__single-title {
          .single-line {
            left: 50%;
            transform: translateX(-50%) !important;
            max-width: 100%;
            @include media-breakpoint-up(lg) {
              transform: none !important;
              max-width: 100%;
              left: 0;
            }
            background-color: $green-light;
          }
        }
      }
      &-title {
        font-size: 2.4rem;
        font-weight: 700;
        margin-bottom: 2rem;
        position: relative;
        padding-bottom: 2.5rem;
        margin-bottom: 2.3rem;
        @include media-breakpoint-up(lg) {
          padding-bottom: 2.5rem;
          margin-bottom: 4rem;
        }
        .single-line {
          display: block;
          height: 4px;
          border-radius: 4px;
          position: absolute;
          bottom: -10px;
        }
      }
    }
    p {
      font-weight: 600;
      font-size: 1.8rem;
    }
  }
}
.lottie-illustration-pricing-hero {
  position: relative;
  max-width: 680px;
  left: 50%;
  transform: translate(-50%);
  margin-top: 5rem;
}
.graphic-box {
  text-align: center;
  .graphic-small {
    margin-bottom: 4rem;
    @include media-breakpoint-down(lg) {
      margin-top: 3.5rem;
    }
    .lottie-illustration-small {
      svg {
        max-height: 155px;
      }
    }
  }
  &__title {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 1.2rem;
    margin-top: -1rem;
    display: block;
  }
  p {
    font-weight: 600;
    font-size: 1.8rem;
  }
}
.text-semibold {
  font-weight: 600;
}
.section-luke {
  padding-bottom: 7rem;
  & > .container {
    position: relative;
    &:before {
      content: "";
      height: 2px;
      background-color: $violet-lightest;
      bottom: -5.5rem;
      left: 0;
      right: 0;
      position: absolute;
      display: none;
      width: 100%;
      z-index: -1;
      left: 0;
      @media screen and (min-width: 1600px) {
        display: block;
        left: unset;
        bottom: 85px;
        width: 300px;
      }
    }
  }
  .person-box {
    margin-left: auto;
    margin-right: auto;
    padding: 7.5rem 3rem 5.5rem 3rem;
    @include media-breakpoint-up(md) {
      padding: 7.5rem 3.5rem 5.5rem 3.5rem;
    }
    @include media-breakpoint-up(lg) {
      margin-left: unset;
      margin-right: unset;
      padding: 7.5rem 6rem 5.5rem 6rem;
    }
    @media screen and (min-width: 1800px) {
      padding: 7.5rem 8rem 5.5rem 8rem;
    }
  }
}
.person-box {
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
  position: relative;
  background-color: white;
  padding: 7.5rem 7rem 5.5rem 7rem;
  box-shadow: -2px 14px 52px -7px $shadow-middle;
  margin-top: 3rem;
  border-radius: 5px;
  transition: 0.3s;
  @media screen and (min-width: 1600px) {
    padding: 7.5rem 8rem 5.5rem 8rem;
  }
  &:hover {
    box-shadow: -2px 14px 52px -7px $shadow;
  }
  &__img-wrapper {
    position: absolute;
    width: 96px;
    height: 96px;
    top: -42px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 11;
  }
  &__foto {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: circle(50% at 50% 50%);
    box-shadow: -2px 14px 33px -13px $shadow-dark;
  }
  &__name {
    font-weight: 700;
    font-size: 3.2rem;
    margin-bottom: -0.5rem;
  }
  &__occupation {
    font-weight: 700;
    font-size: 1.2rem;
    letter-spacing: 1px;
  }
  &__intro {
    font-weight: 500;
    font-size: 1.8rem;
    margin-top: 3.2rem;
    margin-bottom: 5rem;
  }
  .btn {
    position: relative;
    padding: 2.5rem 3rem;
    @include media-breakpoint-up(md) {
      padding: 2.5rem 4rem;
    }
    &:after {
      content: "";
      height: 2px;
      background-color: $violet-light;
      top: 50%;
      display: none;
      position: absolute;
      z-index: 10;
      @media screen and (min-width: 1600px) {
        display: block;
        width: 150px;
        left: -200px;
      }
      @media screen and (min-width: 1800px) {
        width: 262px;
        left: -326px;
      }
    }
  }
  .calendly-mark {
    position: absolute;
    background-image: url("../images/calendly-logo.png");
    top: -5px;
    right: -5px;
    width: 74px;
    height: 74px;
    z-index: 2;
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    @include media-breakpoint-up(lg) {
      top: -7px;
      right: -7px;
      width: 100px;
      height: 100px;
    }
  }
}
.person__address {
  @include media-breakpoint-down(sm) {
    margin-right: 0;
    padding-right: 0;
  }
  padding-bottom: 6rem;
  margin-top: 6rem;
  padding-right: 15px;
  position: relative;
  z-index: 11;
  a {
    color: $main-font-color;
    font-weight: 600;
  }
}
.text-lighter {
  color: $font-lighter;
}
.journey-row {
  margin-top: 10.5rem;
}
.journey-section {
  .lottie-circle-container--center {
    @media (min-width: 768px) {
      transform: translate(-50%, -40%);
    }
  }
}
#heroPricingPlayTrigger {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 0;
}
