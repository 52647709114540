.hero-services {
    margin-bottom: 20rem;
    @include media-breakpoint-up(lg) {
        margin-bottom: 27rem;
    }
    .ufo-wrapper,
    .ufo {
        height: 50%;
        @include media-breakpoint-up(xl) {
            height: 100%;
        }
    }
}
.page-services {
    .hero__subtitle {
        @include media-breakpoint-up(lg) {
            margin-top: 4rem;
        }
    }
}
.logo-list {
    margin-top: 12rem;
    li {
        width: 33.33%;
        margin-bottom: 4.5rem;
        @include media-breakpoint-up(md) {
            width: 25%;
            margin-bottom: 7.5rem;
        }
        @include media-breakpoint-up(lg) {
            width: 50%;
            margin-bottom: 11.5rem;
            margin-top: 0;
        }
        a {
            width: 100%;
            height: 100%;
            display: block;
            text-align: center;
        }
        img {
            max-width: 140px;
            max-width: 75%;
            max-height: 30px;
            object-fit: contain;
            opacity: 1;
        }
    }
}
.services2-section {
    margin-top: 12rem;
    @include media-breakpoint-up(lg) {
        margin-top: 3rem;
    }
    .lottie-circle-container--center {
        top: calc(50% - 0.25rem);
    }
}
.services1-section {
    @include media-breakpoint-down(md) {
        margin-bottom: 12.5rem;
    }
    .ufo-wrapper {
        @media screen and (min-width: 576px) and (max-width: 991px) {
            width: 100%;
            height: 50%;
        }
        @include media-breakpoint-up(lg) {
            height: 100%;
        }
    }
}
.lottie-illustration-services-hero {
    width: 130%;
    position: relative;
    max-width: 790px;
    margin-top: 5rem;
    left: 50%;
    transform: translate(-50%);
}
.logo-bar {
    margin-top: 3rem;
    li {
        min-height: 75px;
        width: 33.33%;
        @include media-breakpoint-up(md) {
            width: 20%;
            min-height: 120px;
            margin-top: 0;
        }
        @media screen and (min-width: 1700px) {
            min-height: 140px;
        }
        a {
            text-align: center;
        }
        img {
            width: 75%;
            max-width: 180px;
            max-width: 75%;
            max-height: 30px;
            object-fit: contain;
        }
    }
}
.line {
    width: 2px;
    height: 56px;
    background-color: $violet-light;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    top: unset;
    @include media-breakpoint-up(lg) {
        height: 115px;
        top: 135%;
    }
}
.mobile-logos {
    margin-bottom: 12.5rem;
    width: 100%;
}
.link-boxes {
    margin-top: 9rem;
    @include media-breakpoint-up(md) {
        margin-top: 10rem;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 9rem;
    }
    .link-box-wrapper:first-of-type {
        .decorator {
            background-image: url("../images/engineering-raport_cover.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    .link-box-wrapper:nth-of-type(2) {
        .decorator {
            background-image: url("../images/development-raport_cover.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}
.link-box-wrapper {
    transition: 0.5s;
    &:hover {
        transform: translateY(-15px);
    }
}
.link-box {
    position: relative;
    padding: 128px 50px 38px 50px;
    transition: 0.5s;
    box-shadow: -2px 14px 45px -16px $shadow-middle2;
    border: 1px solid white;
    border-radius: 5px;
    z-index: 2;
    &:after {
        content: "";
        background-color: $primary;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        opacity: 0;
        height: 2px;
        border: 1px solid transparent;
        border-radius: 2px;
        position: absolute;
        display: block;
        z-index: -1;
        transition: 0.3s;
    }
    &--services {
        @include media-breakpoint-up(xl) {
            padding: 40px 70px 40px 160px;
            width: calc(100% - 1.5rem);
            max-width: calc(100% - 1.5rem);
        }
        @media screen and (min-width: 1700px) {
            padding: 53px 80px 53px 176px;
            width: calc(100% - 1.5rem);
            max-width: calc(100% - 1.5rem);
        }
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        background-color: $lightest;
        border: 1px solid $lightest;
        border-radius: 5px;
    }
    .decorator {
        width: 95px;
        height: 135px;
        border-radius: 5px;
        position: absolute;
        top: -45px;
        left: 50%;
        transform: translateX(-50%);
        @include media-breakpoint-up(md) {
            left: 36px;
            transform: none;
        }
    }
    .bottom-layer1 {
        position: absolute;
        bottom: -7px;
        background-color: #e5d0a7;
        border-radius: 3px 3px 8px 8px;
        width: calc(100% - 16px);
        left: 50%;
        transform: translateX(-50%);
        height: 7px;
        transition: 0.3s;
        z-index: -2;
        @include media-breakpoint-up(md) {
            opacity: 0;
            bottom: -10px;
            height: 13px;
        }
    }
    .bottom-layer2 {
        position: absolute;
        height: 7px;
        background-color: #ebe6dd;
        border-radius: 3px 3px 8px 8px;
        bottom: -14px;
        width: calc(100% - 28px);
        left: 50%;
        transform: translateX(-50%);
        transition: 0.3s;
        z-index: -3;
        @include media-breakpoint-up(md) {
            height: 12px;
            bottom: -18px;
            opacity: 0;
        }
    }
    .btn--small {
        margin-top: 4rem;
        padding-left: 23px;
        padding-right: 23px;
    }
    .link-box__title {
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 1.25;
        color: $main-font-color;
    }
    &:hover {
        box-shadow: -2px 14px 33px -13px rgba(186, 186, 186, 1);
        text-decoration: none;
        .btn {
            background-color: $primary;
        }
        .bottom-layer1,
        .bottom-layer2,
        &:after {
            opacity: 1;
        }
    }
}
.page-services {
    .link-box {
        @media screen and (min-width: 1700px) {
            padding: 53px 60px 53px 176px;
        }
    }
}
.link-boxes {
    .col-sm-6 {
        &:first-of-type {
            .link-box {
                @include media-breakpoint-up(sm) {
                    margin-right: 1.5rem;
                }
            }
        }
        &:nth-of-type(2) {
            .link-box {
                margin-top: 8.5rem;
                @include media-breakpoint-up(sm) {
                    margin-left: 1rem;
                    margin-top: 0;
                }
            }
        }
    }
}
.section-sandcastle {
    margin-bottom: 12rem;
    @include media-breakpoint-down(lg) {
        margin-top: 4rem;
    }
    .lottie-illustration3 {
        @include media-breakpoint-down(md) {
            width: 110%;
            left: -5%;
            position: relative;
        }
    }
}
.section-level-up {
    .section__subtitle {
        font-size: 2.4rem;
    }
    .icon-wrapper {
        margin-bottom: 2rem;
    }
}
.lottie-illustration5 {
    @include media-breakpoint-down(md) {
        max-width: 88%;
        margin-left: auto;
        margin-right: auto;
    }
}
.service2-section {
    .dots-wrapper {
        bottom: -1.6rem;
    }
}
.sandcastle-icons {
    margin-top: 3.5rem;
    margin-bottom: 12.5rem;
    padding-top: 3px;
    @include media-breakpoint-up(md) {
        margin-top: 7rem;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 9.5rem;
    }
    .icon-step {
        width: 50%;
        @include media-breakpoint-up(sm) {
            width: 33.33%;
        }
        @include media-breakpoint-up(lg) {
            width: 20%;
        }
        &:nth-of-type(1),
        &:last-of-type {
            .white-overlay {
                width: 180px;
            }
        }
        &:nth-of-type(1) {
            .white-overlay {
                right: -24px;
                left: unset;
            }
        }
    }
    .icon-wrapper {
        position: relative;
        margin-bottom: 0;
        span {
            margin-top: 0;
        }
        .white-overlay {
            width: 96px;
            height: 48px;
            left: -24px;
            background: white;
            z-index: 2;
            display: block;
            position: absolute;
        }
        .checkmark {
            display: block;
            width: 24px;
            height: 24px;
            border-radius: 24px;
            background-color: $green-light;
            background-size: 10px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("../images/checked.svg");
            position: absolute;
            top: -1px;
            left: -12px;
            z-index: 4;
        }
        .img-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;
            width: 48px;
            height: 48px;
            border-radius: 48px;
            @include media-breakpoint-down(lg) {
                background-color: $violet-light;
            }
            img,
            svg {
                width: 23px;
                height: 23px;
                object-fit: contain;
            }
        }
    }
    span {
        font-size: 1.6rem;
        font-weight: 600;
        display: inline-block;
        margin-bottom: 23px;
        margin-top: 0;
        @media screen and (min-width: 360px) {
            font-size: 1.8rem;
        }
        @include media-breakpoint-up(lg) {
            margin-top: 23px;
            margin-bottom: 0;
        }
    }
    .icons-line {
        position: absolute;
        width: 100%;
        height: 2px;
        top: 4px;
        overflow: hidden;
        background-color: $violet-lightest;
        display: block;
        z-index: -10;
    }
    .icons-line-moved {
        width: 100%;
        height: 2px;
        top: 4px;
        z-index: -9;
        background-color: $violet-light;
    }
}
.buttons-row {
    margin-top: 5rem;
    padding-bottom: 5.5rem;
    @include media-breakpoint-up(lg) {
        padding-bottom: 11.5rem;
        margin-top: 11.5rem;
    }
}
.btn-photo {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -30px;
}
.section-summary__items-list--services {
    li {
        width: 20%;
        span {
            font-weight: 700;
        }
    }
}
.grow-row {
    margin-top: 5rem;
    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }
}
.buttons-row {
    .btn {
        &:first-of-type {
            @include media-breakpoint-down(sm) {
                min-width: 252px;
                margin-bottom: 0;
            }
        }
    }
    .luke-btn {
        @include media-breakpoint-down(sm) {
            min-width: 222px;
            margin-left: 30px;
        }
        @include media-breakpoint-up(sm) {
            padding-left: 5.5rem;
        }
    }
}
.page-services {
    .lottie-illustration5 {
        @include media-breakpoint-up(lg) {
            position: relative;
            top: -10rem;
        }
    }
    .buttons-row {
        @include media-breakpoint-up(lg) {
            margin-top: 12rem;
        }
    }
}
