html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    font-size: 1.8rem;
    font-family: $main-font-family;
    font-weight: 400;
    color: $main-font-color;
    padding-top: 128px;
    min-height: 100vh;
    transition: 0.2s ease;
}

#preloader {
    position: fixed;
    top: 0;
    width: 100%;
    height: 1000%;
    background-color: $lightest;
    z-index: 999999;
    opacity: 1;
    transition: 0.5s;
}
dl,
ol,
ul {
    margin-bottom: 0;
}
main {
    padding-top: 3rem;
    @include media-breakpoint-up(md) {
        padding-top: 8rem;
    }
    @include media-breakpoint-up(lg) {
        padding-top: 10rem;
    }
}
.container {
    max-width: 1200px;
    @media screen and (min-width: 1500px) {
        max-width: 1360px;
    }
}
.container-fluid {
    @media screen and (min-width: 1860px) {
        max-width: 1800px;
    }
}
@media screen and (min-width: 370px) and (max-width: 575px) {
    [class^="col-"] {
        padding-left: 24px;
        padding-right: 24px;
    }
}
@media screen and (min-width: 1400px) {
    [class^="col-"] {
        padding-left: 0;
        padding-right: 0;
    }
}

.nav-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    height: 100%;
    margin-bottom: 0;
    li {
        display: flex;
        align-items: center;
        a {
            color: $main-font-color;
            font-weight: 700;
            position: relative;
            text-decoration: none;
            font-size: 1.2rem;
            line-height: 1;
            height: 100%;
            display: flex;
            align-items: center;
            &:before {
                content: "";
                width: 100%;
                height: 2px;
                display: block;
                position: absolute;
                bottom: 0;
                background-color: $violet-light;
                opacity: 0;
                transition: $transition-time;
            }
            &:hover:before {
                opacity: 1;
            }
            &.current {
                &:before {
                    opacity: 1;
                }
            }
        }
        &:not(:last-of-type) {
            a {
                margin-right: 25px;
                @media screen and (min-width: 1400px) {
                    margin-right: 35px;
                }
                @media screen and (min-width: 1799px) {
                    margin-right: 58px;
                }
            }
        }
    }
}
.btn {
    color: $lightest;
    line-height: 1;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 40px;
    letter-spacing: 0.16rem;
    transition: background-color $transition-time;
    &:hover {
        color: $lightest;
    }
    &:not([disabled]):hover {
        background-color: $primary;
    }
    // &:active,
    // &:focus {
    //     outline: none !important;
    //     box-shadow: none;
    // }
    &--small {
        padding: 1.7rem 3.8rem;
    }
    &--big {
        padding: 2.25rem 4.8rem;
    }
    &--biggest {
        padding: 2.25rem 9.3rem;
    }
    &--light {
        background-color: $violet-light;
    }
    &--dark {
        background-color: $violet-dark;
    }
    &--darkest {
        background-color: $main-font-color;
    }
    .btn-arrow {
        margin-left: 0.8rem;
    }

}
.btn-scroll-wrapper {
    width: 6rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(61, 67, 89, 0.08);
    border-radius: 50%;
    animation: bounce 2s ease infinite;
    transition: $transition-time;


    @include media-breakpoint-up(lg) {
        width: 8rem;
        height: 8rem;
    }
    &--light {
        background-color: $lightest;
    }
    &--yellow {
        background-color: $primary;
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translate(-50%, 0);}
    40% {transform: translate(-50%, -5px);}
    60% {transform: translate(-50%, -3px);}
}

.btn-scroll {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: 2.4rem;
    background-position: center;    
    @include media-breakpoint-up(lg) {
        width: 6.4rem;
        height: 6.4rem;
    }
    &--light {
        background-color: $lightest;
        background-image: url("../images/arrow-down.svg");
        border: 1px solid $violet-lightest;
    }
    &--dark {
        background-color: $lightest;
        background-image: url("../images/arrow-down-black.svg");
        border: 1px solid $violet-lightest;
    }
    &--yellow {
        background-color: $primary;
        background-image: url("../images/icons/icon_arrow_down_light.svg");
        border: 1px solid $lightest;
        background-size: 12.5px;
    }
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
}

.big-header-space {
    margin-top: 3.5rem;
    @include media-breakpoint-up(xl) {
        margin-top: 5.6rem;
    }
}
.section {
    position: relative;
    margin-bottom: 15.5rem;
    @include media-breakpoint-up(xl) {
        padding-top: 10rem;
    }
    &__title {
        text-align: center;
        font-weight: 700;
        line-height: 1.125;
        &--big {
            font-size: 3.2rem;
            line-height: 1.125;
            @include media-breakpoint-down(xs) {
                max-width: 31.5rem;
            }
            @include media-breakpoint-up(md) {
                font-size: 3.5rem;
                text-align: left;
                max-width: 60.5rem;
                max-width: 51.5rem;
            }
            @include media-breakpoint-up(xl) {
                font-size: 4.8rem;
                max-width: 100%;
            }
        }
        &--small {
            font-size: 2.4rem;
            line-height: 1.125;
            max-width: 32.5rem;
            @include media-breakpoint-up(md) {
                font-size: 3.2rem;
            }
            @include media-breakpoint-up(lg) {
                max-width: 63rem;
            }
        }
        &--middle {
            font-size: 2.4rem;
            @include media-breakpoint-up(md) {
                font-size: 3.5rem;
            }
            @include media-breakpoint-up(lg) {
                font-size: 4rem;
            }
        }
    }
    &__text {
        font-size: 1.8rem;
        line-height: 1.44;
        font-weight: 500;
        @include media-breakpoint-up(lg) {
            width: 100%;
            margin-left: 0;
        }
        @include media-breakpoint-up(xl) {
            width: 100%;
            margin-left: 0;
        }
        p {
            margin-bottom: 0;
            &:not(:last-of-type) {
                margin-bottom: 3rem;
            }
        }
        .span-light {
            font-weight: 400;
        }
        a {
            color: $violet-light;
        }
        ul {
            list-style-type: none;
            padding-left: 0;
            position: relative;
            li {
                position: relative;
                &:before {
                    content: "";
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
            &.list-simple {
                margin-top: 4rem;
                li {
                    padding-left: 38px;
                    display: block;
                    &:not(:last-of-type) {
                        margin-bottom: 1.5rem;
                        @include media-breakpoint-up(lg) {
                            margin-bottom: 1.6rem;
                        }
                    }
                    &:before {
                        display: block;
                        width: 23px;
                        height: 23px;
                        border-radius: 100%;
                        background-color: $primary;
                        background-size: 10px;
                        background-image: url("../images/icons/icon_checked.svg");
                        position: absolute;
                        left: 0;
                    }
                }
                li.sub-bullet {
                    margin-left: 1em;
                    &:before {
                        background-image: none !important;
                        display: none !important;
                    }
                }
                &--blue {
                    li {
                        &:before {
                            background-color: $green-light;
                            background-image: url("../images/bullet-inside.png");
                            background-size: 6px;
                        }
                    }
                }
            }
            &.list-simple-tight {
                margin-top: 1rem;
                li {
                    padding-left: 38px;
                    display: block;
                    &:not(:last-of-type) {
                        margin-bottom: 1.5rem;
                        @include media-breakpoint-up(lg) {
                            margin-bottom: 1.6rem;
                        }
                    }
                    &:before {
                        display: block;
                        width: 23px;
                        height: 23px;
                        border-radius: 100%;
                        background-color: $primary;
                        background-size: 10px;
                        background-image: url("../images/icons/icon_checked.svg");
                        position: absolute;
                        left: 0;
                    }
                }
                li.sub-bullet {
                    margin-left: 1em;
                    &:before {
                        background-image: none !important;
                        display: none !important;
                    }
                }
                &--blue {
                    li {
                        &:before {
                            background-color: $green-light;
                            background-image: url("../images/bullet-inside.png");
                            background-size: 6px;
                        }
                    }
                }
            }
            &.list-triple {
                display: flex;
                flex-wrap: wrap;
                li {
                    width: 50%;
                    margin-bottom: 1.5rem;
                    @include media-breakpoint-up(lg) {
                        margin-bottom: 1.7rem;
                    }
                    @include media-breakpoint-up(xl) {
                        width: 35%;
                        .subtitle-small {
                            font-size: 2.4rem;
                            margin-bottom: 2rem;
                            font-weight: 600;
                        }
                    }
                }
            }
            &.list-half {
                display: flex;
                flex-wrap: wrap;
                li {
                    width: 100%;
                    margin-bottom: 1.5rem;
                    @include media-breakpoint-up(lg) {
                        margin-bottom: 1.7rem;
                        width: 50%;
                    }
                }
            }
            &.list-smallest {
                display: flex;
                flex-wrap: wrap;
                max-width: 310px;
                li {
                    width: 45%;
                    margin-bottom: 1.5rem;
                    text-align: left;
                    @include media-breakpoint-up(lg) {
                        margin-bottom: 1.7rem;
                        max-width: 14.5rem;
                    }
                    &:before {
                        background-color: $violet2;
                    }
                }
            }
            &.list-icons {
                li {
                    padding-left: 6rem;
                    margin-bottom: 4rem;
                    &:before {
                        width: 48px;
                        height: 48px;
                        border-radius: 100%;
                        background-color: $lightest;
                        box-shadow: -2px 14px 45px -16px;
                        position: absolute;
                        left: 0rem;
                        display: flex;
                        align-items: center;
                    }
                }
            }
            &.list-default {
                font-weight: 600;
                &--blue,
                &--green {
                    li {
                        &:before {
                            width: 8px;
                            height: 8px;
                            border-radius: 100%;
                            top: 50%;
                            transform: translateY(-50%);
                            background-image: none;
                        }
                    }
                }
                &--blue {
                    li {
                        &:before {
                            background-color: $violet-light;
                        }
                    }
                }
                &--green {
                    li {
                        &:before {
                            background-color: $green-light;
                        }
                    }
                }
            }
        }

        &--squized {
            &:not(:first-of-type) {
                margin-top: 3rem;
            }
            &:not(:last-of-type) {
                margin-bottom: 3rem;
            }
            p {
                margin-bottom: 0;
                &:not(:last-of-type) {
                    margin-bottom: 1.6rem;
                }
            }
        }

        &--strong {
            font-weight: 600;
        }
    }

    &__subtitle {
        display: block;
        line-height: 1.25;
        margin-bottom: 4rem;
        font-weight: 700;
        font-size: 2rem;
        &--big {
            @include media-breakpoint-up(md) {
                font-weight: 600;
            }
        }
        &--small {
            margin-bottom: 3rem;
            @include media-breakpoint-up(md) {
                font-size: 2.4rem;
                margin-bottom: 3rem;
                font-weight: 600;
            }
        }
    }

    &--fullpage {
        margin-bottom: 0;
    }

}

.text-part-wrapper {
    border-top: 1px solid #EBEEF9;
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;

    @include media-breakpoint-up(lg) {
        padding-top: 6.4rem;
        padding-bottom: 6.4rem;
    }

}

.text-part-wrapper-no-border {
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;

    @include media-breakpoint-up(lg) {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
    }

}

.text-part {
    padding-left: 2.4rem;
    font-weight: 600;

    @include media-breakpoint-up(lg) {
        padding-left: 3.2rem;
    }

    &--blue {
        border-left: 3px solid $violet-light;
        span {
            color: $violet-light;
        }
    }
    &--green {
        border-left: 3px solid $green-light;
        span {
            color: $green-light;
        }
    }
}
.text-blue {
    color: $violet-light;
}
.subtitle-small {
    font-size: 2.4rem;
    margin-bottom: 2rem;
    font-weight: 600;
}
.scroll-top {
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 1px;
    .icon-wrapper {
        border: 1px solid transparent;
        img {
            height: 16px;
            object-fit: contain;
        }
    }
    &:focus {
        outline: none !important;
        .icon-wrapper {
            border: 1px solid $violet-lightest;
        }
    }
}
.address-box {
    .icon-wrapper img {
        width: 25px;
        height: 20px;
    }
}
.error-content {
    margin-bottom: 12rem;
}
.lottie-error {
    width: 100%;
    @include media-breakpoint-down(lg) {
        margin-bottom: 8rem;
    }
}

/* Google ReCAPTCHA badge visibility hidden */
.grecaptcha-badge {
    visibility: hidden;
}

@media (min-width: 1200px) and (-webkit-device-pixel-ratio: 1.25) and (max-width: 1920px) {
    html {
        font-size: 50%;
    }
    .container {
        max-width: 1140px !important;
    }
    .scrap,
    .lottie-circle,
    .lottie-friendly .compagnon,
    .diagram {
        zoom: 0.8 !important;
    }
    .lottie-zoomed {
        zoom: 1.25;
    }
    .step {
        margin-top: 16rem !important;
    }
    .value-line1,
    .value-line2,
    .value-line3,
    .value-line4 {
        width: 47% !important;
    }
}

// Sizing fixes on Safari
.illu-step1 {
    margin-left: auto;
    margin-right: auto;
    max-width: 34rem;
}
.illu-step2 {
    @include media-breakpoint-up(md) {
        margin-left: auto;
        margin-right: auto;
        max-width: 45.3rem;
    }
    svg {
        left: -13vw;
        @include media-breakpoint-up(lg) {
            left: unset;
        }
    }
}
.illu-step3 {
    @include media-breakpoint-up(md) {
        margin-left: auto;
        margin-right: auto;
        max-width: 45rem;
    }
    svg {
        left: -10vw;
        @include media-breakpoint-up(lg) {
            left: unset;
        }
    }
}
.illu-step4 {
    margin-left: auto;
    margin-right: auto;
    max-width: 34rem;
}

.illu-documentation {
    max-width: 56.2rem;
    margin-left: auto;
    margin-right: auto;
}
