.page-report {
    .hero {
        margin-bottom: 19rem;
        @include media-breakpoint-up(md) {
            margin-bottom: 26rem;
        }
    }
    .hero .dots-wrapper {
        bottom: -6.6rem;
        @include media-breakpoint-up(xl) {
            bottom: -15rem;
        }
    }
    .lottie-circle-container--center {
        width: 210px;
        height: 210px;
        top: 20%;
        @include media-breakpoint-up(lg) {
            width: 350px;
            height: 350px;
            top: 35%;
        }
    }
    iframe {
        border: 2px solid $violet-lightest;
        padding-top: 160px;
        @include media-breakpoint-up(lg) {
            padding-top: 85px;
        }
    }
}
.lottie-let-us {
    svg {
        max-height: 460px;
        margin-left: auto;
        margin-right: auto;
    }
}
.section-deliver {
    .section__title {
        margin-bottom: 5.5rem;
        @include media-breakpoint-up(lg) {
            max-width: 100%;
        }
    }
}
.report-container {
    position: relative;
    @include media-breakpoint-up(lg) {
        margin-top: 5rem;
    }
    .ufo,
    .ufo-wrapper {
        position: absolute;
        z-index: 10;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.section-report {
    .dots-wrapper {
        bottom: 8.6rem;
        @include media-breakpoint-up(xl) {
            bottom: -5.6rem;
        }
    }
}
.report {
    margin-top: 15rem;
    @include media-breakpoint-up(lg) {
        margin-top: 20rem;
    }
    .section__title {
        max-width: 100%;
        font-size: 2.4rem;
        @include media-breakpoint-up(sm) {
            font-size: 3.2rem;
        }
        @include media-breakpoint-down(md) {
            margin-bottom: 3.5rem;
        }
        @include media-breakpoint-up(md) {
            font-size: 3.5rem;
        }
        @include media-breakpoint-up(lg) {
            font-size: 4rem;
        }
    }
    .link-box {
        box-shadow: -2px 14px 45px -16px #b0bcc730;
        padding: 13.8rem 2.2rem 4rem 2.2rem;
        border: none;
        position: relative;
        max-width: 100%;
        transition: 0.3s;
        .bottom-layer1 {
            width: calc(100% - 40px);
            @media screen and (min-width: 1600px) {
                width: calc(100% - 60px);
            }
        }
        .bottom-layer2 {
            width: calc(100% - 60px);
            @media screen and (min-width: 1600px) {
                width: calc(100% - 100px);
            }
        }
        @include media-breakpoint-down(lg) {
            margin-bottom: 15rem;
        }
        &:after {
            content: "";
            background-color: $primary;
            top: 1px;
            left: 50%;
            transform: translateX(-50%);
            width: 85%;
            height: 2px;
            position: absolute;
            display: none;
            z-index: -1;
            opacity: 1;
            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
        &:before {
            content: "";
            background-image: url(/images/graph_background.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center bottom;
            display: block;
            height: 102%;
            width: 116%;
            left: -8%;
            top: 0;
            bottom: 0;
            position: absolute;
            z-index: -1;
        }
        @include media-breakpoint-up(md) {
            padding: 10.5rem 2.2rem 11rem 2.2rem;
        }
        @media screen and (min-width: 1600px) {
            padding: 10rem;
        }
        .bottom-layer1 {
            bottom: -16px;
            height: 7px;
            @include media-breakpoint-up(md) {
                bottom: -20px;
                height: 13px;
            }
        }
        .bottom-layer2 {
            bottom: -22px;
            @include media-breakpoint-up(md) {
                height: 12px;
                bottom: -29px;
            }
        }
        .btn--big {
            margin-top: 3.5rem;
            @include media-breakpoint-down(md) {
                padding: 2rem 3.8rem;
            }
            @include media-breakpoint-up(md) {
                margin-top: 5.5rem;
            }
        }
        &--report {
            padding: 10rem 3.5rem 5rem 3.5rem;
        }
    }
    &__cover {
        min-width: 215px;
        width: 215px;
        left: 50%;
        transform: translateX(-50%);
        height: 420px;
        padding: 55px 50px 55px 50px;
        background-image: url(/images/cover-report2.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        border-radius: 5px;
        height: 300px;
        padding: 34px 38px 35px 38px;
        top: -200px;
        box-shadow: -4px 14px 22px -16px $shadow-middle2;
        @include media-breakpoint-up(md) {
            top: -165px;
            margin-bottom: -165px;
            top: -200px;
            margin-bottom: -200px;
            left: unset;
            transform: none;
            min-width: 300px;
            height: 380px;
            position: relative;
        }
        @include media-breakpoint-up(xl) {
            min-width: 335px;
            height: 420px;
        }
        @media screen and (min-width: 1700px) {
            min-width: 335px;
            height: 447px;
            padding: 55px 50px 55px 50px;
            top: calc(-10rem - 52px);
            margin-bottom: calc(-10rem - 52px);
        }
        &:hover {
            text-decoration: none;
        }
    }
    &__logo {
        display: block;
        width: 40px;
        height: 40px;
        margin-bottom: 3rem;
    }
    &__title {
        font-weight: 700;
        font-size: 2rem;
        @include media-breakpoint-up(md) {
            font-size: 3.2rem;
        }
    }
    &__subtitle {
        font-size: 1.5rem;
        position: relative;
        display: inline-block;
        font-weight: 600;
        margin-bottom: 3.5rem;
        &:before {
            display: block;
            content: "";
            width: 24px;
            height: 2px;
            background-color: $lightest;
            bottom: -20px;
            position: absolute;
        }
        @include media-breakpoint-up(md) {
            font-size: 1.8rem;
        }
    }
    &__date {
        font-size: 1.2rem;
        font-weight: 700;
        display: inline-block;
    }
    &__intro {
        width: 100%;
        @include media-breakpoint-up(md) {
            padding-left: 8rem;
        }
        @include media-breakpoint-up(lg) {
        }
        @media screen and (min-width: 1700px) {
            padding-left: 10rem;
        }
        .section__title--big {
            @include media-breakpoint-up(xl) {
                font-size: 4rem !important;
            }
        }
        p {
            max-width: 440px;
            font-weight: 500;
            @include media-breakpoint-down(md) {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
.report-btns {
    padding-top: 100px;
    padding-bottom: 25px;
    .btn {
        @include media-breakpoint-down(md) {
            padding: 1.7rem 3rem;
        }
    }
    @include media-breakpoint-up(lg) {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}
.report-iframe {
    width: 100%;
    height: 100%;
}
.report-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: $lightest;
}
