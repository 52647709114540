.ai-rocket {
  background-image: url("../images/fuel_ill_2.svg");
  background-position: calc(100% - 28px) 21px;
  background-repeat: no-repeat;
  background-size: 350px;
  height: 450px;

  @include media-breakpoint-up(md) {
      background-size: 60%;
  }
}

.ai-jump {
  background-image: url("../images/jump.svg");
  background-position: calc(100% - 28px) 21px;
  background-repeat: no-repeat;
  background-size: 350px;
  height: 450px;

  @include media-breakpoint-up(md) {
      background-size: 60%;
  }
}