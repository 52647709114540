.hero-about {
  margin-bottom: 15.5rem;
  @include media-breakpoint-up(xl) {
    margin-bottom: 20.5rem;
  }
  .section__subtitle {
    margin-top: 4.5rem;
    margin-bottom: 3.5rem;
  }
  p {
    font-weight: 500;
  }
  &--person {
    .icon-link {
      a {
        color: $main-font-color;
        font-weight: 600;
      }
    }
  }
  .icon-wrapper {
    img {
      width: 18px;
      height: 18px;
    }
  }
}
.page-team {
  .lottie-loop {
    @media screen and (max-width: 991px) {
      margin-top: 50px;
    }
  }
  .person-box.person-box--small {
    @include media-breakpoint-down(lg) {
      padding: 9rem 1rem 4.2rem 1rem;
    }
    margin-top: 8rem;
    .btn {
      padding: 2.5rem 3rem;
      margin-top: 3.5rem;
      @include media-breakpoint-up(lg) {
        padding: 2.5rem 4rem;
      }
    }
  }
  .hero-about {
    .milkyway {
      @include media-breakpoint-down(md) {
        margin-top: 9rem;
      }
    }
  }
  .orbites {
    &__single,
    &__wrapper {
      &--big {
        @media screen and (max-width: 1500px) {
          width: 400px;
          height: 400px;
        }
      }
    }
  }
}
.section-team {
  .lottie-circle-container--center {
    @include media-breakpoint-up(md) {
      transform: translate(-50%, -32%);
    }
  }
}
.section-consequence {
  @include media-breakpoint-up(lg) {
    margin-bottom: 35rem;
  }
  .dots-wrapper {
    @include media-breakpoint-up(lg) {
      bottom: -28.6rem;
    }
  }
  p {
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
  }
}
.experience {
  margin-top: 7rem;
  flex-direction: column;
  @media screen and (min-width: 370px) {
    flex-direction: row;
  }
  &__item {
    align-items: center;
    &:first-of-type {
      & > div {
        @media screen and (max-width: 370px) {
          align-items: center;
        }
      }
      @media screen and (min-width: 370px) {
        margin-right: 3.5rem;
        align-items: start;
      }
    }
    &:nth-of-type(2) {
      & > div {
        @media screen and (max-width: 370px) {
          align-items: center;
        }
      }
      @media screen and (min-width: 370px) {
        margin-left: 3.5rem;
        align-items: start;
      }
    }
  }
  &__number {
    font-size: 5.6rem;
    line-height: 1;
    font-weight: 700;
    @include media-breakpoint-up(lg) {
      font-size: 8rem;
    }
    &:before {
      content: "";
      width: 50px;
      height: 50px;
      border-radius: 50px;
      position: absolute;
      top: -15px;
      left: -22px;
      display: block;
      z-index: -1;
      background-color: $primary;
    }
  }
  &__number-title {
    margin-left: 0;
    padding-top: 0.4rem;
    @include media-breakpoint-up(lg) {
      margin-left: 1.3rem;
    }
    font-size: 1.2rem;
    font-weight: 700;
    height: 6.5rem;

    letter-spacing: 1px;
    &:after {
      content: "";
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 10px;
      display: block;
      background-color: $violet-light;
      @include media-breakpoint-up(lg) {
        bottom: 0;
      }
    }
  }
}
.members {
  .member {
    &__single {
      margin-bottom: 4.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      transition: 0.2s;
      &:hover {
        text-decoration: none;
        &__name {
          color: $main-font-color;
        }
      }
    }
    &__photo,
    &__photo-wrapper,
    &__shadow-wrapper {
      width: 112px;
      height: 112px;
      @include media-breakpoint-up(sm) {
        width: 170px;
        height: 170px;
      }
      @include media-breakpoint-up(xl) {
        width: 200px;
        height: 200px;
      }
      @media screen and (min-width: 1500px) {
        width: 228px;
        height: 228px;
      }
      border-radius: 100%;
    }
    &__shadow-wrapper {
      position: relative;
      &:before {
        content: "";
        border-radius: 100%;
        position: absolute;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        top: -1px;
        left: -1px;
        z-index: 10;
        box-shadow: inset 0px 0px 0px 10px $lightest;
        @include media-breakpoint-up(lg) {
          box-shadow: inset 0px 0px 0px 16px $lightest;
        }
      }
      &:after {
        content: "";
        background-image: url(/images/semicircle.png);
        background-size: contain;
        background-position: top;
        background-repeat: no-repeat;
        width: calc(100% + 24px);
        height: 70%;
        object-fit: contain;
        z-index: -1;
        top: -12px;
        left: -12px;
        position: absolute;
        @include media-breakpoint-up(lg) {
          width: calc(100% + 48px);
          top: -24px;
          left: -24px;
        }
      }
      img {
        box-shadow: -2px 14px 52px -7px $shadow;
      }
    }
    &__name {
      margin-top: 2rem;
      font-size: 2rem;
      font-weight: 700;
      color: $main-font-color;
      text-align: center;
      line-height: 1.25;
      @include media-breakpoint-up(lg) {
        font-size: 3.2rem;
        margin-top: 2.9rem;
      }
    }
    &__function {
      color: $font-lighter;
      font-size: 1.2rem;
      font-weight: 700;
      letter-spacing: 1px;
      text-align: center;
    }

    &__photo-wrapper {
      text-align: center;
      transition: 0.4s;
    }
    &__single-link {
      &:hover {
        text-decoration: none;
        .member__photo-wrapper {
          transform: translatey(-10px);
          box-shadow: -2px 14px 52px -7px $shadow;
        }
      }
    }
  }
}
.fullwidth-lottie {
  @include media-breakpoint-up(lg) {
    width: 125%;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @include media-breakpoint-up(xl) {
    width: 130%;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media screen and (min-width: 1300px) {
    width: 100%;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media screen and (min-width: 1400px) {
    width: 116%;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media screen and (min-width: 1600px) {
    width: 90%;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1625px;
    margin-left: auto;
    margin-right: auto;
  }
}
.section-love {
  @include media-breakpoint-up(xl) {
    margin-bottom: 15.5rem;
  }
}
.milkyway {
  margin-top: 12rem;
  @include media-breakpoint-up(md) {
    margin-top: 10rem;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 6rem;
    position: relative;
    top: -2rem;
    margin-top: 0;
  }
  @media screen and (min-width: 1500px) {
    top: -5rem;
  }
  @media screen and (min-width: 1920px) {
    top: -9rem;
  }
}
.orbites {
  @media screen and (min-width: 1500px) {
    width: 688px;
    height: 688px;
  }
  position: relative;
  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
  &__wrapper {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    &--small {
      width: 268px;
      height: 268px;
      @media screen and (min-width: 1500px) {
        width: 450px;
        height: 450px;
      }
    }
    &--big {
      width: 420px;
      height: 420px;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
      @media screen and (min-width: 1500px) {
        width: 682px;
        height: 682px;
      }
    }
  }
  .small-orbit-container {
    width: 268px;
    height: 268px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (min-width: 1500px) {
      width: 450px;
      height: 450px;
    }
  }
  &__single {
    position: relative;
    border: 2px solid $violet-lightest;
    border-radius: 100%;
    &--big {
      width: 420px;
      height: 420px;
      @media screen and (min-width: 1500px) {
        width: 682px;
        height: 682px;
      }
      animation: orbitMoving 500s linear infinite;
      animation-delay: 4s;
      img {
        animation: personMoving 500s linear infinite;
        animation-delay: 4s;
        border-radius: 50%;
        z-index: 10;
      }
    }
    &--small {
      width: 268px;
      height: 268px;
      @media screen and (min-width: 1500px) {
        width: 450px;
        height: 450px;
      }
      animation: orbitMoving 400s linear infinite;
      animation-delay: 4s;
      img {
        animation: personMoving 400s linear infinite;
        animation-delay: 4s;
        border-radius: 50%;
      }
    }
    .img-1 {
      top: 66%;
      right: 97px;
      @media screen and (min-width: 1500px) {
        top: 10%;
        right: 83px;
      }
    }
    .img-2 {
      bottom: 18px;
      left: 12%;
    }
    .img-3 {
      top: -6%;
      left: 44%;
    }
    .img-4 {
      right: 137px;
      top: 92%;
    }
    .img-5 {
      top: -63px;
      left: 87%;
    }
    .img-6 {
      bottom: 0;
      left: 11%;
    }
    .img-7 {
      top: 1%;
      left: 5px;
    }
    .img-8 {
      right: -15px;
      top: 11%;
    }
    @include media-breakpoint-up(lg) {
      .img-2 {
        bottom: -8px;
        left: 61%;
      }
      .img-3 {
        top: 59%;
        left: -18px;
      }
      .img-4 {
        right: -3px;
        top: 66%;
      }
      .img-5 {
        top: -25px;
        left: 55%;
      }
      .img-6 {
        bottom: -39px;
        left: 26%;
      }
      .img-7 {
        top: 8%;
        left: -6px;
      }
      .img-8 {
        right: -31px;
        top: 25%;
      }
    }
  }
  &__star-wrapper,
  &__sun-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .the-sun,
    .the-star {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 100%;
    }
  }
  &__sun-wrapper {
    width: 115px;
    height: 115px;
    @media screen and (min-width: 1500px) {
      width: 228px;
      height: 228px;
    }
    .the-sun {
      width: 115px;
      height: 115px;
      animation: sunset linear 1s;
      background-image: url(/images/logo_atlantis_blue.svg);
      @media screen and (min-width: 1500px) {
        width: 228px;
        height: 228px;
      }
    }
  }
  &__star-wrapper {
    .the-star {
      width: 250px;
      height: 250px;
      @include media-breakpoint-up(lg) {
        width: 300px;
        height: 300px;
      }
      @media screen and (min-width: 1500px) {
        width: 460px;
        height: 460px;
      }
    }
  }
  &__person {
    clip-path: circle(50% at 50% 50%);
    position: absolute;
    &--small {
      width: 50px;
      height: 50px;
    }
    &--big {
      width: 70px;
      height: 70px;
      @media screen and (min-width: 1500px) {
        width: 94px;
        height: 94px;
      }
    }
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes sunset {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}
.list-icons {
  display: flex;
  margin-top: 5rem;
  li {
    &:first-of-type:before {
      background-image: url(/images/icons/Java.png);
      background-size: 50%;
    }
    &:nth-of-type(2):before {
      background-image: url(/images/icons/Swift.png);
      background-size: 65%;
    }
    &:nth-of-type(3):before {
      background-image: url(/images/icons/Python.png);
      background-size: 50%;
    }
    &:nth-of-type(4):before {
      background-image: url(/images/icons/Golang.png);
      background-size: 55%;
    }
    &:nth-of-type(5):before {
      background-image: url(/images/icons/Ruby.png);
      background-size: 55%;
    }
    &:nth-of-type(6):before {
      background-image: url(/images/icons/JavaScript.jpg);
      background-size: 55%;
    }
  }
}
.about-contact-section {
  padding-bottom: 5.5rem;
  .section__title--big {
    @media (min-width: 1200px) {
      margin-bottom: 12rem;
    }
  }
}
.border-blue {
  position: relative;
  &:after {
    content: "";
    width: calc(100% + 5.4rem);
    height: 2px;
    background-color: $violet-light;
    position: absolute;
    left: 0;
    z-index: 2;
    display: none;
    bottom: 75px;
    @include media-breakpoint-up(lg) {
      display: block;
    }
    @include media-breakpoint-up(xl) {
      bottom: 85px;
    }
  }
}
.border-blue-animated {
  position: absolute;
  height: 2px;
  background-color: $violet-light;
  position: absolute;
  left: 0;
  z-index: 2;
  display: none;
  bottom: 75px;
  @include media-breakpoint-up(lg) {
    display: block;
  }
  @include media-breakpoint-up(xl) {
    bottom: 85px;
  }
}
.collage-mobile {
  width: 126%;
  height: 310px;
  left: -13%;
  position: relative;
}
.section.person-about {
  @include media-breakpoint-up(lg) {
    margin-bottom: 32rem;
  }
  .dots-wrapper {
    @include media-breakpoint-up(lg) {
      bottom: -9.6rem;
    }
  }
  .section__text {
    @include media-breakpoint-up(xl) {
      font-size: 2rem;
      line-height: 1.4;
      font-weight: 500;
    }
  }
  .photo-wrapper {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem;
    text-align: center;
    &.brian2 {
      width: 100%;
      @media screen and (min-width: 575px) {
        width: 32.5%;
        max-width: 32.5%;
      }
    }
    &.brian3 {
      width: 100%;
      @media screen and (min-width: 575px) {
        width: 65%;
        max-width: 65%;
      }
    }
    &.forrest2 {
      width: 100%;
      @media screen and (min-width: 575px) {
        width: 47.5%;
        max-width: 47.5%;
      }
    }
    &.forrest3 {
      width: 100%;
      @media screen and (min-width: 575px) {
        width: 47.5%;
        max-width: 47.5%;
      }
    }
    &.tom2 {
      width: 100%;
      @media screen and (min-width: 575px) {
        width: 35%;
        max-width: 35%;
        margin-left: 2.5%;
        margin-right: 2.5%;
      }
    }
    &.tom3 {
      width: 100%;
      @media screen and (min-width: 575px) {
        width: 35%;
        max-width: 35%;
        margin-left: 2.5%;
        margin-right: 2.5%;
      }
    }
    &.jon2 {
      img {
        width: 100%;
        object-fit: cover;
      }
      @media screen and (min-width: 575px) {
        width: 30%;
        max-width: 30%;
        margin-right: 2.5%;
      }
    }
    &.jon3 {
      img {
        width: 100%;
        object-fit: cover;
      }

      @media screen and (min-width: 575px) {
        width: 65%;
        max-width: 65%;
        margin-left: 2.5%;
      }
    }
    &.fred2 {
      width: 100%;
      @media screen and (min-width: 575px) {
        width: 35%;
        max-width: 35%;
        margin-left: 2.5%;
        margin-right: 2.5%;
      }
    }
    &.fred3 {
      width: 100%;
      @media screen and (min-width: 575px) {
        width: 35%;
        max-width: 35%;
        margin-left: 2.5%;
        margin-right: 2.5%;
      }
    }
    &.luke2 {
      width: 100%;
      @media screen and (min-width: 575px) {
        width: 45%;
        max-width: 45%;
        margin-right: 2.5%;
      }
      @media screen and (min-width: 992px) {
        width: 35%;
        max-width: 35%;
        margin-right: 2.5%;
      }
    }
    &.luke3 {
      width: 100%;
      @media screen and (min-width: 575px) {
        width: 45%;
        max-width: 45%;
        margin-left: 2.5%;
      }
      @media screen and (min-width: 992px) {
        width: 35%;
        max-width: 35%;
        margin-left: 2.5%;
      }
    }
    &.lily2 {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @media screen and (min-width: 575px) {
        width: 45%;
        max-width: 45%;
        margin-right: 2.5%;
      }
      @media screen and (min-width: 992px) {
        width: 35%;
        max-width: 35%;
        margin-right: 2.5%;
      }
    }
    &.lily3 {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @media screen and (min-width: 575px) {
        width: 45%;
        max-width: 45%;
        margin-left: 2.5%;
      }
      @media screen and (min-width: 992px) {
        width: 35%;
        max-width: 35%;
        margin-left: 2.5%;
      }
    }
  }
  .gallery {
    .photo-wrapper:first-of-type {
      @media screen and (max-width: 575px) {
        margin-bottom: 0;
      }
    }
  }
  .photo {
    max-width: 100%;
    object-fit: contain;
    border-radius: 5px;
    display: inline-block;
  }
}
.illustration-mobile {
  @include media-breakpoint-down(lg) {
    margin-top: 5rem;
  }
}
