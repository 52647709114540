.footer {
    margin-top: 6rem;
    padding-bottom: 5.4rem;
    @include media-breakpoint-up(md) {
        margin-top: 8rem;
    }
    @include media-breakpoint-up(lg) {
        padding-bottom: 11.8rem;
        margin-top: 11.5rem;
    }
    .nav-list {
        padding-left: 8rem;
        height: 48px;
        a {
            &:not(:last-of-type) {
                @include media-breakpoint-up(xl) {
                    margin-right: 6rem;
                }
            }
        }
    }
    .nav-wrapper {
        margin-bottom: 5.3rem;
        @include media-breakpoint-up(lg) {
            margin-bottom: 8.4rem;
        }
        .btn {
            display: inline-block;
        }
    }
    .address-box {
        font-size: 1.6rem;
        line-height: 1.625;
        font-weight: 600;
        @include media-breakpoint-up(lg) {
            padding-left: 15px;
        }
        .address {
            margin-bottom: 0;
            font-style: normal;
        }
        .icon-wrapper {
            margin-right: 2.5rem;
            img {
                width: 15px;
                height: 15px;
            }
        }
        p {
            margin-bottom: 0;
        }
        a {
            color: $violet-light;
        }
    }
    .copyright {
        margin-top: 7rem;
        font-size: 1.6rem;
        line-height: 1.625;
        font-weight: 600;
        color: $font-lighter;
        span {
            color: $main-font-color;
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            li {
                padding-left: 0.6rem;
            }
            a {
                color: $font-lighter;
            }
        }
    }

    &--moved {
        margin-top: 2.4rem;
        @include media-breakpoint-up(sm) {
            margin-top: -13rem;
        }

        .border-row {
            position: relative;
            &:before {
                content: "";
                left: 24px;
                right: 24px;
                height: 2px;
                background-color: $violet-lightest;
                width: calc(100% - 48px);
                position: absolute;
                display: none;
                @include media-breakpoint-up(xl) {
                    top: 25rem;
                    width: 100%;
                    left: 0;
                    right: 0;
                    display: block;
                }
            }
        }
    }
}

.border-row {
    position: relative;
    &:before {
        content: "";
        top: -6rem;
        left: 24px;
        right: 24px;
        height: 2px;
        background-color: $violet-lightest;
        width: calc(100% - 48px);
        position: absolute;
        display: block;
        @include media-breakpoint-up(lg) {
            top: -11.5rem;
            width: 100%;
            left: 0;
            right: 0;
        }
    }
}

.recaptcha-info {
    font-size: 1.2rem;
    a {
        color: $violet-light !important;
    }
}
