.dots-wrapper, 
.dots-wrapper--fullpage {
  left: 50%;
  transform: translateX(-50%);
  .dots {
    .dot-center {
      position: relative;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      &:before,
      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        width: 6px;
        height: 6px;
        border-radius: 6px;
      }
      &:before {
        top: -13px;
      }
      &:after {
        top: 13px;
      }
    }
  }

}

.dots-wrapper {
    &.dots-animated {
        animation: fadeIn;
        .dots {
          .dot-center {
            animation: dotFlashing 1s infinite linear alternate;
            animation-delay: 1s;
            &:before {
              animation: dotFlashing 1s infinite linear alternate;
              animation-delay: 0.5s;
            }
            &:after {
              animation: dotFlashing 1s infinite linear alternate;
              animation-delay: 1.5s;
            }
          }
        }
      }
}

.dots-wrapper.dots-wrapper--fullpage {
    &.dots-animated {
        animation: fadeIn;
        .dots {
          .dot-center {
            animation: sumDotFlashing 1s infinite linear alternate;
            animation-delay: 1s;
            &:before {
              animation: sumDotFlashing 1s infinite linear alternate;
              animation-delay: 0.5s;
            }
            &:after {
              animation: sumDotFlashing 1s infinite linear alternate;
              animation-delay: 1.5s;
            }
          }
        }
      }
}


.dots-wrapper {
    bottom: -5.6rem;

    &--fullpage {
        height: 3rem;
        margin-top: 4.8rem;
        // bottom: 3.6rem;
        @include media-breakpoint-up(lg) {
            display: none;
        }

        @media screen and (min-width: 1560px) {
            display: block;
        }

        @media screen and (min-width: 1460px) and (min-height: 768px) {
            bottom: 2.4rem;
        }
        @media screen and (min-width: 1920px) and (min-height: 1080px) {
            bottom: 5.6rem;
        }
    }
}

.dots-wrapper--fullpage {
    .dots {
        .dot-center {
            background-color: #F2C2DA;
            &:after,
            &:before {
                background-color: #F2C2DA;

            }
            animation: sumDotFlashing 1s infinite linear alternate;
            animation-delay: 1s;
            &:before {
            animation: sumDotFlashing 1s infinite linear alternate;
            animation-delay: 0.5s;
            }
            &:after {
            animation: sumDotFlashing 1s infinite linear alternate;
            animation-delay: 1.5s;
            }
        }
    }
   
}

@keyframes dotFlashing {
  0% {
    background-color: $primary;
  }
  50%,
  100% {
    background-color: $violet-lightest;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
