.polygon-center-container {
    width: 1800px;
    height: 1360px;
    left: 50%;
    max-width: 100%;
    transform: translateX(-50%);
    .polygon-top {
        width: 1360px;
        height: 1360px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
.polygon-top {
    position: absolute;
    left: 50%;
    transform: translateY(0) translateX(-50%) rotate(0);
    width: 1800px;
    @media screen and (min-width: 1366px) and (max-width: 1800px) {
        width: 1260px;
        max-width: 1260px;
    }
}
.lottie-circle-hero {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 1360px!important;
    height: 1360px!important;
    svg {
        position: absolute;
        left: 0;
        top: 0;
    }
    &--fuel {

        @include media-breakpoint-up(md) {
            width: 70%!important;
            height: 1300px!important;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }

        @media screen and (min-width: 1920px) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 1500px!important;
            height: 1300px!important;
        }
    }

    &--conversation {

        @media screen and (min-width: 1920px) {
            position: absolute;
            left: 50%;
        }
    }
}
.polygon-left-container {
    overflow: hidden;
    width: 1800px;
    height: 1360px;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translateX(-50%);
    @include media-breakpoint-down(lg) {
        overflow: visible;
    }
    .polygon-top {
        width: 1360px;
        height: 1360px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
.polygon-top {
    position: absolute;
    left: 50%;
    transform: translateY(0) translateX(-50%) rotate(0);
    width: 1800px;
    @media screen and (min-width: 1366px) and (max-width: 1800px) {
        width: 1260px;
        max-width: 1260px;
    }
    &--fuel {
        position: absolute;
        left: 50%;
        transform: translateY(0) translateX(-50%) rotate(0);
        width: 1587px !important;
        height: 1300px !important;
        margin-left: auto;
        margin-right: auto;
    }
    &--conversation {
        height: 100%;
        top: -700px;
        right: -500px;
        height: 1300px;
        display: none;
        @include media-breakpoint-up(lg) {
            display: block;
        }
        
    }
}
.polygon-home-container {
    top: -825px;
    @include media-breakpoint-up(lg) {
        top: -590px;
    }
    @include media-breakpoint-up(xl) {
        top: -585px;
    }
    &--fuel {
        top: -750px;
        @include media-breakpoint-up(md) {
            top: -630px
        }
        @media screen and (min-width: 1920px) {
            top: -680px;
        }

        .polygon-top--fuel {
            width: 1350px !important;
            height: 1300px !important;
            @include media-breakpoint-up(lg) {
                width: 1587px !important;
            }
        }
    }
    &--conversation {
        top: -850px;
        right: -700px;
        height: 1300px;
        display: none;
        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}
.polygon-report-container {
    top: -935px;
    @include media-breakpoint-up(md) {
        top: -900px;
    }
    @include media-breakpoint-up(lg) {
        top: -835px;
    }
    @include media-breakpoint-up(xl) {
        top: -800px;
    }
}
.polygon-contact-container {
    top: -800px;

    @include media-breakpoint-up(md) {
        top: -200px;
    }
    @include media-breakpoint-up(lg) {
        top: -35%;
    }
    @include media-breakpoint-up(xl) {
        top: -695px;
    }
}
.polygon-services-container {
    left: 50%;
    transform: translateX(-50%);
    top: -10.5%;
    @include media-breakpoint-up(md) {
        top: -9.5%;
    }
    @include media-breakpoint-up(lg) {
        top: -8.5%;
    }
    @include media-breakpoint-up(xl) {
        top: -10%;
    }
    .lottie-circle-hero {
        width: 100%;
        height: 100%;
        @include media-breakpoint-up(md) {
            top: 0;
        }
    }
}
.page-services {
    .polygon-wrapper {
        width: 1800px;
        left: 50%;
        max-width: 100%;
        transform: translateX(-50%);
        position: absolute;
    }
    .polygon-container {
        top: -1000px;
        width: 100%;
        height: 1360px;
        left: 0;
        @include media-breakpoint-up(md) {
            top: -835px;
        }
        @include media-breakpoint-up(xl) {
            top: -735px;
            left: -25%;
            transform: rotate(-340deg);
        }
        @media screen and (min-width: 1921px) {
            top: -750px;
            left: -15%;
        }
    }
    .polygon-services {
        width: 1360px;
        max-width: 100%;
        max-height: 100%;
    }
}
.page-pricing {
    .polygon-wrapper {
        width: 1800px;
        left: 50%;
        max-width: 100%;
        transform: translateX(-50%);
        position: absolute;
    }
    .polygon-container {
        top: -966px;
        width: 100%;
        height: 1360px;
        left: 0;
        @include media-breakpoint-up(md) {
            top: -835px;
        }
        @include media-breakpoint-up(xl) {
            top: -894px;
            left: -25%;
        }
        @media screen and (min-width: 1921px) {
            top: -750px;
            left: -15%;
        }
    }
    .polygon-pricing {
        width: 1360px;
        max-width: 100%;
        max-height: 100%;
    }
}
.page-career {
    .polygon-wrapper {
        width: 1800px;
        left: 50%;
        max-width: 100%;
        transform: translateX(-50%);
        position: absolute;
    }
    .polygon-container {
        top: -1000px;
        width: 100%;
        height: 1360px;
        left: 0;
        @include media-breakpoint-up(md) {
            top: -835px;
        }
        @include media-breakpoint-up(xl) {
            top: -735px;
            left: -31%;
            transform: rotate(340deg);
        }

        @media screen and (min-width: 1799px) {
            top: -735px;
            left: -25%;
            transform: rotate(340deg);
        }

        @media screen and (min-width: 1921px) {
            top: -750px;
            left: -15%;
        }
    }
    .polygon-careers {
        width: 1360px;
        max-width: 100%;
        max-height: 100%;
    }
}
.team {
    .polygon-wrapper {
        width: 1800px;
        left: 50%;
        max-width: 100%;
        transform: translateX(-50%);
        position: absolute;
    }
    .polygon-container {
        top: -845px;
        width: 100%;
        height: 1360px;
        left: 0;
        overflow: hidden;
        @include media-breakpoint-up(md) {
            top: -835px;
        }
        @include media-breakpoint-up(xl) {
            top: -666px;
            left: -26%;
        }
        @media screen and (min-width: 1921px) {
            top: -684px;
            left: -27%;
        }
    }
}
.polygon-practices-container {
    top: -768px;
    @include media-breakpoint-up(md) {
        top: -712px;
    }
    @include media-breakpoint-up(lg) {
        top: -588px;
    }
    @include media-breakpoint-up(xl) {
        top: -730px;
    }
}
.page-projects {
    .polygon-wrapper {
        width: 1800px;
        left: 50%;
        max-width: 100%;
        transform: translateX(-50%);
        position: absolute;
    }
    .polygon-container {
        top: -1131px;
        width: 100%;
        height: 1360px;
        left: 0;
        @include media-breakpoint-up(md) {
            top: -835px;
        }
        @include media-breakpoint-up(xl) {
            top: -962px;
            left: -26%;
        }
        @media screen and (min-width: 1921px) {
            top: -900px;
            left: -21%;
        }
    }
    .polygon-projects {
        width: 1360px;
        max-width: 100%;
        max-height: 100%;
    }
}
.polygon-services-container {
    left: 50%;
    transform: translateX(-50%);
    top: -10.5%;
    @include media-breakpoint-up(md) {
        top: -9.5%;
    }
    @include media-breakpoint-up(lg) {
        top: -8.5%;
    }
    @include media-breakpoint-up(xl) {
        top: -10%;
    }
    .lottie-circle-hero {
        width: 100%;
        height: 100%;
        @include media-breakpoint-up(md) {
            top: 0;
        }
    }
}
// .page-engage {
//     .polygon-wrapper {
//         width: 1800px;
//         left: 50%;
//         max-width: 100%;
//         transform: translateX(-50%);
//         position: absolute;
//     }
//     .polygon-container {
//         top: -700px;
//         width: 100%;
//         height: 1360px;
//         left: 0;
//         overflow: hidden;
//         @include media-breakpoint-up(md) {
//             top: -630px;
//         }
//         @include media-breakpoint-up(xl) {
//             top: -500px;
//         }
//         @media screen and (min-width: 1300px) {
//             left: -10%;

//         }
//         @media screen and (min-width: 1921px) {
//             left: -27%;
//         }
//     }
// }

.page-engage {
    .polygon-wrapper {
        width: 1800px;
        left: 50%;
        max-width: 100%;
        transform: translateX(-50%);
        position: absolute;
    }
    .polygon-container {
        top: -800px;
        width: 100%;
        height: 1360px;
        left: 0;
        @include media-breakpoint-up(md) {
            top: -635px;
        }
        @include media-breakpoint-up(xl) {
            top: -635px;
            left: -15%;
            transform: rotate(-354deg);
        }
        @media screen and (min-width: 1921px) {
            top: -600px;
            left: -10%;
        }
    }
    .polygon-engage {
        width: 1360px;
        max-width: 100%;
        max-height: 100%;
    }
}