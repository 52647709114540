.page-practices {
  .section-summary__report-list {
    li {
      width: 25%;
    }
  }
  .temporary {
    img {
      max-height: 450px;
    }
  }
  .section {
    .hero__subtitle {
      @include media-breakpoint-down(md) {
        font-size: 1.8rem;
      }
    }
  }
}
.documentation-desc {
  &:after {
    content: "";
    width: 2px;
    height: 55px;
    position: absolute;
    bottom: -55px;
    background-color: $violet-light;
    display: block;
    @include media-breakpoint-down(lg) {
      left: 50%;
      transform: translateX(-50%);
    }
    @include media-breakpoint-up(lg) {
      left: 15px;
      transform: none;
      bottom: -55px;
    }
  }
}
.row-title {
  margin-bottom: 8rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 14.5rem;
  }
}
.boxes-list {
  margin-top: 12rem;
}
.section.section-management {
  @include media-breakpoint-down(lg) {
    margin-bottom: 8rem;
  }
  .icon-wrapper {
    position: relative;
    @include media-breakpoint-down(md) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .boxes-list {
    @include media-breakpoint-down(lg) {
      margin-top: 0;
    }
  }
}
.velocity-list {
  @include media-breakpoint-down(lg) {
    margin-top: 4rem;
  }
  .dots-wrapper {
    @include media-breakpoint-down(lg) {
      bottom: 1rem;
    }
  }
}
.section.section-velocity {
  @include media-breakpoint-down(lg) {
    margin-bottom: 8rem;
  }
}
.management-intro {
  margin-top: 12rem;
  margin-bottom: 10rem;
  @include media-breakpoint-down(lg) {
    margin-top: 0;
    margin-bottom: 0;
  }
  .section__text {
    @include media-breakpoint-down(lg) {
      max-width: 60rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.border-thick {
  width: calc(100% - 80px);
  height: 2px;
  background-color: $violet-light;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  bottom: 2px;
  @include media-breakpoint-up(lg) {
    left: 0;
    transform: none;
  }
}
.sec-title-partner {
  font-size: 2.4rem;
  display: inline-block;
  font-weight: 600;
  margin-top: 4rem;
  line-height: 1.25;
  @include media-breakpoint-up(md) {
    max-width: 47rem;
    margin-top: 2.2rem;
    font-size: 2.4rem;
  }
  @include media-breakpoint-up(xl) {
    max-width: 100%;
  }
}
.code-attributes {
  @include media-breakpoint-up(lg) {
    margin-bottom: 15.5rem;
  }
  .code-list {
    &:first-of-type {
      & > div {
        margin-bottom: 6.4rem;
      }
    }
    &:nth-of-type(2) {
      & > div {
        &:not(:last-of-type) {
          margin-bottom: 6.4rem;
          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.code-list {
  &__box {
    padding: 6rem 3rem 4rem 3rem;
    border: 2px solid $violet-lightest;
    border-radius: 5px;
    min-height: 26rem;
    position: relative;
    max-width: 64rem;
    @include media-breakpoint-up(lg) {
      padding: 6rem;
    }
  }
  &__title {
    font-size: 2.4rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  .icon-wrapper {
    position: absolute;
    margin-bottom: 0;
    top: -2.4rem;
    @include media-breakpoint-down(md) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.testing {
  @include media-breakpoint-up(md) {
    margin-bottom: 15.5rem;
  }
}
.documentation-list {
  &__col {
    position: relative;
    padding-top: 2rem;
    max-width: 38rem;
    margin-bottom: 5rem;
    @include media-breakpoint-down(lg) {
      margin-left: auto;
      margin-right: auto;
    }
    &:not(:last-of-type) {
      margin-bottom: 5rem;
    }
    .checkmark-wrapper {
      display: block;
      width: 23px;
      height: 23px;
      border-radius: 100%;
      background-color: $primary;
      background-size: 10px;
      background-image: url("../images/icons/icon_checked.svg");
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: -2rem;
      left: 50%;
      transform: translateX(-50%);
      @include media-breakpoint-up(md) {
        left: 0;
        transform: none;
      }
      @media screen and (min-width: 1400px) {
        left: 0;
      }
    }
    .section__text {
      max-width: 400px;
    }
  }
}
.text-empty {
  font-size: 6.4rem;
  text-shadow: -1px -1px 0 #eaedfb, 1px -1px 0 #eaedfb, -1px 1px 0 #eaedfb,
    1px 1px 0 #eaedfb;
  color: white;
  font-weight: 700;
  line-height: 0.925;
  @include media-breakpoint-up(lg) {
    font-size: 8rem;
  }
}
.sec-title-mobile-small {
  @include media-breakpoint-down(sm) {
    font-size: 2.4rem;
  }
}
.lottie-circle-container {
  &.lottie-circle-smallest {
    @include media-breakpoint-down(md) {
      width: 120px;
      height: 120px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.border-circle {
  width: calc(100% - 48px);
  height: 1px;
  background-color: $violet-lightest;
  margin-top: 6rem;
  margin-bottom: 6rem;
  left: 24px;
  @include media-breakpoint-up(xl) {
    width: calc(100% + 100px);
    left: -50px;
  }
  @media screen and (min-width: 1700px) {
    width: calc(100% + 120px);
    left: -60px;
  }
  .circle {
    background: white;
    z-index: 2;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    border: 1px solid $violet-lightest;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .circle-inner {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: $violet3;
    }
  }
}
.tools {
  margin-top: 12rem;
  @include media-breakpoint-down(lg) {
    margin-top: 4rem;
  }
  &__col {
    .section__text {
      padding-bottom: 5rem;
      max-width: 540px;
      border-bottom: 2px solid $violet-light;
      @include media-breakpoint-down(lg) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .icon-wrapper {
    @include media-breakpoint-down(md) {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.section-practices {
  .hero__subtitle {
    margin-top: 4rem;
  }
}
.section.section-planning {
  @include media-breakpoint-down(md) {
    margin-bottom: 5rem;
  }
}
.story-boxes {
  margin-bottom: 5rem;
  @include media-breakpoint-up(md) {
    margin-bottom: 10rem;
  }
  > div:nth-of-type(2) {
    @include media-breakpoint-down(xs) {
      margin-top: 6rem;
    }
  }
  &__item {
    padding: 6rem 1.5rem 4rem 1.5rem;
    border: 1px solid $violet-lightest;
    border-radius: 5px;
    @include media-breakpoint-down(md) {
      max-width: 400px;
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      padding: 6rem 5rem;
      width: 100%;
      max-width: 425px;
    }
  }
  .icon-wrapper {
    position: absolute;
    margin-bottom: 0;
    top: -2.4rem;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 17px;
      height: 17px;
    }
  }
  p {
    margin-bottom: 0;
  }
  &__title {
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 1px;
  }
  &__text {
    font-size: 2rem;
    font-weight: 600;
  }
}
.bottom-line {
  position: relative;
  &:after {
    content: "";
    width: 1px;
    height: 55px;
    position: absolute;
    bottom: -10rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: $violet-light;
    display: block;
  }
}
.temporary {
  display: flex;
  justify-content: center;
  img {
    max-width: 100%;
  }
}
.diagram {
  .lottie-loop {
    width: 100%;
    max-width: 480px;
    max-height: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}
.practices-diagram {
  margin-top: 4rem;
  @include media-breakpoint-up(xl) {
    margin-top: 14rem;
  }
  .row:first-of-type {
    @include media-breakpoint-up(xl) {
      margin-bottom: 6.5rem;
    }
  }
  .row:last-of-type {
    @include media-breakpoint-up(xl) {
      margin-top: 6.5rem;
    }
  }
  .row {
    @include media-breakpoint-down(xl) {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
.mobile-no {
  margin-top: 3.8rem;
  margin-bottom: 3.8rem;
  width: 130px;
  height: 130px;
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-up(xl) {
    display: none;
    margin: 0;
  }
}
.lottie-friendly {
  .compagnon {
    position: absolute;
    z-index: 20;
    border: 1rem solid white;
    background-color: white;
    box-shadow: -2px 14px 45px -16px #d8d8d8;
    border-radius: 50%;
    &:first-of-type {
      bottom: 1rem;
      left: 21rem;
      @include media-breakpoint-down(lg) {
        bottom: 7rem;
        left: 24rem;
        border: 1rem solid white;
      }
    }
    &:nth-of-type(2) {
      bottom: 14rem;
      right: 24rem;

      @include media-breakpoint-down(lg) {
        bottom: 27rem;
        right: 36rem;
        border: 1rem solid white;
      }
    }
  }
}
.lottie-illustration {
  @include media-breakpoint-down(md) {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}
.section.section-product {
  @include media-breakpoint-down(lg) {
    margin-bottom: 5rem;
  }
}
.section-story {
  .documentation-list__col .checkmark-wrapper {
    left: 50%;
    transform: translateX(-50%);
    @include media-breakpoint-up(lg) {
      left: 0;
      transform: none;
    }
    @include media-breakpoint-up(xl) {
      left: 0;
    }
  }
}
#practices4_IllustrationLOOP_desktop,
#product {
  @media screen and (min-width: 1920px) {
    .lottie-loop {
      width: 100%;
    }
  }
}
