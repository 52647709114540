.hero {
    margin-bottom: 17rem;
    @include media-breakpoint-up(lg) {
        margin-bottom: 15rem;
    }
    &__title {
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 1.1;
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
            font-size: 4.5rem;
        }
        @include media-breakpoint-up(lg) {
            font-size: 5.6rem;
        }
    }
    &__subtitle {
        font-size: 2.4rem;
        display: inline-block;
        font-weight: 600;
        margin-top: 1.7rem;
        line-height: 1.25;
        @include media-breakpoint-up(md) {
            max-width: 47rem;
            margin-top: 2.2rem;
            font-size: 2.4rem;
        }
        @include media-breakpoint-up(xl) {
            max-width: 100%;
        }
    }
    .btn {
        margin-top: 3.5rem;
        margin-bottom: 1.8rem;
        @include media-breakpoint-up(xl) {
            margin-top: 4rem;
        }
    }
    .dots-wrapper {
        bottom: -5.6rem;
        @include media-breakpoint-up(md) {
            bottom: -5.6rem;
        }
    }
    .illustration-wrapper {
        @include media-breakpoint-up(lg) {
            height: 540px;
        }
    }
    &-services {
        &__title {
            font-size: 3.2rem;
            font-weight: 700;
            line-height: 1.1;
            margin-bottom: 0;
            max-width: 100%;
            @include media-breakpoint-up(md) {
                font-size: 4.5rem;
            }
            @include media-breakpoint-up(lg) {
                max-width: 73rem;
                font-size: 5.6rem;
            }
        }
        &__subtitle {
            font-size: 2rem;
            font-weight: 700;
            margin-top: 3rem;
            line-height: 1.25;
            max-width: 100%;
            @include media-breakpoint-up(md) {
                font-size: 2.4rem;
            }
        }
    }
}
.circle {
    position: absolute;
    &--hero {
        width: 100%;
        height: 600px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}
.section-summary {
    padding-top: 0;
    .checked {
        img {
            width: 150px;
            height: 130px;
            @include media-breakpoint-up(lg) {
                width: 240px;
                height: 200px;
            }
        }
    }
    .section__title {
        text-align: center;
        max-width: 100%;
        margin-bottom: 1.5rem;
        @include media-breakpoint-up(md) {
            font-size: 4.8rem;
            text-align: left;
            margin-bottom: 2.2rem;
        }
        @media screen and (min-width: 1600px) {
            font-size: 6rem;
        }
    }
    &__items-list {
        li {
            width: 100%;
            text-align: center;
            align-items: center;
            justify-content: flex-start;
            display: flex;
            flex-direction: column;
            margin-top: 4rem;

            @include media-breakpoint-up(sm) {
                width: 50%;
            }
            @include media-breakpoint-up(lg) {
                width: 25%;
                margin-bottom: 0;
                &:nth-of-type(2),
                &:nth-of-type(3) {
                    margin-top: 9.5rem;
                }
            }
        }
    }
    &__services-list {
        li {
            width: 50%;
            text-align: center;
            align-items: center;
            justify-content: flex-start;
            display: flex;
            flex-direction: column;
            margin-top: 3.5rem;
            @include media-breakpoint-up(md) {
                width: 33.33%;
            }
            @include media-breakpoint-up(lg) {
                width: 20%;
                margin-bottom: 0;
                margin-top: 0;
                &:nth-of-type(3) {
                    margin-top: 5rem;
                }
                &:nth-of-type(2),
                &:nth-of-type(4) {
                    margin-top: 2.5rem;
                }
            }
        }
    }
    &__report-list {
        margin-top: 6rem;
        li {
            width: 50%;
            text-align: center;
            align-items: center;
            justify-content: flex-start;
            display: flex;
            flex-direction: column;
            font-weight: 600;
            @include media-breakpoint-up(md) {
                width: 33.33%;
                margin-bottom: 0;
            }
            &:nth-of-type(2) {
                margin-top: 4rem;
                width: 100%;
                @include media-breakpoint-up(md) {
                    margin-top: 2.5rem;
                    width: 33.33%;
                }
            }
        }
    }
    &__practices-list {
        margin-bottom: 4.5rem;
        margin-top: 6rem;
        @include media-breakpoint-up(lg) {
            margin-top: 4rem;
            margin-bottom: 11rem;
        }
        li {
            width: 50%;
            text-align: center;
            align-items: center;
            justify-content: flex-start;
            display: flex;
            flex-direction: column;
            font-weight: 600;
            @include media-breakpoint-up(lg) {
                width: 25%;
                margin-bottom: 0;
            }
            &:nth-of-type(2),
            &:nth-of-type(3) {
                width: 50%;
                margin-top: 4rem;
                @include media-breakpoint-up(lg) {
                    margin-top: 5.5rem;
                    width: 25%;
                }
            }
            &:first-of-type {
                @include media-breakpoint-up(lg) {
                    align-items: flex-start;
                }
            }
            &:last-of-type {
                @include media-breakpoint-up(lg) {
                    align-items: flex-end;
                }
            }
        }
    }
    .btn {
        margin-top: 4.2rem;
        margin-bottom: 2.4rem;

        @include media-breakpoint-up(sm) {
            margin-bottom: 5.6rem;
        }
    }
}
.icon-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.4rem;
    &--dark {
        background-color: $purple-color;
    }
    &--light {
        background-color: $violet-lightest;
    }
    &--green {
        background-color: $green-light;
    }
    &--primary {
        background-color: $primary;
    }
    img {
        width: 25px;
        height: 25px;
    }
}
// LOTTIE CIRCLES
.lottie-circle-container {
    position: absolute;
    z-index: -1;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    width: 230px;
    height: 230px;
    @include media-breakpoint-up(lg) {
        transform: none;
        top: -118px;
        left: -50px;
        width: 300px;
        height: 300px;
    }
    @include media-breakpoint-up(xl) {
        left: -118px;
    }
    &--center {
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @include media-breakpoint-down(md) {
            width: 300px;
            height: 300px;
        }
        @include media-breakpoint-down(sm) {
            width: 230px;
            height: 230px;
        }
        @include media-breakpoint-up(md) {
            top: calc(50% - 2.25rem);
            transform: translate(-50%, -46%);
        }
    }
}
.lottie-illustration-cto {
    @media screen and (max-width: 500px) {
        margin-top: 53px;
    }
    @media screen and (min-width: 500px) and (max-width: 991px) {
        margin-top: 50px;
    }
}
.lottie-illustration-connect {
    @media screen and (max-width: 500px) {
        z-index: 10;
        margin-top: 50px;
    }
    @media screen and (min-width: 500px) {
        margin-top: 50px;
    }
}
.lottie-illustration-meet {
    @media screen and (max-width: 500px) {
        z-index: 10;
        margin-top: 50px;
    }
    @media screen and (min-width: 500px) {
        margin-top: 50px;
    }

    svg {
        @include media-breakpoint-up(lg) {
            max-width: 90% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }

}
.lottie-illustration-deploy {
    @media screen and (max-width: 500px) {
        z-index: 10;
        margin-top: 56px;
    }
    @media screen and (min-width: 500px) {
        margin-top: 50px;
    }
}
.lottie-illustration-solved {
    @media screen and (max-width: 500px) {
        width: 209vw;
        position: relative;
        left: -60vw;
        z-index: 10;
        margin-top: 56px;
    }
}
.number {
    font-size: 16rem;
    color: $lightest;
    font-weight: 700;
    @include media-breakpoint-up(lg) {
        font-size: 2.4rem;
    }
}
.section-cto-vip {
    ul {
        @include media-breakpoint-up(lg) {
            max-width: 41.5rem;
        }
    }
}
.section-deploy {
    .ufo {
        @media screen and (max-width: 500px) {
            position: relative;
            z-index: -1;
            width: 100%;
            height: 69%;
            left: 0;
            top: 37%;
        }
    }
    .section__text {
        @include media-breakpoint-up(lg) {
            max-width: 55rem;
        }
    }
}

.section-connect {
    .section__text {
        @include media-breakpoint-up(xl) {
            max-width: 52rem;
        }
        max-width: 52rem;
    }
}

.hero-ill-wrapper {
    display: none;
    @media screen and (min-width: 500px) {
        display: block;
    }
}
.home-hero-mobile {
    display: flex;
    width: 180vw;
    position: relative;
    left: -40vw;
    z-index: 10;
    display: flex;
    justify-content: center;
    height: 400px;
    @media screen and (min-width: 400px) {
        width: 99vw;
        left: -5vw;
    }
    img {
        width: 100%;
        height: 400px;
    }
    @media screen and (min-width: 500px) {
        display: none;
    }
}

.illu-temporary {
    max-width: 100%;
}
