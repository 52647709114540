.career-hero {
  .hero__subtitle {
    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }
  }
  .icon-box-list {
    @include media-breakpoint-up(lg) {
      max-width: 36rem;
    }
    .icon-wrapper {
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
    br {
      display: none;
      @media screen and (min-width: 1799px) {
        display: inline-block;
      }
    }
    &__title {
      font-size: 1.8rem;
      font-weight: 600;
    }
  }
}
.section-change {
  @include media-breakpoint-down(xl) {
    padding-top: 19rem;
  }
}
.illu-mobile {
  @include media-breakpoint-down(lg) {
    margin-top: 5rem;
  }
}
.section-jobs {
  .lottie-hire {
    margin-left: auto;
    margin-right: auto;
    max-width: 28rem;
    position: relative;
    top: -5rem;
  }
  .line {
    @include media-breakpoint-up(lg) {
      top: 110%;
    }
  }
  .lottie-job {
    position: absolute;
    top: -11.6rem;
    svg {
      max-height: 165px;
    }
  }
  .jobs {
    margin-bottom: 12rem;
    margin-top: 8rem;
    @include media-breakpoint-up(xl) {
      margin-top: 15rem;
    }
    .link-box {
      &--job {
        box-shadow: none;
        padding: 100px 25px 120px 25px;
        @include media-breakpoint-up(lg) {
          padding: 90px 50px 170px 50px;
        }
        @include media-breakpoint-down(lg) {
          box-shadow: -2px 14px 45px -16px $shadow-middle2;
          margin-top: 18.5rem;
        }
        &:hover {
          box-shadow: -2px 14px 45px -16px $shadow-middle2;
          cursor: default;
        }
        .bottom-layer1,
        .bottom-layer2 {
          @include media-breakpoint-down(lg) {
            opacity: 1;
          }
        }
        .btn {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          @include media-breakpoint-up(lg) {
            bottom: 6.5rem;
          }
        }
      }
    }
  }
}
.job-title {
  font-size: 2.4rem;
  margin-bottom: 4rem;
  font-weight: 700;
}
.section-values {
  @include media-breakpoint-down(sm) {
    margin-bottom: 6rem;
  }
  .list-simple {
    @include media-breakpoint-up(xl) {
      max-width: 400px;
    }
  }
  .lottie-circle-container {
    @include media-breakpoint-up(md) {
      transform: translate(-50%, -65%);
    }
  }
  .no-mobile {
    display: block;
    margin-bottom: 2.5rem;
    background-color: $violet-lightest;
    width: 48px;
    height: 48px;
    color: $violet-light;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: 16px;
    font-weight: 700;
    margin-left: auto;
    margin-right: auto;
  }
  .no-close {
    img {
      width: 16px;
      height: 16px;
      transform: rotate(-20deg);
    }
  }
}
.step {
  align-items: center;
  margin-top: 6rem;
  @include media-breakpoint-up(md) {
    margin-top: 8rem;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 9rem;
  }
  @media screen and (min-width: 1500px) {
    margin-top: 14rem;
  }
  &--1 {
    margin-top: 12rem;
    @include media-breakpoint-up(lg) {
      margin-top: 17rem;
    }
  }
  &--2 {
    @include media-breakpoint-up(xl) {
      margin-top: 6rem;
    }
    @media screen and (min-width: 1500px) {
      margin-top: 14rem;
    }
  }
  &--3 {
    @include media-breakpoint-up(xl) {
      margin-top: 11rem;
    }
    @media screen and (min-width: 1500px) {
      margin-top: 18rem;
    }
  }
  .illu-wide {
    @media screen and (max-width: 400px) {
      svg {
        width: 130vw !important;
        position: relative;
      }
    }
  }
}
.value-line1,
.value-line2,
.value-line3,
.value-line4 {
  position: absolute;
  top: 0;
  width: 72%;
  height: 110%;
  left: 50%;
  top: calc(50% - 5rem);
  transform: translate(-50%, -50%);
  @include media-breakpoint-up(xl) {
    width: 53%;
  }
}
.value-line4 {
  top: calc(50% - 8rem);
}

.apply-bottom {
  @include media-breakpoint-up(lg) {
    margin-top: 10rem;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 15rem;
  }
}


.open-positions {
  h2.section__title { 
    margin-bottom: 2.0rem;
  }
  #BambooHR {
    .BambooHR-ATS-board {
      h2 { 
        display: none;
      }
      .BambooHR-ATS-Location {
        display: none;
      }

      .BambooHR-ATS-Department-Header {
        color: black;
        font-size: 3.0rem;
        margin-bottom: .5rem;
        font-family: Gilroy, sans-serif;

        @include media-breakpoint-down(md) {
          font-size: 1.5rem;
        }
      }
      a {
        font-size: 2.5rem;
        font-family: Gilroy, sans-serif;

        @include media-breakpoint-down(md) {
          font-size: 2.0rem;
        }
      }
    }
    // Has a "style" attribute and no "class" attribute
    // This targets the "powered by Bamboo" div
    div[style]:not([class]) {
      display: none;
    }
  }
}