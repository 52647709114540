.hero-projects {
  margin-bottom: 4.5rem;
  @include media-breakpoint-up(xl) {
    margin-bottom: 0;
  }
  .hero__title {
    @include media-breakpoint-down(xl) {
      margin-bottom: 5rem;
    }
  }
}
.mission {
  position: relative;
  margin-bottom: 15rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 18rem;
  }
  &__title {
    padding-left: 1.8rem;
    font-weight: 700;
  }
  .section__text {
    @include media-breakpoint-up(md) {
      font-weight: 500;
    }
  }
  .mission-list-first {
    margin-bottom: 3rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 5rem;
    }
  }
  .mission-desc {
    margin-top: 3rem;
    @include media-breakpoint-up(lg) {
      margin-top: 5rem;
    }
    &.no-gutters > [class*="col-"] {
      @media screen and (min-width: 1400px) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  .mission-title-wrapper {
    margin-bottom: 4rem;
  }
  .mission-lottie {
    @include media-breakpoint-down(lg) {
      margin-top: 7rem;
    }
  }
  .section__text {
    ul {
      &.list-simple {
        @include media-breakpoint-down(lg) {
          margin-top: 3rem;
        }
      }
    }
  }
}
.missions-intro-lottie {
  @include media-breakpoint-down(lg) {
    margin-top: 11.5rem;
  }
}
.mission-content-all {
  @include media-breakpoint-down(lg) {
    display: none;
  }
  @include media-breakpoint-up(lg) {
    display: block !important;
  }
}
.hide-mission {
  margin-top: 5rem;
}
.page-projects {
  .line-items {
    &__single {
      @include media-breakpoint-down(lg) {
        width: 100%;
        text-align: center;
        margin-bottom: 5.2rem;
      }

      &:first-of-type {
        @include media-breakpoint-up(xl) {
          width: 35%;
        }
        @media screen and (min-width: 1799px) {
          width: 448px;
        }
        .line-items__single-title {
          .single-line {
            left: 50%;
            transform: translateX(-50%) !important;
            @include media-breakpoint-up(xl) {
              transform: none !important;
              max-width: 100%;
              left: 0;
            }
            background-color: $green-light;
          }
        }
      }
      &:nth-of-type(2) {
        @include media-breakpoint-up(xl) {
          width: 25%;
        }
        @media screen and (min-width: 1799px) {
          width: 310px;
        }
        .line-items__single-title {
          .single-line {
            left: 50%;
            transform: translateX(-50%) !important;
            @include media-breakpoint-up(xl) {
              transform: none !important;
              max-width: 100%;
              left: 0;
            }
            background-color: $violet-light;
          }
        }
      }
      &:nth-of-type(3) {
        @include media-breakpoint-down(lg) {
          margin-bottom: 0;
        }
        @include media-breakpoint-up(xl) {
          width: 20%;
        }
        @media screen and (min-width: 1799px) {
          width: 260px;
        }
        .line-items__single-title {
          .single-line {
            left: 50%;
            transform: translateX(-50%) !important;
            @include media-breakpoint-up(xl) {
              transform: none !important;
              max-width: 100%;
              left: 0;
            }
            background-color: $purple-color;
          }
        }
      }
      &-title {
        font-size: 2.4rem;
        font-weight: 700;
        margin-bottom: 2rem;
        position: relative;
        padding-bottom: 0.5rem;
        margin-bottom: 2.5rem;
        @include media-breakpoint-up(lg) {
          padding-bottom: 2.5rem;
          margin-bottom: 4rem;
        }
        .single-line {
          display: block;
          height: 4px;
          border-radius: 4px;
          position: absolute;
          bottom: -10px;
        }
      }
    }
    p {
      font-weight: 600;
      font-size: 1.8rem;
    }
  }
  .logo-list {
    margin-top: 12rem;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
    li {
      margin-bottom: 5.25rem;
      margin-top: 5.25rem;
    }
  }
}
.col-bottom {
  @include media-breakpoint-down(lg) {
    margin-top: 5rem;
  }
}
.toggle-mission {
  margin: 5rem auto 0 auto;
  display: block;
}
.page-projects {
  @media screen and (max-width: 500px) {
    .lottie-illustration-hero {
      width: 130vw;
      position: relative;
      left: 50%;
      z-index: 10;
      transform: translateX(-50%);
    }
  }
}
