.floaties-container {
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
}
.ufo-wrapper {
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 100%;
    height: 50%;
    pointer-events: none;
    opacity: 1;
    display: none;
    @include media-breakpoint-up(sm) {
        display: block;
    }
    @include media-breakpoint-up(lg) {
        z-index: 10;
    }
}
.ufo {
    position: relative;
    z-index: -1;
    width: 100%;
    height: 100%;
}
.scrap {
    pointer-events: none;
    position: absolute;
    opacity: 0.3;
    transition-property: transform, opacity;
    background-size: contain;
    width: 60px;
    height: 40px;
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center;
    &.aos-animate {
        transform: translateY(0) translateX(0);
        @include media-breakpoint-up(md) {
            opacity: 1;
        }
    }
}
.floaties-type1 {
    .ufo-wrapper {
        bottom: 0;
    }
    .hero1 {
        left: 28%;
        top: -36%;
        width: 20px;
        height: 20px;
    }
    .hero2 {
        right: 13%;
        top: 72%;
        width: 70px;
        height: 30px;
    }
    .hero3 {
        right: 16%;
        top: 50%;
        width: 60px;
        height: 60px;
    }
    .hero4 {
        right: 13%;
        top: 14%;
        width: 30px;
        height: 30px;
    }
    .hero5 {
        right: 30%;
        top: 25%;
        width: 30px;
        height: 30px;
    }
    .hero6 {
        right: 25%;
        top: 12%;
        width: 20px;
        height: 20px;
    }
    .hero7 {
        left: 16%;
        top: 70%;
        width: 100px;
        height: 20px;
    }
    .hero8 {
        left: 19%;
        top: 31%;
        width: 20px;
        height: 20px;
    }
    .hero9 {
        left: 16%;
        top: -10%;
    }
    .hero10 {
        right: 5%;
        top: -10%;
    }
    .hero11 {
        left: 5%;
        top: -2%;
        width: 90px;
        height: 50px;
    }
    .hero12 {
        left: -10%;
        top: -12%;
        width: 40px;
        height: 40px;
    }
    .hero13 {
        right: 13%;
        top: 7%;
        width: 40px;
        height: 30px;
    }
    .hero14 {
        left: 8%;
        top: 40%;
    }
}
.floaties-type2 {
    .meet1 {
        top: 27%;
        right: 5%;
        width: 65px;
        height: 30px;
    }
    .meet2 {
        top: -15%;
        right: 12%;
        width: 100px;
        height: 35px;
    }
    .meet3 {
        top: 35%;
        left: 50%;
        width: 40px;
        height: 40px;
    }
    .meet4 {
        right: 7%;
        top: 58%;
        width: 60px;
        height: 20px;
    }
    .meet5 {
        left: 58%;
        top: 42%;
        width: 90px;
        height: 30px;
    }
    .meet6 {
        left: 52%;
        top: 74%;
        width: 110px;
        height: 30px;
    }
    .meet6 {
        left: 45%;
        top: 65%;
        width: 110px;
        height: 30px;
    }
    .meet7 {
        left: 50%;
        top: 55%;
        width: 40px;
        height: 30px;
    }
    .meet8 {
        left: -18%;
        top: 47%;
        width: 25px;
        height: 20px;
    }
    .meet9 {
        left: 11%;
        top: -13%;
        width: 25px;
        height: 20px;
    }
    .meet10 {
        left: -40%;
        top: 15%;
        width: 25px;
        height: 20px;
    }
    .meet11 {
        right: 29%;
        top: 14%;
        width: 115px;
        height: 20px;
    }
    .meet12 {
        right: 0;
        top: 80%;
        width: 45px;
        height: 20px;
    }
}
.floaties-type3 {
    .cto1 {
        top: 10%;
        left: 60%;
        width: 30px;
        height: 20px;
    }
    .cto2 {
        top: 45%;
        left: 4%;
        width: 40px;
        height: 20px;
    }
    .cto3 {
        left: 45%;
        top: 50%;
        width: 90px;
        height: 60px;
    }
    .cto4 {
        left: 50%;
        top: 65%;
        width: 40px;
        height: 20px;
    }
    .cto5 {
        right: 15%;
        top: 70%;
        width: 100px;
        height: 30px;
    }
    .cto6 {
        left: 18%;
        top: -3rem;
        width: 35px;
        height: 35px;
    }
    .cto7 {
        right: 19%;
        top: 25%;
        width: 40px;
        height: 20px;
    }
    .cto8 {
        right: 51%;
        top: 70%;
        width: 90px;
        height: 37px;
    }
    .cto9 {
        right: 8%;
        top: 8%;
        width: 100px;
        height: 37px;
    }
}
.floaties-type4 {
    .connect1 {
        top: 67%;
        left: 28%;
        width: 50px;
        height: 30px;
    }
    .connect2 {
        top: 75%;
        left: 34%;
        width: 60px;
        height: 35px;
    }

    .connect3 {
        right: 11%;
        top: 36%;
        width: 20px;
        height: 20px;
    }

    .connect4 {
        left: 36%;
        top: 20%;
        width: 60px;
        height: 20px;
    }

    .connect5 {
        left: 44%;
        top: 55%;
        width: 90px;
        height: 30px;
    }

    .connect6 {
        right: 6%;
        top: 30%;
        width: 48px;
        height: 48px;
    }

    .connect7 {
        top: 41%;
        right: 62%;
        width: 50px;
        height: 20px;
    }

    .connect8 {
        left: 29%;
        top: 14%;
        width: 30px;
        height: 100px;
    }
    .connect9 {
        left: -11%;
        bottom: 30%;
        width: 40px;
        height: 40px;
    }
    .connect10 {
        left: 22%;
        bottom: 5%;
        width: 90px;
        height: 20px;
    }
    .connect11 {
        left: 11%;
        bottom: 50%;
        width: 20px;
        height: 20px;
    }
    .connect12 {
        left: 12%;
        bottom: 67%;
        width: 30px;
        height: 30px;
    }
    .connect13 {
        left: 5%;
        bottom: 59%;
        width: 100px;
        height: 20px;
    }

    .connect14 {
        right: 3%;
        top: 75%;
        width: 50px;
        height: 20px;
    }

    .connect15 {
        right: 14%;
        top: 65%;
        width: 30px;
        height: 20px;
    }
}

.floaties-type5 {
    .ufo-wrapper {
        height: 70%;

        @include media-breakpoint-up(md) {
            height: 100%;
        }
    }
    .deploy1 {
        top: 60%;
        right: 57%;
        width: 20px;
        height: 20px;
    }

    .deploy2 {
        top: 75%;
        left: 9%;
        width: 25px;
        height: 25px;
    }
    .deploy3 {
        left: 47%;
        top: 77%;
        width: 80px;
        height: 30px;
    }

    .deploy4 {
        left: 25%;
        top: 68%;
        width: 110px;
        height: 30px;
    }
    .deploy5 {
        @include media-breakpoint-down(md) {
            top: 52%;
        }
        left: 10%;
        top: 41%;
        width: 30px;
        height: 30px;
    }
    .deploy6 {
        left: 10%;
        top: 59%;
        width: 100px;
        height: 30px;
    }

    .deploy7 {
        left: 4%;
        top: 58%;
        width: 30px;
        height: 30px;
    }
    .deploy8 {
        left: 23%;
        top: 23%;
        width: 45px;
        height: 45px;
    }

    .deploy9 {
        @include media-breakpoint-down(md) {
            left: -27%;
        }
        left: 17%;
        top: 31%;
        width: 40px;
        height: 40px;
    }

    .deploy10 {
        right: 10%;
        top: 35%;
        width: 99px;
        height: 39px;
    }

    .deploy11 {
        right: 48%;
        top: 40%;
        width: 40px;
        height: 40px;
    }

    .deploy12 {
        right: 6%;
        top: 53%;
        width: 50px;
        height: 40px;
    }
    .deploy13 {
        left: 37%;
        top: 28%;
        width: 70px;
        height: 50px;
    }
}

.floaties-type6 {
    .hero1 {
        left: 100%;
        top: 11%;
    }
    .hero2 {
        right: 60%;
        top: 72%;
        width: 100px;
    }
    .hero3 {
        right: 108%;
        top: -1%;
    }
    .hero4 {
        right: 113%;
        top: -18%;
        width: 90px;
        height: 30px;
    }
    .hero5 {
        right: 65%;
        top: 25%;
        width: 40px;
    }
    .hero6 {
        right: -10%;
        top: 32%;
        width: 30px;
        height: 90px;
    }
    .hero7 {
        left: 119%;
        top: 32%;
        width: 30px;
        height: 90px;
    }
    .hero9 {
        left: -7%;
        top: 44%;
    }
    .hero12 {
        left: -5%;
        top: -12%;
        width: 30px;
        height: 25px;
    }
    .hero14 {
        left: -12%;
        top: -26%;
        width: 30px;
        height: 20px;
    }
}

.floaties-type7 {
    .solved1 {
        top: 3%;
        right: 10%;
        width: 20px;
        height: 20px;
    }

    .solved2 {
        top: 20%;
        right: 31%;
        width: 20px;
        height: 15px;
    }

    .solved3 {
        top: 14%;
        left: 11%;
        width: 20px;
        height: 20px;
    }

    .solved4 {
        top: 9%;
        right: 1%;
        width: 25px;
        height: 25px;
    }

    .solved5 {
        left: 9%;
        top: -5%;
        width: 60px;
        height: 40px;
    }

    .solved6 {
        right: 15%;
        top: 30%;
        width: 60px;
        height: 30px;
    }

    .solved7 {
        right: 2%;
        top: -4%;
        width: 60px;
        height: 40px;
    }

    .solved8 {
        right: 30%;
        top: 49%;
        width: 100px;
        height: 30px;
    }
    .solved9 {
        left: 16%;
        top: 22%;
        width: 25px;
        height: 20px;
    }

    .solved10 {
        left: 8%;
        top: 4%;
        width: 20px;
        height: 20px;
    }

    .solved11 {
        right: 18%;
        top: 19%;
        width: 35px;
        height: 20px;
    }

    .solved12 {
        left: 0%;
        top: 20%;
        width: 20px;
        height: 20px;
    }
}

.floaties-type8 {
    .hero-services1 {
        left: 14%;
        top: 75%;
        width: 20px;
        height: 20px;
    }
    .hero-services2 {
        right: 14%;
        top: 23%;
        width: 70px;
        height: 30px;
    }
    .hero-services3 {
        left: 6%;
        top: 51%;
        width: 30px;
        height: 30px;
    }
    .hero-services4 {
        left: 45%;
        top: 80%;
        width: 120px;
        height: 30px;
    }

    .hero-services5 {
        right: 17%;
        top: 50%;
        width: 50px;
        height: 40px;
    }

    .hero-services6 {
        right: 5%;
        top: 12%;
        width: 20px;
        height: 20px;
    }

    .hero-services7 {
        left: 47%;
        top: 14%;
        width: 100px;
        height: 30px;
    }

    .hero-services8 {
        left: 50%;
        top: 64%;
        width: 40px;
        height: 40px;
    }

    .hero-services9 {
        right: 15%;
        top: 68%;
        width: 40px;
        height: 40px;
    }

    .hero-services10 {
        right: 34%;
        top: 60%;
        width: 80px;
        height: 50px;
    }
    .hero-services11 {
        left: 10%;
        top: -9%;
        width: 40px;
        height: 40px;
    }

    .hero-services12 {
        right: -10%;
        top: 60%;
        width: 40px;
        height: 70px;
    }
}

.floaties-type9 {
    .services-deploy1 {
        top: 30%;
        right: 50%;
        width: 80px;
        height: 40px;
    }

    .services-deploy2 {
        top: 48%;
        left: 43%;
        width: 100px;
        height: 20px;
    }
    .services-deploy3 {
        left: 39%;
        top: 10%;
        width: 30px;
        height: 40px;
    }

    .services-deploy4 {
        left: 39%;
        top: 66%;
        width: 110px;
        height: 40px;
    }
    .services-deploy5 {
        left: 14%;
        top: 24%;
        width: 40px;
        height: 40px;
    }
    .services-deploy6 {
        left: 21%;
        top: 59%;
        width: 90px;
        height: 40px;
    }

    .services-deploy7 {
        left: 45%;
        top: 65%;
        width: 100px;
        height: 30px;
    }
    .services-deploy8 {
        right: 3%;
        top: 23%;
        width: 55px;
        height: 55px;
    }

    .services-deploy9 {
        left: 43%;
        top: 23%;
        width: 40px;
        height: 40px;
    }

    .services-deploy10 {
        right: 8%;
        top: 35%;
        width: 20px;
        height: 30px;
    }

    .services-deploy11 {
        right: 57%;
        top: 36%;
        width: 60px;
        height: 40px;
    }

    .services-deploy12 {
        left: 16%;
        top: 46%;
        width: 30px;
        height: 30px;
    }
    .services-deploy13 {
        left: 16%;
        top: 10%;
        width: 20px;
        height: 30px;
    }

    .services-deploy14 {
        right: 10%;
        top: 42%;
        width: 20px;
        height: 30px;
    }
}

.floaties-type10 {
    .services-sand1 {
        top: -15%;
        right: 5%;
        width: 80px;
        height: 40px;
    }

    .services-sand2 {
        top: -10%;
        right: 15%;
        width: 100px;
        height: 20px;
    }
    .services-sand3 {
        right: 15%;
        top: 30%;
        width: 80px;
        height: 40px;
    }

    .services-sand4 {
        right: 0;
        top: 38%;
        width: 60px;
        height: 60px;
    }
    .services-sand5 {
        top: 85%;
        right: 10%;
        width: 35px;
        height: 40px;
    }
    .services-sand6 {
        left: 50%;
        top: 15%;
        width: 70px;
        height: 40px;
    }

    .services-sand7 {
        right: -3%;
        top: 20%;
        width: 100px;
        height: 30px;
    }
    .services-sand8 {
        right: 52%;
        top: 0;
        width: 20px;
        height: 20px;
    }

    .services-sand9 {
        left: 47%;
        top: 50%;
        width: 30px;
        height: 30px;
    }

    .services-sand10 {
        right: 45%;
        top: 65%;
        width: 40px;
        height: 30px;
    }
}

.floaties-type11 {
    .services-relentless1 {
        top: 0;
        right: 11%;
        width: 20px;
        height: 20px;
    }

    .services-relentless2 {
        top: 10%;
        left: 10%;
        width: 20px;
        height: 20px;
    }
    .services-relentless3 {
        right: 48%;
        top: 10%;
        width: 20px;
        height: 20px;
    }

    .services-relentless4 {
        left: 16%;
        top: 10%;
        width: 50px;
        height: 50px;
    }
    .services-relentless5 {
        top: 40%;
        left: 8%;
        width: 100px;
        height: 40px;
    }
    .services-relentless6 {
        right: 3%;
        top: 15%;
        width: 90px;
        height: 40px;
    }

    .services-relentless7 {
        left: 15%;
        top: 20%;
        width: 30px;
        height: 30px;
    }
    .services-relentless8 {
        right: 8%;
        top: 25%;
        width: 60px;
        height: 40px;
    }

    .services-relentless9 {
        left: 12%;
        top: 30%;
        width: 30px;
        height: 30px;
    }

    .services-relentless10 {
        right: 72%;
        top: 7%;
        width: 50px;
        height: 50px;
    }

    .services-relentless11 {
        left: 40%;
        top: 48%;
        width: 40px;
        height: 40px;
    }
}

.floaties-type12 {
    .services-up1 {
        top: 30%;
        left: 8%;
        width: 40px;
        height: 40px;
    }

    .services-up2 {
        top: 10%;
        right: 12%;
        width: 90px;
        height: 30px;
    }
    .services-up3 {
        left: 50%;
        top: 35%;
        width: 150px;
        height: 20px;
    }

    .services-up4 {
        left: 50%;
        top: 25%;
        width: 100px;
        height: 50px;
    }
    .services-up5 {
        top: 35%;
        right: 8%;
        width: 30px;
        height: 20px;
    }
    .services-up6 {
        left: 54%;
        top: 6%;
        width: 50px;
        height: 40px;
    }

    .services-up7 {
        right: 20%;
        top: 0;
        width: 20px;
        height: 20px;
    }
}

.floaties-type13 {
    .hero-pricing1 {
        left: 10%;
        top: -11%;
        width: 20px;
        height: 20px;
    }
    .hero-pricing2 {
        right: 15%;
        top: 61%;
        width: 100px;
        height: 30px;
    }
    .hero-pricing3 {
        left: 5%;
        top: 35%;
        width: 20px;
        height: 20px;
    }
    .hero-pricing4 {
        left: 74%;
        top: -10%;
        width: 20px;
        height: 20px;
    }
    .hero-pricing5 {
        right: 9%;
        top: 9%;
        width: 40px;
        height: 40px;
    }

    .hero-pricing6 {
        right: 11%;
        top: 31%;
        width: 30px;
        height: 20px;
    }

    .hero-pricing7 {
        left: 85%;
        top: 76%;
        width: 90px;
        height: 30px;
    }
    .hero-pricing8 {
        left: 60%;
        top: -8%;
        width: 20px;
        height: 20px;
    }
    .hero-pricing9 {
        right: 45%;
        top: 27%;
        width: 50px;
        height: 50px;
    }
    .hero-pricing10 {
        right: 1%;
        top: -9%;
        width: 60px;
        height: 50px;
    }
    .hero-pricing11 {
        left: -1%;
        top: 21%;
    }
    .hero-pricing12 {
        right: -8%;
        top: 50%;
        width: 60px;
        height: 60px;
    }
    .hero-pricing13 {
        right: 12%;
        top: -3%;
        width: 50px;
        height: 20px;
    }
    .hero-pricing14 {
        left: 0;
        top: 50%;
        width: 60px;
        height: 55px;
    }
    .hero-pricing15 {
        right: 20%;
        top: -13%;
        width: 32px;
        height: 25px;
    }
}

.floaties-type14 {
    .hero1 {
        left: 93%;
        top: 11%;
        width: 20px;
        height: 20px;
    }
    .hero2 {
        right: 60%;
        top: 72%;
        width: 100px;
    }
    .hero3 {
        right: 95%;
        top: -1%;
        width: 55px;
        height: 55px;
    }
    .hero4 {
        right: 96%;
        top: -18%;
        width: 90px;
        height: 40px;
    }
    .hero5 {
        right: 64%;
        top: 25%;
        width: 40px;
    }
    .hero6 {
        right: 10%;
        top: 32%;
        width: 30px;
        height: 90px;
    }
    .hero7 {
        left: 99%;
        top: 32%;
        width: 30px;
        height: 90px;
    }
    .hero9 {
        left: 7%;
        top: 44%;
        width: 60px;
        height: 40px;
    }
    .hero10 {
        right: 0;
        top: 0;
        width: 60px;
        height: 40px;
    }
    .hero11 {
        left: 5%;
        top: -12%;
        width: 60px;
        height: 40px;
    }
    .hero12 {
        left: 70%;
        top: -12%;
        width: 30px;
        height: 25px;
    }
    .hero14 {
        left: 10%;
        top: -26%;
        width: 30px;
        height: 20px;
    }
}

.floaties-type15 {
    .services-sand1 {
        top: -15%;

        right: 5%;
        width: 80px;
        height: 40px;
    }

    .services-sand2 {
        top: -10%;
        right: 15%;
        width: 100px;
        height: 20px;
    }
    .services-sand3 {
        right: 15%;
        top: 30%;
        width: 80px;
        height: 40px;
    }

    .services-sand4 {
        right: 0;
        top: 38%;
        width: 60px;
        height: 60px;
    }
    .services-sand5 {
        top: 85%;
        right: 10%;
        width: 35px;
        height: 40px;
    }
    .services-sand6 {
        left: 50%;
        top: 15%;
        width: 70px;
        height: 40px;
    }

    .services-sand7 {
        right: -3%;
        top: 20%;
        width: 100px;
        height: 30px;
    }
    .services-sand8 {
        right: 52%;
        top: 0;
        width: 20px;
        height: 20px;
    }

    .services-sand9 {
        left: 47%;
        top: 50%;
        width: 30px;
        height: 30px;
    }

    .services-sand10 {
        right: 45%;
        top: 65%;
        width: 40px;
        height: 30px;
    }
}

.floaties-type16 {
    .meet1 {
        top: 94%;
        right: -15%;
        width: 20px;
        height: 30px;
    }
    .meet2 {
        top: 62%;
        right: -16%;
        width: 100px;
        height: 35px;
    }
    .meet3 {
        top: -23%;
        left: -14%;
        width: 35px;
    }
    .meet4 {
        right: -4%;
        top: 30%;
        width: 40px;
    }
    .meet6 {
        left: 118%;
        top: 14%;
    }

    .meet7 {
        left: 1%;
        top: 70%;
        width: 40px;
    }
    .meet8 {
        left: -5%;
        top: 52%;
        width: 30px;
    }
    .meet9 {
        left: -14%;
        top: 45%;
        width: 25px;
    }
    .meet10 {
        left: -13%;
        top: 98%;
        width: 25px;
    }
    .meet11 {
        right: 98%;
        top: 8%;
        width: 95px;
        height: 20px;
    }
    .meet12 {
        left: 100%;
        top: 100%;
        width: 30px;
    }
}

.floaties-type17 {
    .hero1 {
        left: 65%;
        top: 60%;
        width: 20px;
        height: 20px;
    }
    .hero2 {
        right: 96%;
        top: 32%;
        width: 80px;
        height: 60px;
    }
    .hero3 {
        right: 12%;
        top: -21%;
        width: 60px;
        height: 60px;
    }
    .hero4 {
        right: 10%;
        top: 51%;
        width: 20px;
        height: 20px;
    }
    .hero5 {
        right: 7%;
        top: 7%;
        width: 40px;
        height: 40px;
    }
    .hero6 {
        right: 96%;
        top: 7%;
        width: 20px;
    }
    .hero7 {
        left: 64%;
        top: 83%;
    }
    .hero8 {
        left: 69%;
        top: 20%;
        width: 20px;
        height: 20px;
    }
    .hero9 {
        left: 92%;
        top: 26%;
    }
    .hero10 {
        right: -1%;
        top: 78%;
    }
    .hero11 {
        left: 90%;
        top: 75%;
        width: 20px;
        height: 60px;
    }
    .hero12 {
        left: 4%;
        top: 51%;
        width: 48px;
        height: 52px;
    }
    .hero13 {
        right: 100%;
        top: -10%;
        width: 45px;
        height: 30px;
    }
}

.floaties-type18 {
    .hero1 {
        left: 75%;
        top: 103%;
        width: 20px;
        height: 20px;
    }
    .hero2 {
        right: 100%;
        top: 82%;
        width: 70px;
        height: 30px;
    }
    .hero3 {
        right: 0%;
        top: 14%;
        width: 60px;
        height: 60px;
    }
    .hero4 {
        right: -2%;
        top: 34%;
        width: 30px;
        height: 30px;
    }
    .hero5 {
        right: 93%;
        top: 23%;
        width: 50px;
        height: 40px;
    }
    .hero6 {
        right: 114%;
        top: 57%;
        width: 20px;
        height: 20px;
    }
    .hero7 {
        left: 60%;
        top: 110%;
        width: 100px;
        height: 20px;
    }
    .hero8 {
        left: 18%;
        top: 70%;
        width: 20px;
        height: 20px;
    }
    .hero9 {
        left: 88%;
        top: 42%;
        height: 40px;
        width: 70px;
    }
    .hero10 {
        right: -9%;
        top: 55%;
        height: 40px;
        width: 80px;
    }
    .hero11 {
        left: 80%;
        top: 84%;
        height: 60px;
        width: 80px;
    }
    .hero12 {
        left: -10%;
        top: 12%;
        width: 70px;
        height: 30px;
    }
    .hero14 {
        left: -3%;
        top: 40%;
        width: 60px;
        height: 40px;
    }
}

.floaties-type19 {
    .hero-services1 {
        left: 76%;
        top: 88%;
        width: 20px;
        height: 20px;
    }
    .hero-services2 {
        right: 93%;
        top: 41%;
        height: 30px;
        width: 110px;
    }
    .hero-services3 {
        left: 3%;
        top: 120%;
        height: 30px;
        width: 20px;
    }
    .hero-services4 {
        left: 82%;
        top: 80%;
        width: 120px;
        height: 30px;
    }
    .hero-services5 {
        right: 90%;
        top: 66%;
        width: 40px;
        height: 40px;
    }
    .hero-services6 {
        right: 2%;
        top: 38%;
        width: 28px;
        height: 20px;
    }

    .hero-services7 {
        left: 43%;
        top: 65%;
        width: 100px;
        height: 30px;
    }
    .hero-services8 {
        left: 90%;
        top: 49%;
        width: 45px;
        height: 40px;
    }
    .hero-services9 {
        right: 16%;
        top: 33%;
        width: 45px;
        height: 40px;
    }
    .hero-services10 {
        right: 10%;
        top: 11%;
        width: 65px;
        height: 50px;
    }
    .hero-services11 {
        left: 56%;
        top: 17%;
        width: 15px;
        height: 20px;
    }
}

.floaties-type20 {
    .connect1 {
        top: 43%;
        left: 34%;
        width: 50px;
        height: 30px;
    }
    .connect2 {
        top: 109%;
        left: 31%;
        width: 110px;
        height: 35px;
    }
    .connect3 {
        right: 4%;
        top: 67%;
        width: 30px;
        height: 20px;
    }
    .connect4 {
        left: 5%;
        top: 40%;
        width: 60px;
        height: 25px;
    }
    .connect5 {
        left: 92%;
        top: 19%;
        width: 90px;
        height: 30px;
    }
    .connect6 {
        right: 0%;
        top: 40%;
        width: 50px;
        height: 50px;
    }
    .connect8 {
        left: 35%;
        top: 6%;
        width: 25px;
        height: 90px;
    }
    .connect9 {
        left: 12%;
        bottom: 4%;
        width: 40px;
        height: 40px;
    }
    .connect10 {
        left: 31%;
        bottom: 18%;
        width: 115px;
        height: 30px;
    }
    .connect11 {
        left: 11%;
        bottom: 50%;
        width: 20px;
        height: 20px;
    }
    .connect12 {
        left: 12%;
        bottom: 67%;
        width: 30px;
        height: 30px;
    }
    .connect13 {
        left: 21%;
        bottom: 59%;
        width: 100px;
        height: 20px;
    }
    .connect14 {
        right: 6%;
        top: 78%;
        width: 20px;
        height: 20px;
    }
    .connect15 {
        right: 55%;
        top: 65%;
        width: 90px;
        height: 46px;
    }
}

.floaties-type21 {
    .services-sand1 {
        top: 50%;
        right: 12%;
        width: 80px;
        height: 40px;
    }
    .services-sand2 {
        top: 84%;
        right: 11%;
        width: 100px;
        height: 20px;
    }
    .services-sand3 {
        right: 20%;
        top: 100%;
        width: 100px;
        height: 40px;
    }
    .services-sand4 {
        right: 15%;
        top: 17%;
        width: 60px;
        height: 60px;
    }
    .services-sand5 {
        top: 54%;

        right: 51%;
        width: 35px;
        height: 40px;
    }
    .services-sand6 {
        left: 59%;
        top: 82%;
        width: 40px;
        height: 40px;
    }
    .services-sand7 {
        right: 0;
        top: 60%;
        width: 100px;
        height: 30px;
    }
    .services-sand8 {
        right: 40%;
        top: 57%;
        width: 20px;
        height: 20px;
    }
    .services-sand9 {
        height: 30px;
        width: 40px;
    }
    .services-sand10 {
        right: 40%;
        top: 26%;
        width: 40px;
        height: 30px;
    }
}

.floaties-type22 {
    .hero-services1 {
        left: -12%;
        top: 52%;
        height: 90px;
        width: 30px;
    }
    .hero-services2 {
        right: 55%;
        top: -9%;
        width: 55px;
        height: 55px;
    }
    .hero-services4 {
        left: 110%;
        top: 19%;
    }
    .hero-services3 {
        left: 38%;
        top: 32%;
        width: 90px;
        height: 30px;
    }

    .hero-services5 {
        right: 104%;
        top: 4%;
    }
    .hero-services6 {
        right: -6%;
        top: 30%;
        width: 25px;
        height: 25px;
    }
    .hero-services7 {
        left: 98%;
        top: -7%;
        width: 90px;
        height: 40px;
    }
    .hero-services8 {
        left: -5%;
        top: 39%;
    }
}

.floaties-type23 {
    .hero-contact1 {
        left: 11%;
        top: 21%;
        width: 20px;
        height: 20px;
    }
    .hero-contact2 {
        left: 1%;
        top: 44%;
        width: 65px;
        height: 40px;
    }
    .hero-contact3 {
        right: 10%;
        top: -3%;
        width: 60px;
        height: 60px;
    }
    .hero-contact4 {
        right: 9%;
        top: -10%;
        width: 20px;
        height: 20px;
    }

    .hero-contact5 {
        right: 10%;
        top: 12%;
        width: 90px;
        height: 40px;
    }

    .hero-contact6 {
        right: 0;
        top: 49%;
        width: 20px;
        height: 20px;
    }

    .hero-contact7 {
        left: -1%;
        top: 0;
        width: 90px;
        height: 30px;
    }

    .hero-contact8 {
        right: 2%;
        top: 22%;
        width: 20px;
        height: 20px;
    }

    .hero-contact9 {
        left: 12%;
        top: -10%;
    }

    .hero-contact11 {
        right: -1%;
        top: 7%;
        width: 30px;
        height: 60px;
    }
    .hero-contact12 {
        right: 5%;
        top: 17%;
        width: 40px;
        height: 40px;
    }
}

.floaties-type24 {
    .hero-report1 {
        left: 22%;
        top: 70%;
        width: 20px;
        height: 20px;
    }
    .hero-report2 {
        right: 19%;
        top: 23%;
        width: 70px;
        height: 30px;
    }
    .hero-report3 {
        left: -16%;
        top: 103%;
        width: 30px;
        height: 30px;
    }
    .hero-report4 {
        right: 8%;
        top: 63%;
        width: 30px;
        height: 30px;
    }

    .hero-report5 {
        left: 10%;
        top: 43%;
        width: 40px;
        height: 40px;
    }

    .hero-report6 {
        right: -2%;
        top: 12%;
        width: 20px;
        height: 20px;
    }

    .hero-report7 {
        left: 30%;
        top: 81%;
        width: 110px;
        height: 30px;
    }

    .hero-report8 {
        left: 18%;
        top: -9%;
        width: 20px;
        height: 20px;
    }

    .hero-report9 {
        left: 0;
        top: 17%;
    }

    .hero-report10 {
        right: 8%;
        top: 6px;
        width: 70px;
        height: 50px;
    }

    .hero-report11 {
        left: 82%;
        top: 72%;
        width: 80px;
        height: 50px;
    }
    .hero-report12 {
        right: 7%;
        top: 78%;
        width: 40px;
        height: 70px;
    }
    .hero-report13 {
        right: 2%;
        top: 32%;
        width: 40px;
        height: 40px;
    }
    .hero-report14 {
        left: 5%;
        top: -1%;
    }

    .hero-report15 {
        left: 8%;
        top: 74%;
        width: 30px;
        height: 30px;
    }
}

.floaties-type25 {
    .connect1 {
        top: 65%;
        left: 12%;
        width: 50px;
        height: 30px;
    }
    .connect2 {
        top: 75%;
        left: 19%;
        width: 110px;
        height: 35px;
    }

    .connect3 {
        right: 25%;
        top: 36%;
        width: 20px;
        height: 20px;
    }

    .connect4 {
        left: 20%;
        top: 20%;
        width: 60px;
        height: 20px;
    }

    .connect5 {
        left: 30%;
        top: 55%;
        width: 90px;
        height: 30px;
    }

    .connect6 {
        right: 20%;
        top: 40%;
        width: 50px;
        height: 50px;
    }

    .connect7 {
        top: 41%;
        right: 74%;
        width: 50px;
        height: 20px;
    }

    .connect8 {
        left: 14%;
        top: 22%;
        width: 15px;
        height: 100px;
    }
    .connect9 {
        left: -5%;
        bottom: 30%;
        width: 40px;
        height: 40px;
    }
    .connect10 {
        left: 7%;
        bottom: 5%;
        width: 90px;
        height: 20px;
    }
    .connect11 {
        left: 0%;
        bottom: 50%;
        width: 20px;
        height: 20px;
    }
    .connect12 {
        left: -2%;
        bottom: 67%;
        width: 30px;
        height: 30px;
    }
    .connect13 {
        left: -6%;
        bottom: 59%;
        width: 100px;
        height: 20px;
    }

    .connect14 {
        right: 17%;
        top: 75%;
        width: 50px;
        height: 20px;
    }

    .connect15 {
        right: 28%;
        top: 65%;
        width: 30px;
        height: 20px;
    }
}

.floaties-type26 {
    .report1 {
        right: 3%;
        top: -20%;
        width: 20px;
        height: 20px;
    }
    .report2 {
        right: 22%;
        top: 49%;
        width: 70px;
        height: 30px;
    }
    .report3 {
        left: 8%;
        top: 32%;
        width: 50px;
        height: 50px;
    }
    .report4 {
        right: 2%;
        top: -59%;
        width: 30px;
        height: 30px;
    }

    .report5 {
        left: 7%;
        top: 60%;
        width: 40px;
        height: 40px;
    }

    .report6 {
        right: 0%;
        top: 12%;
        width: 20px;
        height: 20px;
    }

    .report7 {
        left: 5%;
        top: -50%;
        width: 110px;
        height: 30px;
    }

    .report8 {
        left: 3%;
        top: -64%;
        width: 20px;
        height: 20px;
    }

    .report9 {
        left: 4%;
        top: -30%;
    }

    .report10 {
        right: -3%;
        top: 57%;
        width: 110px;
        height: 50px;
    }
    .report11 {
        right: 10%;
        top: 24%;
        width: 80px;
        height: 50px;
    }
    .report12 {
        right: 0;
        top: 26%;
        width: 40px;
        height: 50px;
    }
    .report13 {
        left: 5%;
        top: -60%;
        width: 20px;
        height: 20px;
    }
    .report14 {
        top: -91%;
        left: 14%;
    }
}

.floaties-type27 {
    .hero-pricing1 {
        left: -10%;
        top: -11%;
        width: 20px;
        height: 20px;
    }
    .hero-pricing2 {
        right: 5%;
        top: 61%;
        width: 100px;
        height: 30px;
    }
    .hero-pricing3 {
        left: -5%;
        top: 35%;
        width: 20px;
        height: 20px;
    }
    .hero-pricing4 {
        left: 74%;
        top: -10%;
        width: 20px;
        height: 20px;
    }

    .hero-pricing5 {
        right: -15%;
        top: 9%;
        width: 40px;
        height: 40px;
    }

    .hero-pricing6 {
        right: -11%;
        top: 31%;
        width: 30px;
        height: 20px;
    }

    .hero-pricing7 {
        left: 44%;
        top: 63%;
        width: 90px;
        height: 30px;
    }

    .hero-pricing8 {
        left: 60%;
        top: -8%;
        width: 20px;
        height: 20px;
    }

    .hero-pricing9 {
        right: 49%;
        top: 27%;
        width: 50px;
        height: 50px;
    }

    .hero-pricing10 {
        right: -22%;
        top: -9%;
        width: 60px;
        height: 50px;
    }
    .hero-pricing11 {
        left: -18%;
        top: 21%;
        width: 60px;
        height: 70px;
    }

    .hero-pricing12 {
        right: -8%;
        top: 50%;
        width: 60px;
        height: 60px;
    }

    .hero-pricing13 {
        right: -10%;
        top: -3%;
        width: 50px;
        height: 20px;
    }

    .hero-pricing14 {
        left: -22%;
        top: 50%;
        width: 60px;
        height: 55px;
    }

    .hero-pricing15 {
        right: 2%;
        top: -13%;
        width: 32px;
        height: 25px;
    }
}

.hero-luke1 {
    left: 52%;
    top: 27%;
    width: 110px;
    height: 20px;
}
.hero-luke2 {
    left: -10%;
    top: 12%;
    width: 110px;
    height: 40px;
}
.hero-luke3 {
    right: 0%;
    top: 25%;
    width: 30px;
    height: 20px;
}
.hero-luke4 {
    right: -5%;
    top: 41%;
    width: 20px;
    height: 20px;
}

.hero-luke5 {
    left: -7%;
    top: 10%;
    width: 70px;
    height: 30px;
}

.increase1 {
    left: 3%;
    top: 3%;
    width: 70px;
    height: 50px;
}
.increase2 {
    left: -5%;
    top: 7%;
    width: 30px;
    height: 20px;
}
.increase3 {
    left: -3%;
    top: 22%;
    width: 20px;
    height: 20px;
}
.increase4 {
    right: 81%;
    top: 11%;
    width: 50px;
    height: 20px;
}

.increase5 {
    right: -11%;
    top: 19%;
    width: 90px;
    height: 40px;
}

.increase6 {
    right: -14%;
    top: 57%;
    width: 85px;
    height: 20px;
}

.increase7 {
    right: 1%;
    top: 11%;
    width: 30px;
    height: 30px;
}

.step {
    .ufo {
        left: unset;
    }
    &--1 {
        .connect4 {
            left: -7%;
            bottom: 45%;
            width: 35px;
            height: 30px;
        }
        .connect10 {
            left: 108%;
            bottom: 18%;
            width: 90px;
            height: 30px;
        }
        .connect11 {
            left: 110%;
            bottom: 42%;
            width: 20px;
            height: 20px;
        }
        .connect12 {
            left: -2%;
            bottom: 115%;
            width: 30px;
            height: 30px;
        }
    }
    &--2 {
        .connect4 {
            left: 93%;
            top: 70%;
            width: 40px;
            height: 20px;
        }
        .connect5 {
            left: 90%;
            top: 40%;
            width: 90px;
            height: 30px;
        }
        .connect6 {
            right: -6%;
            top: 55%;
            width: 50px;
            height: 50px;
        }
        .connect8 {
            left: -3%;
            top: 1%;
            width: 30px;
            height: 100px;
        }
    }
    &--3 {
        .connect2 {
            top: 24%;
            left: 6%;
        }
        .connect3 {
            right: 95%;
            top: 38%;
            width: 20px;
            height: 20px;
        }
        .connect9 {
            left: 0%;
            bottom: 50%;
            width: 20px;
            height: 40px;
        }
    }
    &--4 {
        .hero4 {
            right: 4%;
            top: 42%;
            width: 85px;
            height: 40px;
        }
        .services-sand1 {
            right: -2%;
            top: 30%;
            width: 20px;
            height: 40px;
        }
    }
}

.page-error {
    .ufo-wrapper {
        bottom: unset;
        top: 0;
    }
    .ufo {
        left: 0;
    }
    .connect2 {
        top: 104%;
        left: 19%;
    }
    .connect6 {
        right: 0%;
        top: -9%;
    }
    .connect5 {
        left: 34%;
        top: 15%;
    }
    .connect6 {
        right: 0%;
        top: -9%;
    }
    .connect8 {
        left: 7%;
        top: 0%;
        height: 100px;
        width: 20px;
    }
}


.header1 {
    left: 60%;
    top: -10px;
    width: 40px;
    height: 42px;
    display: none;
    @include media-breakpoint-up(xl) {
        display: block;
    }
}

.floaties-type28 {
    .ufo-wrapper {
        bottom: 0;
        height: 100%;
    }
    .hero1 {
        left: 2vw;
        top: 10%;
        width: 20px;
        height: 20px;
    }
    .hero2 {
        left: 1vw;
        top: 40%;
        width: 24px;
        height: 10px;
    }
    .hero3 {
        left: 8%;
        top: 57%;
        width: 40px;
        height: 14px;
    }
    .hero4 {
        left: 20%;
        top: 20%;
        width: 33px;
        height: 18px;
    }
    .hero5 {
        left: 30%;
        top: -2%;
        width: 20px;
        height: 19px;
    }
    .hero7 {
        left: 75%;
        top: 3%;
        width: 25px;
        height: 10px;
    }
    .hero8 {
        left: 74%;
        top: 27%;
        width: 18px;
        height: 20px;
    }
    .hero9 {
        left: 80%;
        top: 32%;
        width: 58px;
        height: 12px;
    }
    .hero10 {
        right: 10%;
        top: 18%;
        width: 67px;
        height: 24px;
    }
    .hero11 {
        right: -15px;
        top: 30%;
        width: 57px;
        height: 57px;
    }
}

.floaties-type29 {
    .ufo-wrapper {
        height: 100%;
        overflow: hidden;
    }
    .footer1 {
        left: 2%;
        top: 36%;
        width: 26px;
        height: 12px;
    }
    .footer2 {
        left: 10%;
        top: 15%;
        width: 43px;
        height: 15px;
    }
    .footer3 {
        left: 14%;
        top: 35%;
        width: 117px;
        height: 24px;
        z-index: 3;

        @media screen and (min-width: 1560px) {
            left: 19%;
        }
    }
    .footer4 {
        left: 45%;
        top: 55%;
        width: 19px;
        height: 21px;
    }
    .footer5 {
        left: 72%;
        top: 8%;
        width: 21px;
        height: 21px;
    }
    .footer6 {
        left: 76%;
        top: 50%;
        width: 72px;
        height: 25px;
        z-index: 3;
    }
    .footer7 {
        right: 12%;
        top: 28%;
        width: 22px;
        height: 20px;
    }
    .footer8 {
        right: 5%;
        top: 11%;
        width: 62px;
        height: 12px;
    }
}

.floaties-type30 {
    .ufo-wrapper {
        height: 100%;
        overflow: hidden;
    }
    .hero1 {
        left: 20%;
        top: 40%;
        width: 62px;
        height: 22px;
    }
    .hero2 {
        left: 7%;
        top: 15%;
        width: 30px;
        height: 16px;
        transform: rotate(180deg);
    }
    .hero3 {
        left: 27%;
        top: 13%;
        width: 52px;
        height: 53px;
        z-index: 3;
    }
    .hero4 {
        left: 34%;
        top: 52%;
        width: 19px;
        height: 21px;
    }    
    .hero5 {
        left: 61%;
        top: 3%;
        width: 18px;
        height: 18px;
    }
    .hero6 {
        left: 67%;
        top: 51%;
        width: 19px;
        height: 18px;
        z-index: 3;
    }
    .hero7 {
        left: 66%;
        top: 20%;
        width: 23px;
        height: 10px;
        transform: rotate(-10deg);
    }
    .hero8 {
        right: 22%;
        top: 31%;
        width: 103px;
        height: 20px;
    }
    .hero9 {
        right: 15%;
        top: 23%;
        width: 54px;
        height: 11px;
    }
    .hero10 {
        right: 22%;
        top: 17%;
        width: 23px;
        height: 10px;
    }
    .hero11 {
        right: 9%;
        top: 17%;
        width: 39px;
        height: 39px;
    }
}
.floaties-type31 {
    .hero-engage1 {
        top: 5%;
        left: -20%;
        width: 40px;
        height: 21px;
    }
    .hero-engage2 {
        top: -4%;
        left: -2%;
        width: 38px;
        height: 30px;
    }

    .hero-engage3 {
        left: 2%;
        top: -12rem;
        width: 20px;
        height: 20px;
    }

    .hero-engage4 {
        left: 0%;
        top: 16%;
        width: 40px;
        height: 20px;
    }

    .hero-engage5 {
        left: 5%;
        top: 1rem;
        width: 30px;
        height: 16px;
    }

    .hero-engage6 {
        left: 24%;
        top: -2rem;
        width: 50px;
        height: 40px;
    }

    .hero-engage7 {
        top: 0;
        right: 25%;
        width: 54px;
        height: 20px;
    }

    .hero-engage8 {
        right: 16%;
        top: -8rem;
        width: 15px;
        height: 20px;
        transform: rotate(-80deg);
    }
    .hero-engage9 {
        right: 0;
        top: -21rem;
        width: 55px;
        height: 44px;
        transform: rotate(-50deg);    
    }
    .hero-engage10 {
        left: 7%;
        bottom: 5%;
        width: 90px;
        height: 20px;
    }
    .hero-engage11 {
        right: -6%;
        top: 24%;
        width: 20px;
        height: 20px;
        transform: rotate(60deg);
    }
    .hero-engage12 {
        right: -12rem;
        top: 0;
        width: 40px;
        height: 45px;
        transform: rotate(-100deg);
    }

    .hero-engage13 {
        right: 30%;
        bottom: -6rem;
        width: 62px;
        height: 26px;
        transform: rotate(13deg);
    }
    .hero-engage14 {
        right: 3%;
        bottom: -13rem;
        width: 47px;
        height: 45px;
    }
    .hero-engage15 {
        left: 12%;
        bottom: -12rem;
        width: 20px;
        height: 20px;
        transform: rotate(100deg);
    }
    .hero-engage16 {
        left: 1rem;
        bottom: -10rem;
        width: 25px;
        height: 27px;
    }
    .hero-engage17 {
        right: 15%;
        bottom: -6rem;
        width: 26px;
        height: 20px;
    
    }
    .hero-engage18 {
        left: 32%;
        bottom: -9rem;
        width: 32px;
        height: 35px;
        transform: rotate(100deg);
    }
}


[data-aos="luke1"] {
    transform: translateY(-50px) translateX(70px) rotate(10deg);
}
[data-aos="luke2"] {
    transform: translateY(50px) translateX(50px) rotate(-20deg);
}
[data-aos="luke3"],
[data-aos="luke5"] {
    transform: translateY(-50px) translateX(-60px) rotate(-20deg);
}
[data-aos="luke4"] {
    transform: translateY(-50px) translateX(40px) rotate(-30deg);
}
[data-aos="increase1"] {
    transform: translateY(40px) translateX(30px) rotate(-30deg);
}
[data-aos="increase2"] {
    transform: translateY(-70px) translateX(-70px) rotate(50deg);
}
[data-aos="increase3"] {
    transform: translateY(-80px) translateX(-50px) rotate(-20deg);
}
[data-aos="increase4"] {
    transform: translateY(-60px) translateX(-70px) rotate(-10deg);
}
[data-aos="increase5"] {
    transform: translateY(90px) translateX(60px) rotate(50deg);
}
[data-aos="increase6"] {
    transform: translateY(-30px) translateX(-40px) rotate(-30deg);
}
[data-aos="increase7"] {
    transform: translateY(-50px) translateX(80px) rotate(10deg);
}
[data-aos="hero1"] {
    transform: translateY(40px) translateX(30px) rotate(-30deg);
}
[data-aos="hero2"] {
    transform: translateY(-70px) translateX(-70px) rotate(50deg);
}
[data-aos="hero3"] {
    transform: translateY(-80px) translateX(-50px) rotate(-20deg);
}
[data-aos="hero4"] {
    transform: translateY(-60px) translateX(-70px) rotate(-10deg);
}
[data-aos="hero5"] {
    transform: translateY(90px) translateX(60px) rotate(50deg);
}
[data-aos="hero6"] {
    transform: translateY(-30px) translateX(-40px) rotate(-30deg);
}
[data-aos="hero7"] {
    transform: translateY(-50px) translateX(80px) rotate(10deg);
}
[data-aos="hero8"] {
    transform: translateY(-50px) translateX(70px) rotate(10deg);
}
[data-aos="hero9"] {
    transform: translateY(50px) translateX(50px) rotate(-20deg);
}
[data-aos="hero10"] {
    transform: translateY(-50px) translateX(-60px) rotate(-20deg);
}
[data-aos="hero11"] {
    transform: translateY(-50px) translateX(40px) rotate(-30deg);
}
[data-aos="hero12"] {
    transform: translateY(-00px) translateX(20px) rotate(-40deg);
}
[data-aos="hero13"] {
    transform: translateY(70px) translateX(-70px) rotate(-20deg);
}
[data-aos="hero14"] {
    transform: translateY(-60px) translateX(80px) rotate(-10deg);
}
[data-aos="cto1"] {
    transform: translateY(-50px) translateX(-70px) rotate(-10deg);
}
[data-aos="cto2"] {
    transform: translateY(60px) translateX(-50px) rotate(-20deg);
}
[data-aos="cto3"] {
    transform: translateY(-70px) translateX(50px) rotate(30deg);
}
[data-aos="cto4"] {
    transform: translateY(-40px) translateX(-70px) rotate(-30deg);
}
[data-aos="cto5"] {
    transform: translateY(80px) translateX(-70px);
}
[data-aos="cto6"] {
    transform: translateY(50px) translateX(-80px) rotate(-15deg);
}
[data-aos="cto7"] {
    transform: translateY(40px) translateX(80px) rotate(-10deg);
}
[data-aos="cto8"] {
    transform: translateY(50px) translateX(70px) rotate(20deg);
}
[data-aos="connect1"] {
    transform: translateY(-70px) translateX(-80px) rotate(-10deg);
}
[data-aos="connect2"] {
    transform: translateY(-80px) translateX(-50px) rotate(-40deg);
}
[data-aos="connect3"] {
    transform: translateY(60px) translateX(100px) rotate(-170deg);
}
[data-aos="connect4"] {
    transform: translateY(-70px) translateX(-60px) rotate(-20deg);
}
[data-aos="connect5"] {
    transform: translateY(80px) translateX(-90px) rotate(-170deg);
}
[data-aos="connect6"] {
    transform: translateY(-50px) translateX(90px);
}
[data-aos="connect7"] {
    transform: translateY(-90px) translateX(100px) rotate(-10deg);
}
[data-aos="connect8"] {
    transform: translateY(-110px) translateX(-130px) rotate(-10deg);
}
[data-aos="connect9"] {
    transform: translateY(-50px) translateX(70px) rotate(-10deg);
}
[data-aos="connect10"] {
    transform: translateY(-120px) translateX(-110px) rotate(-10deg);
}
[data-aos="connect11"] {
    transform: translateY(-60px) translateX(70px) rotate(-10deg);
}
[data-aos="connect12"] {
    transform: translateY(-100px) translateX(-80px) rotate(-10deg);
}
[data-aos="connect13"] {
    transform: translateY(-80px) translateX(-30px) rotate(-10deg);
}
[data-aos="connect14"] {
    transform: translateY(110px) translateX(50px) rotate(-20deg);
}
[data-aos="connect15"] {
    transform: translateY(-80px) translateX(70px) rotate(-10deg);
    transition: 2s;
}
[data-aos="meet1"] {
    transform: translateY(-120px) translateX(-130px) rotate(-10deg);
}
[data-aos="meet2"] {
    transform: translateY(-150px) translateX(-80px) rotate(-20deg);
}
[data-aos="meet3"] {
    transform: translateY(220px) translateX(300px) rotate(-180deg);
}
[data-aos="meet4"] {
    transform: translateY(140px) translateX(-300px) rotate(-20deg);
}
[data-aos="meet5"] {
    transform: translateY(-50px) translateX(-60px) rotate(-170deg);
}
[data-aos="meet6"] {
    transform: translateY(130px) translateX(120px) rotate(-30deg);
}
[data-aos="meet7"] {
    transform: translateY(-110px) translateX(-80px) rotate(-20deg);
}
[data-aos="meet8"] {
    transform: translateY(-130px) translateX(30px) rotate(-10deg);
}
[data-aos="meet9"] {
    transform: translateY(-150px) translateX(-60px) rotate(10deg);
    transition: 2s;
}
[data-aos="meet10"] {
    transform: translateY(-150px) translateX(-80px) rotate(20deg);
}
[data-aos="meet11"] {
    transform: translateY(120px) translateX(-70px) rotate(-10deg);
}
[data-aos="deploy1"] {
    transform: translateY(80px) translateX(-90px) rotate(-10deg);
}
[data-aos="deploy2"] {
    transform: translateY(-60px) translateX(-80px) rotate(-20deg);
}
[data-aos="deploy3"] {
    transform: translateY(90px) translateX(110px) rotate(-100deg);
}
[data-aos="deploy4"] {
    transform: translateY(70px) translateX(80px) rotate(-20deg);
}
[data-aos="deploy5"] {
    transform: translateY(-90px) translateX(-60px) rotate(-170deg);
}
[data-aos="deploy6"] {
    transform: translateY(60px) translateX(70px) rotate(90deg);
}
[data-aos="deploy7"] {
    transform: translateY(60px) translateX(-80px) rotate(-10deg);
}
[data-aos="deploy8"] {
    transform: translateY(-70px) translateX(-60px) rotate(-10deg);
    transition: 2s;
}
[data-aos="deploy9"] {
    transform: translateY(-80px) translateX(-80px) rotate(10deg);
    transition: 2s;
}
[data-aos="deploy10"] {
    transform: translateY(-50px) translateX(-70px) rotate(20deg);
}
[data-aos="deploy11"] {
    transform: translateY(50px) translateX(-50px) rotate(-10deg);
}
[data-aos="deploy12"] {
    transform: translateY(110px) translateX(-50px) rotate(-50deg);
}
[data-aos="deploy13"] {
    transform: translateY(-50px) translateX(90px) rotate(-70deg);
}
[data-aos="solved1"] {
    transform: translateY(140px) translateX(-90px) rotate(-10deg);
}
[data-aos="solved2"] {
    transform: translateY(-150px) translateX(-100px) rotate(-20deg);
}
[data-aos="solved3"] {
    transform: translateY(120px) translateX(110px) rotate(-100deg);
}
[data-aos="solved4"] {
    transform: translateY(150px) translateX(80px) rotate(10deg);
}
[data-aos="solved5"] {
    transform: translateY(80px) translateX(90px);
}
[data-aos="solved6"] {
    transform: translateY(150px) translateX(-110px);
}
[data-aos="solved7"] {
    transform: translateY(-170px) translateX(-150px) rotate(-20deg);
}
[data-aos="solved8"] {
    transform: translateY(140px) translateX(80px) rotate(-10deg);
    transition: 2s;
}
[data-aos="solved9"] {
    transform: translateY(-200px) translateX(-100px);
    transition: 2s;
}
[data-aos="solved10"] {
    transform: translateY(-50px) translateX(-70px);
}
[data-aos="solved11"] {
    transform: translateY(-100px) translateX(-90px);
}
[data-aos="solved12"] {
    transform: translateY(-110px) translateX(-90px) rotate(-70deg);
}
[data-aos="hero-contact1"] {
    transform: translateY(40px) translateX(30px) rotate(-30deg);
}
[data-aos="hero-contact2"] {
    transform: translateY(-70px) translateX(-70px) rotate(50deg);
}
[data-aos="hero-contact3"] {
    transform: translateY(-80px) translateX(-50px) rotate(-20deg);
}
[data-aos="hero-contact4"] {
    transform: translateY(-60px) translateX(-70px) rotate(-10deg);
}
[data-aos="hero-contact5"] {
    transform: translateY(90px) translateX(60px) rotate(50deg);
}
[data-aos="hero-contact6"] {
    transform: translateY(-30px) translateX(-40px) rotate(-30deg);
}
[data-aos="hero-contact7"] {
    transform: translateY(-50px) translateX(80px) rotate(10deg);
}
[data-aos="hero-contact8"] {
    transform: translateY(-50px) translateX(70px) rotate(10deg);
}
[data-aos="hero-contact9"] {
    transform: translateY(50px) translateX(50px) rotate(-20deg);
}
[data-aos="hero-contact10"] {
    transform: translateY(-50px) translateX(-60px) rotate(-20deg);
}
[data-aos="hero-contact11"] {
    transform: translateY(-50px) translateX(40px) rotate(-30deg);
}
[data-aos="hero-contact12"] {
    transform: translateY(0) translateX(20px) rotate(-40deg);
}
[data-aos="hero-pricing1"] {
    transform: translateY(40px) translateX(30px) rotate(-30deg);
}
[data-aos="hero-pricing2"] {
    transform: translateY(-70px) translateX(-70px) rotate(50deg);
}
[data-aos="hero-pricing3"] {
    transform: translateY(-80px) translateX(-50px) rotate(-20deg);
}
[data-aos="hero-pricing4"] {
    transform: translateY(-60px) translateX(-70px) rotate(-10deg);
}
[data-aos="hero-pricing5"] {
    transform: translateY(90px) translateX(60px) rotate(50deg);
}
[data-aos="hero-pricing6"] {
    transform: translateY(-30px) translateX(-40px) rotate(-30deg);
}
[data-aos="hero-pricing7"] {
    transform: translateY(-50px) translateX(80px) rotate(10deg);
}
[data-aos="hero-pricing8"] {
    transform: translateY(-50px) translateX(70px) rotate(10deg);
}
[data-aos="hero-pricing9"] {
    transform: translateY(50px) translateX(50px) rotate(-20deg);
}
[data-aos="hero-pricing10"] {
    transform: translateY(-50px) translateX(-60px) rotate(-20deg);
}
[data-aos="hero-pricing11"] {
    transform: translateY(-50px) translateX(40px) rotate(-30deg);
}
[data-aos="hero-pricing12"] {
    transform: translateY(-50px) translateX(40px) rotate(-30deg);
}
[data-aos="hero-pricing13"] {
    transform: translateY(-50px) translateX(40px) rotate(-30deg);
}
[data-aos="hero-pricing14"] {
    transform: translateY(-50px) translateX(40px) rotate(-30deg);
}
[data-aos="hero-report1"] {
    transform: translateY(40px) translateX(30px) rotate(-30deg);
}
[data-aos="hero-report2"] {
    transform: translateY(-70px) translateX(-70px) rotate(50deg);
}
[data-aos="hero-report3"] {
    transform: translateY(-80px) translateX(-50px) rotate(-20deg);
}
[data-aos="hero-report4"] {
    transform: translateY(-60px) translateX(-70px) rotate(-10deg);
}
[data-aos="hero-report5"] {
    transform: translateY(90px) translateX(60px) rotate(50deg);
}
[data-aos="hero-report6"] {
    transform: translateY(-30px) translateX(-40px) rotate(-30deg);
}
[data-aos="hero-report7"] {
    transform: translateY(-50px) translateX(80px) rotate(10deg);
}
[data-aos="hero-report8"] {
    transform: translateY(-50px) translateX(70px) rotate(10deg);
}
[data-aos="hero-report9"] {
    transform: translateY(50px) translateX(50px) rotate(-20deg);
}
[data-aos="hero-report10"] {
    transform: translateY(-50px) translateX(-60px) rotate(-20deg);
}
[data-aos="hero-report11"] {
    transform: translateY(-50px) translateX(40px) rotate(-30deg);
}
[data-aos="hero-report12"] {
    transform: translateY(-00px) translateX(20px) rotate(-40deg);
}
[data-aos="hero-report13"] {
    transform: translateY(70px) translateX(-70px) rotate(-20deg);
}
[data-aos="hero-report14"] {
    transform: translateY(-60px) translateX(80px) rotate(-10deg);
}
[data-aos="hero-report15"] {
    transform: translateY(-60px) translateX(80px) rotate(-10deg);
}
[data-aos="report1"] {
    transform: translateY(40px) translateX(30px) rotate(-30deg);
}
[data-aos="report2"] {
    transform: translateY(-70px) translateX(-70px) rotate(50deg);
}
[data-aos="report3"] {
    transform: translateY(-80px) translateX(-50px) rotate(-20deg);
}
[data-aos="report4"] {
    transform: translateY(-60px) translateX(-70px) rotate(-10deg);
}
[data-aos="report5"] {
    transform: translateY(90px) translateX(60px) rotate(50deg);
}
[data-aos="report6"] {
    transform: translateY(-30px) translateX(-40px) rotate(-30deg);
}
[data-aos="report7"] {
    transform: translateY(-50px) translateX(80px) rotate(10deg);
}
[data-aos="report8"] {
    transform: translateY(-50px) translateX(70px) rotate(10deg);
}
[data-aos="report9"] {
    transform: translateY(50px) translateX(50px) rotate(-20deg);
}
[data-aos="report10"] {
    transform: translateY(-50px) translateX(-60px) rotate(-20deg);
}
[data-aos="report11"] {
    transform: translateY(-50px) translateX(40px) rotate(-30deg);
}
[data-aos="report12"] {
    transform: translateY(-00px) translateX(20px) rotate(-40deg);
}
[data-aos="report13"] {
    transform: translateY(70px) translateX(-70px) rotate(-20deg);
}
[data-aos="report14"] {
    transform: translateY(-60px) translateX(80px) rotate(-10deg);
}
[data-aos="report15"] {
    transform: translateY(-60px) translateX(80px) rotate(-10deg);
}
[data-aos="hero-services1"] {
    transform: translateY(40px) translateX(30px) rotate(-30deg);
}
[data-aos="hero-services2"] {
    transform: translateY(-70px) translateX(-70px) rotate(50deg);
}
[data-aos="hero-services3"] {
    transform: translateY(-80px) translateX(-50px) rotate(-20deg);
}
[data-aos="hero-services4"] {
    transform: translateY(-60px) translateX(-70px) rotate(-10deg);
}
[data-aos="hero-services5"] {
    transform: translateY(90px) translateX(60px) rotate(50deg);
}
[data-aos="hero-services6"] {
    transform: translateY(-30px) translateX(-40px) rotate(-30deg);
}
[data-aos="hero-services7"] {
    transform: translateY(-50px) translateX(80px) rotate(10deg);
}
[data-aos="hero-services8"] {
    transform: translateY(-50px) translateX(70px) rotate(10deg);
}
[data-aos="hero-services9"] {
    transform: translateY(50px) translateX(50px) rotate(-20deg);
}
[data-aos="hero-services10"] {
    transform: translateY(-50px) translateX(-60px) rotate(-20deg);
}
[data-aos="hero-services11"] {
    transform: translateY(-50px) translateX(40px) rotate(-30deg);
}
[data-aos="services-deploy1"] {
    transform: translateY(80px) translateX(-90px) rotate(-10deg);
}
[data-aos="services-deploy2"] {
    transform: translateY(-60px) translateX(-80px) rotate(-20deg);
}
[data-aos="services-deploy3"] {
    transform: translateY(90px) translateX(110px) rotate(-100deg);
}
[data-aos="services-deploy4"] {
    transform: translateY(70px) translateX(80px) rotate(-20deg);
}
[data-aos="services-deploy5"] {
    transform: translateY(-90px) translateX(-60px) rotate(-170deg);
}
[data-aos="services-deploy6"] {
    transform: translateY(60px) translateX(70px) rotate(90deg);
}
[data-aos="services-deploy7"] {
    transform: translateY(60px) translateX(-80px) rotate(-10deg);
}
[data-aos="services-deploy8"] {
    transform: translateY(-70px) translateX(-60px) rotate(-10deg);
}
[data-aos="services-deploy9"] {
    transform: translateY(-80px) translateX(-80px) rotate(10deg);
    transition: 2s;
}
[data-aos="services-deploy10"] {
    transform: translateY(-50px) translateX(-70px) rotate(20deg);
}
[data-aos="services-deploy11"] {
    transform: translateY(50px) translateX(-50px) rotate(-10deg);
}
[data-aos="services-deploy12"] {
    transform: translateY(110px) translateX(-50px) rotate(-50deg);
}
[data-aos="services-deploy13"] {
    transform: translateY(-50px) translateX(90px) rotate(-70deg);
}
[data-aos="services-deploy14"] {
    transform: translateY(50px) translateX(-50px) rotate(-10deg);
}
[data-aos="services-sand1"] {
    transform: translateY(-50px) translateX(-70px) rotate(-10deg);
}
[data-aos="services-sand2"] {
    transform: translateY(60px) translateX(-50px) rotate(-20deg);
}
[data-aos="services-sand3"] {
    transform: translateY(-70px) translateX(50px) rotate(30deg);
}
[data-aos="services-sand4"] {
    transform: translateY(-40px) translateX(-70px) rotate(-30deg);
    transition: 2s;
}
[data-aos="services-sand5"] {
    transform: translateY(80px) translateX(-70px);
}
[data-aos="services-sand6"] {
    transform: translateY(50px) translateX(-80px) rotate(-15deg);
}
[data-aos="services-sand7"] {
    transform: translateY(40px) translateX(80px) rotate(-10deg);
}
[data-aos="services-sand8"] {
    transform: translateY(50px) translateX(70px) rotate(20deg);
    transition: 2s;
}
[data-aos="services-sand9"] {
    transform: translateY(-40px) translateX(-70px) rotate(-30deg);
    transition: 2s;
}
[data-aos="services-sand10"] {
    transform: translateY(80px) translateX(-70px);
}
[data-aos="services-relentless1"] {
    transform: translateY(-50px) translateX(-70px) rotate(-10deg);
}
[data-aos="services-relentless2"] {
    transform: translateY(60px) translateX(-50px) rotate(-20deg);
}
[data-aos="services-relentless3"] {
    transform: translateY(-70px) translateX(50px) rotate(30deg);
}
[data-aos="services-relentless4"] {
    transform: translateY(-40px) translateX(-70px) rotate(-30deg);
    transition: 2s;
}
[data-aos="services-relentless5"] {
    transform: translateY(80px) translateX(-70px);
}
[data-aos="services-relentless6"] {
    transform: translateY(50px) translateX(-80px) rotate(-15deg);
}
[data-aos="services-relentless7"] {
    transform: translateY(40px) translateX(80px) rotate(-10deg);
}
[data-aos="services-relentless8"] {
    transform: translateY(50px) translateX(70px) rotate(20deg);
    transition: 2s;
}
[data-aos="services-relentless9"] {
    transform: translateY(-40px) translateX(-70px) rotate(-30deg);
    transition: 2s;
}
[data-aos="services-relentless10"] {
    transform: translateY(80px) translateX(-70px);
}
[data-aos="services-relentless11"] {
    transform: translateY(-40px) translateX(-70px) rotate(-30deg);
    transition: 2s;
}
[data-aos="services-up1"] {
    transform: translateY(-50px) translateX(-70px) rotate(-10deg);
}
[data-aos="services-up2"] {
    transform: translateY(60px) translateX(-50px) rotate(-20deg);
}
[data-aos="services-up3"] {
    transform: translateY(-70px) translateX(50px) rotate(30deg);
}
[data-aos="services-up4"] {
    transform: translateY(-40px) translateX(-70px) rotate(-30deg);
    transition: 2s;
}
[data-aos="services-up5"] {
    transform: translateY(80px) translateX(-70px);
}
[data-aos="services-up6"] {
    transform: translateY(50px) translateX(-80px) rotate(-15deg);
}
[data-aos="services-up7"] {
    transform: translateY(40px) translateX(80px) rotate(-10deg);
}
