/* Bootstrap */
@import "./node_modules/bootstrap/scss/bootstrap.scss";
/* Custom variables */
@import "variables";

/* Partials */
@import "common";
@import "animations-aos";
@import "scraps";
@import "header";
@import "dots";
@import "page-home";
@import "footer";
@import "page-services";
@import "page-report";
@import "page-availability-pricing";
@import "page-contact";
@import "page-about";
@import "page-career";
@import "page-practices";
@import "page-projects";
@import "page-terms";
@import "page-conversations";
@import "page-engage";
@import "forms";
@import "polygons-top";
@import "page-ai";
