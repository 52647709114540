.popup {
    width: 100%;
    height: 100%;
    box-shadow: -2px 14px 33px -13px rgba(224, 221, 224, 1);
    position: relative;
    overflow: auto;
    background-color: white;
    padding: 120px 24px 56px 24px;
    border: 1px solid transparent;
    border-radius: 5px;
    scrollbar-color: rgba(0, 0, 0, 0.2);
    scrollbar-width: thin;

    &--fuel {
        @include media-breakpoint-up(xl) {
            background-image: url('../images/form-background.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: bottom center;
        }

        .calendly-inline-widget {
            margin-top: 6.4rem;
        }
        .connect-step2 {
            max-width: 680px;
            margin-left: auto;
            margin-right: auto;        
        }
        .person-box {
            max-width: 100%;
            padding: 2.4rem;
            @include media-breakpoint-up(md) {
                padding: 7.5rem 7rem 5.5rem 7rem;
            }
        }
        .person-box__name {
            font-size: 2.4rem;
            @include media-breakpoint-up(lg) {
                font-size: 3.2rem;
            }
        }

    }

    &::-webkit-scrollbar {
        display: block;
        width: 15px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px;
        border-radius: 6px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: $violet-light;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    @include media-breakpoint-up(md) {
        padding: 6rem;
    }
    @include media-breakpoint-up(lg) {
        padding: 11rem;
    }
}
.collaboration-form-wrapper {
    max-width: 566px;
    padding-bottom: 8rem;
    margin-left: auto;
    margin-right: auto;
}

.popup-title {
    font-size: 1.2rem;
    font-weight: 700;
    position: relative;
    margin-bottom: 3.6rem;
    display: inline-block;
    letter-spacing: 1px;
    border-radius: 5px;
    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        left: 0;
        right: 0;
        position: absolute;
        bottom: -12px;
        background-color: $primary;
    }
}
.form-intro {
    .popup-subtitle {
        display: block;
        font-size: 3.2rem;
        font-weight: 700;
        margin-bottom: 4rem;
        line-height: 1.125;
        @include media-breakpoint-up(lg) {
            font-size: 4.2rem;
            margin-bottom: 5.6rem;
        }
    }
    p {
        font-size: 2.4rem;
        line-height: 1.25;
        font-weight: 600;
    }
}
.pa-form,
.career-form {
    margin-top: 5rem;
}
.form-group {
    margin-bottom: 1.6rem;
    @include media-breakpoint-up(lg) {
        margin-bottom: 2.6rem;
    }
}
input,
textarea {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid $form-border;
    border-radius: 8px;
    margin: 0;
    padding: 1.5rem 2rem;
    transition: $transition-time;
    &::placeholder {
        color: $font-lighter;
        font-size: 1.8rem;
    }
    &:focus,
    &:active {
        border: 1px solid $violet-light !important;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 1px $violet-light;
        outline: 0 none;
    }
}
textarea {
    min-height: 7.3rem;
    @include media-breakpoint-up(lg) {
        min-height: 12rem;
    }
}
input {
    &[type="text"] {
        word-wrap: break-word;
        word-break: break-all;
    }
    &[type="checkbox"] {
        position: relative;
        width: 24px;
        height: 24px;
        left: 0;
        z-index: 10;
        opacity: 0;
        margin-right: 24px;
        float: left;
        margin-bottom: 0;
        & + span:before {
            content: "";
            display: block;
            width: 22px;
            height: 22px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: $lightest;
            border: 1px solid $form-border;
            border-radius: 5px;
            transition: background 0.3s ease, border-color 0.3s ease;
        }
        &:checked + span:before {
            background-image: url("../images/check.svg");
            border-color: $violet-light;
            background-repeat: no-repeat;
            background-position: center 4px;
            background-color: $violet-light;
        }
    }
    &[type="submit"] {
        display: inline-block;
        width: auto;
    }
}
.checkboxes-row {
    margin-top: 2.4rem;
    .form-group {
        margin-bottom: 1.6rem;
    }
    label {
        margin-bottom: 0.8rem;
    }
}
.submit-wrapper {
    margin-top: 3.6rem;
}
.checkbox-wrapper {
    &:hover {
        cursor: pointer;
    }
}
label {
    display: block;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.45;
    display: block;
    margin-bottom: 0.8rem;
    @include media-breakpoint-up(lg) {
        margin-bottom: 2rem;
    }
}
.popup-wrapper {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    display: none;
}
.close-btn {
    position: absolute;
    position: fixed;
    right: 24px;
    top: 3.6rem;
    width: 48px;
    height: 48px;
    background-image: url("../images/close.svg");
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
    @include media-breakpoint-up(lg) {
        right: 5.6rem;
        top: 5.6rem;
    }
}
.popup-logo {
    position: absolute;
    top: 3.6rem;
    left: 24px;
    @include media-breakpoint-up(lg) {
        left: 5.6rem;
        top: 5.6rem;
    }
    &:hover {
        text-decoration: none;
    }
}
.popup-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: -20px;
    left: -20px;
    background-color: rgba(255, 255, 255, 0.9);
}
.fuel-form {
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
    .col-half {
        width: 100%;
        @include media-breakpoint-up(lg) {
            width: calc(50% - 12px);
        }
    }
}
.connect-form {
    .col-half {
        @include media-breakpoint-up(lg) {
            width: calc(50% - 12px);
        }
    }
}
.connect-step2 {
    display: none;
    .person-box {
        box-shadow: none;
        background-color: $violet-lightest;
        margin-bottom: 5rem;
        &:hover {
            box-shadow: none;
        }
        @media screen and (min-width: 1600px) {
            padding: 9rem 10rem 5.5rem 10rem;
        }
        &__img-wrapper {
            top: -36px;
        }
        .btn {
            &:after {
                display: none;
            }
        }
        &__img-wrapper {
            z-index: 2;
        }
        &__logo-wrapper {
            text-align: center;
            margin-bottom: 2.4rem;
            img {
                width: 64px;
                height: 64px;
                border-radius: 50%;
            }
        }
        &__icon {
            position: absolute;
            top: -25px;
            left: 50%;
            transform: translateX(-50%);
            width: 285px;
            height: 285px;
        }
        &__intro {
            margin-bottom: 3rem;
        }
        &__intro,
        &__name,
        &__occupation,
        &__summary {
            position: relative;
            z-index: 2;
        }
        &__summary {
            margin-bottom: 4rem;
            font-size: 2.4rem;
            font-weight: 700;
        }
    }
    .popup-subtitle {
        margin-bottom: 9.3rem;
    }
}
.js-ajax-form-message {
    display: none;
}
.form__group-errors {
    display: block;
    margin: 0;
    padding: 3px 0 0;
    li {
        display: block;
        margin: 0;
        padding: 0;
        color: $alert-color;
        font-size: 12px;
        list-style-type: none;
        @include media-breakpoint-up(lg) {
            font-size: 14px;
        }
    }
}
.form-message {
    display: none;
    width: 100%;
    margin: 15px 0 0;
    padding: 15px 30px;
    color: $lightest;
    font-size: 15px;
    border-radius: 5px;
    text-align: center;
    @include media-breakpoint-up(lg) {
        padding-right: 20px;
        padding-left: 20px;
        font-size: 17px;
    }
    &.is-success {
        background: $green-light;
    }
    &.is-error {
        background: $alert-color;
    }
}
.h-form {
    display: none;
}
.pricing-thanks {
    p {
        font-size: 2.4rem;
        font-weight: 600;
    }
}
.pricing-step2,
.career-step2 {
    display: none;
}
.popup.popup-shrink {
    height: 100%;
}
.popup--fuel {
    .checkboxes-row {
        margin-bottom: 2.4rem;
    }
}
