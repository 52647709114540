.main-header {
    padding-top: 25px;
    padding-bottom: 25px;
    height: 128px;
    min-height: 128px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1001;
    background-color: transparent;
    transition: 0.35s all ease-in-out;
    @include media-breakpoint-up(md) {
        padding-left: 45px;
        padding-right: 45px;
    }
    @include media-breakpoint-down(lg) {
        padding-left: 24px;
        padding-right: 24px;
    }
    @include media-breakpoint-up(xl) {
        padding-top: 5.6rem;
    }
    &.header-fixed {
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: white;
        box-shadow: 0 3px 6px #48484812;
        height: 73px;
        min-height: 73px;
        .scrap {
            opacity: 0;
        }
    }
    .logo-wrapper {
        &:hover {
            text-decoration: none;
        }
    }
    &__logo {
        width: 48px;
        height: 48px;
        object-fit: contain;
    }
    &__title {
        font-size: 1.8rem;
        font-weight: 700;
        padding-left: 1.5rem;
        color: $main-font-color;
        @include media-breakpoint-up(lg) {
            font-size: 2.4rem;
        }
    }
    .menu-btn {
        width: 48px;
        height: 48px;
        border-radius: 48px;
        justify-content: center;
        align-items: flex-end;
        background-color: $violet-light;
        border: none;
        padding: 16px;
        transition: background-color 0.25s ease-in-out;
        &:active,
        &:hover {
            background-color: $violet-dark;
        }
        &:active,
        &:hover,
        &:focus {
            outline: none;
        }
        span {
            display: flex;
            height: 2px;
            background-color: $lightest;
            border-radius: 8px;
            transition: all 0.25s ease-in-out;
            &:not(:last-of-type) {
                margin-bottom: 5px;
            }
            &:nth-of-type(2) {
                width: 16px;
            }
            &:nth-of-type(3) {
                width: 12px;
            }
            &:nth-of-type(4) {
                width: 16px;
            }
        }
        &--close {
            span {
                &:nth-of-type(2) {
                    transform: translate(0, 7px) rotate(-45deg);
                }
                &:nth-of-type(3) {
                    opacity: 0;
                }
                &:nth-of-type(4) {
                    transform: translate(0, -7px) rotate(45deg);
                }
            }
        }
    }
    .nav-list {
        @include media-breakpoint-up(xl) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &--conversations {
        .btn--light {
            margin-left: 2.4rem;
        }
    }
}

// MOBILE MENU
.mobile__menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: -9999;
    width: 100%;
    height: 100vh;
    background: $lightest;
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    align-items: center;
    background-image: url("../images/menu-bcg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &--open {
        opacity: 1;
    }
    &-content {
        position: relative;
        flex: 1;
        padding-top: 5rem;
        margin-top: 90px;
        width: 100%;
        max-width: 548px;
        margin-left: auto;
        margin-right: auto;
        overflow: auto;
        align-items: center;
        @media only screen and (min-width: 375px) and (min-height: 480px) {
            min-height: unset;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                text-align: center;
                a {
                    position: relative;
                    display: inline-block;
                    font-size: 1.6rem;
                    color: $main-font-color;
                    font-weight: bold;
                    font-weight: 700;
                    padding-bottom: 1.4rem;
                    &:hover {
                        text-decoration: none;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 2px;
                        background: $violet-light;
                        transition: transform 0.25s ease-in-out;
                        transform: scaleX(0);
                    }
                }
                & > a.current,
                &:hover > a {
                    &:after {
                        transform: scaleX(1);
                    }
                }
                &:not(:last-child) {
                    margin-bottom: 16px;
                    @media only screen and (min-width: 375px) and (min-height: 480px) {
                        margin-bottom: 3.1vh;
                    }
                }
            }
        }
    }
    button.btn {
        display: inline-block;
        margin-top: 24px;
        margin-bottom: 55px;
        padding: 23.5px 37px;
        max-width: 218px;
        position: relative;
        // left: 50%;
        // transform: translateX(-50%);
        @media only screen and (min-width: 375px) and (min-height: 480px) {
            margin-top: 2.96vh;
            margin-bottom: 6.78vh;
        }
    }
    @include media-breakpoint-up(xl) {
        display: none;
    }
}
.top-wrapper {
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        width: 100%;
    }
}
