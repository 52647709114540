.icon-wrapper--primary {
    background-color: $primary;
}
.hero-contact {
    margin-bottom: 16.5rem;
    @include media-breakpoint-up(md) {
        margin-bottom: 20.5rem;
    }
    &__row {
        @include media-breakpoint-up(md) {
            margin-top: 5.4rem;
        }
        .dots-wrapper {
            bottom: -4rem;
        }
    }
    &__icons {
        padding-top: 4rem;
        @include media-breakpoint-up(md) {
            padding-top: 17rem;
        }
        span {
            font-weight: 600;
        }
    }
    .dots-wrapper {
        bottom: -5.6rem;
    }
    .contact-subtitle {
        font-size: 2rem;
        font-weight: 700;
        display: inline-block;
        margin-top: 2rem;
        @include media-breakpoint-up(md) {
            font-size: 2.4rem;
        }
    }
}
.contact-img {
    width: 325px;
    height: 290px;
    margin-top: 3.5rem;
    @include media-breakpoint-up(lg) {
        width: 385px;
        height: 332px;
    }
    @include media-breakpoint-up(xl) {
        height: 405px;
        width: 453px;
        max-width: 453px;
    }
}
.contact-form-section {
    .border-row {
        padding-bottom: 5.5rem;
        @include media-breakpoint-up(lg) {
            padding-bottom: 12rem;
        }
    }
}
.person-box.person-box--small {
    @include media-breakpoint-down(lg) {
        padding: 9rem 1rem 4.2rem 1rem;
    }
    margin-top: 8rem;
    .btn {
        padding: 2.5rem 3rem;
        margin-top: 3.5rem;
        @include media-breakpoint-up(lg) {
            padding: 2.5rem 4rem;
        }
        &:after {
            display: none;
        }
    }
    a {
        font-size: 1.8rem;
        font-weight: 500;
        color: #000000;
    }
    .icon-wrapper {
        width: 30px;
        height: 30px;
        img {
            width: 20px;
            height: 15px;
        }
    }
    .btn {
        font-size: 1.2rem;
        letter-spacing: 1px;
        font-weight: 700;
    }
}
.lily-widget {
    margin-top: 5.5rem;
}
.contact-form,
.connect-form,
.pa-form,
.career-form {
    max-width: 565px;
    margin-left: auto;
    margin-right: auto;
    .col-half {
        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }
}
.item-inside {
    &-left {
        @include media-breakpoint-up(lg) {
            padding-left: 11rem;
        }
    }
    &-right {
        @include media-breakpoint-up(lg) {
            padding-right: 11rem;
        }
    }
}
.contact-icon {
    @include media-breakpoint-up(md) {
        margin-bottom: 5rem;
    }
}
.forms-row {
    margin-top: 5.5rem;
    @include media-breakpoint-up(lg) {
        margin-top: 11.5rem;
    }
    .section__subtitle--small {
        font-size: 2.4rem;
        margin-bottom: 9rem;
    }
}
.contact-box {
    @include media-breakpoint-down(lg) {
        margin-top: 10rem;
    }
}
