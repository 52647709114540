[data-aos="top-text-animation"] {
  transform: translateY(-30px);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateY(0);
    opacity: 1;
  }
}

[data-aos="big-text-animation"] {
  transform: translateY(-10px);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateY(0);
    opacity: 1;
  }
}

[data-aos="small-text-animation"] {
  transform: translateY(-5px);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateY(0);
    opacity: 1;
  }
}

[data-aos="feature-icon-animation"] {
  background-color: $violet-lightest;
  transition-property: background-color;
  &.aos-animate {
    background-color: $violet-light;
  }
}

[data-aos="line-icon-animation"] {
  transform: translateX(-100%);
  transition-property: transform;
  &.aos-animate {
    transform: translateX(0);
  }
}

.page-pricing {
  [data-aos="drawingLine1"] {
    width: 0;
    transition-property: width;
    &.aos-animate {
      width: 95px;
      @include media-breakpoint-up(lg) {
        width: 198px;
      }
    }
  }

  [data-aos="drawingLine2"] {
    width: 0;
    transition-property: width;
    &.aos-animate {
      width: 202px;
      @include media-breakpoint-up(lg) {
        width: 312px;
      }
    }
  }

  [data-aos="drawingLine3"] {
    width: 0;
    transition-property: width;
    &.aos-animate {
      width: 310px;
      @include media-breakpoint-up(lg) {
        width: 512px;
      }
    }
  }
}

.page-projects {
  [data-aos="drawingLine1"] {
    width: 0;
    transition-property: width;
    &.aos-animate {
      width: 120px;
      @include media-breakpoint-up(lg) {
        width: 198px;
      }
    }
  }

  [data-aos="drawingLine2"] {
    width: 0;
    transition-property: width;
    &.aos-animate {
      width: 200px;
      @include media-breakpoint-up(lg) {
        width: 312px;
      }
    }
  }
  [data-aos="drawingLine3"] {
    width: 0;
    transition-property: width;
    &.aos-animate {
      width: 300px;
      @include media-breakpoint-up(lg) {
        width: 512px;
      }
    }
  }
}

[data-aos="blue-line-drawing"] {
  width: 0;
  transition-property: width;
  &.aos-animate {
    width: calc(100% + 5.4rem);
  }
}
