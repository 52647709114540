.legal {
  .update {
    font-size: 1.8rem;
    display: block;
    margin-top: 3rem;
  }
  .section__text {
    h3 {
      font-size: 2.4rem;
      font-weight: 700;
      margin-bottom: 2rem;
    }
  }
}
.page-terms {
  .hero {
    @include media-breakpoint-down(lg) {
      margin-bottom: 8rem;
    }
  }
}
