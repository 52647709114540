
.page-engage {
    padding-top: 0;
    main {
        padding-top: 0;
    }
    .mobile__menu-content {
        margin-top: 70px;
        ul {
            margin-bottom: 1.6rem;
        }
    }
    .footer {
        .border-row {
            position: static;
        }
    }

    .engage-btn-outside {
        display: none;
        &.engage-btn-hidden {
            display: none;
        }
    }

    .header-fixed {
        .engage-btn-outside {
            display: inline-block;
            &.engage-btn-hidden {
                display: none;
            }
        }
    }

}

.engage-hero {
    overflow: hidden;
    position: relative;
    padding-top: calc(3rem + 128px);
    padding-bottom: 4.8rem;
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
        padding-top: calc(8rem + 128px);
    }
    @include media-breakpoint-up(lg) {
        padding-top: calc(10rem + 128px);
    }

    .hero__subtitle {
        max-width: unset;
    }

    .btn--big {
        margin-bottom: 0;
    }

    .dots-wrapper {
        bottom: calc(11.2rem + 6.4rem);
    }

    .btn-scroll-wrapper {
        margin-top: 7.2rem;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
    .hero__subtitle {
        @include media-breakpoint-down(md) {
            font-size: 1.8rem;
        }
    }
}

.widget-sticky {
    height: 580px;
    height: 650px;

    @media screen and (min-width: 678px) {
        height: 700px;
        height: 720px;
    }    

    @media screen and (min-width: 992px) and (min-height: 678px) {
        position: sticky !important;
        top: 9rem;
    }    

    .widget-hero {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        &__img {
            width: 5.6rem;
            height: 5.6rem;
            margin-bottom: 1.6rem;
        }
        &__subtitle {
            color: $font-lighter;
            font-weight: 600;
            display: none;
            @media screen and (min-height: 730px) {
                display: block;
            }
        }
        &__title {
            @extend .section__subtitle;
            margin-bottom: 0;
        }

    }

    .calendly-inline-widget {
        height: 600px!important;
        overflow: hidden!important;

        @media screen and (min-width: 678px) {
            height: 720px!important;
        }    

    }
}

.zoom-container {
    border: 3px solid #CFD5E9;
    border-radius: 8px;
    margin-top: 6.4rem;
    position: relative;
    width: calc(100% + 32px);
    left: -16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
    z-index: 2;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
        border: 8px solid #CFD5E9;
        border-radius: 24px;
        margin-top: 9.6rem;
        width: auto;
        left: unset;
        margin-bottom: 4.8rem;
    }

    &__timer {
        position: absolute;
        z-index: 3;
        width: 7rem;
        height: 7rem;
        top: -2.8rem;
        left: 50%;
        transform: translateX(-50%);

        @include media-breakpoint-up(sm) {
            width: 12rem;
            height: 12rem;
            top: -6.4rem;
        }

        @include media-breakpoint-up(md) {
            width: 14.8rem;
            height: 14.8rem;
            top: -6.4rem;
        }

        @include media-breakpoint-up(lg) {
            left: unset;
            top: -4.8rem;
            right: -4.8rem;
            transform: none;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__people {
        padding: 1rem;
        display: flex;
        width: 100%;
        justify-content: space-between;
        @include media-breakpoint-up(sm) {
            padding: 2.4rem;
        }
        @include media-breakpoint-up(lg) {
            padding: 3.2rem;
        }
    }
    &__people-single {
        border: 2px solid #EBEEF9;
        border-radius: 16px;
        background-color: #FAFAFE;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: calc(33.33% - 0.5rem);
        padding-left: 1.8rem;
        padding-right: 1.8rem;

        @include media-breakpoint-up(sm) {
            padding-top: 2.4rem;
            padding-bottom: 2.4rem;
            width: calc(33.33% - 1.6rem);

        }
        @include media-breakpoint-up(xl) {
            padding-top: 6.5rem;
            padding-bottom: 6.5rem;
        }

        &:first-of-type {
            border: 3px solid #7386E5;
        }

        img {
            display: inline-block;
            &:first-of-type {
                max-width: 100%;
                margin-top: 5.6rem;
                margin-bottom: 5.6rem;

                @include media-breakpoint-up(lg) {
                    margin-top: 7.6rem;
                    margin-bottom: 7.6rem;
                }
                @media screen and (min-width: 1700px) {
                    margin-top: 14.3rem;
                    margin-bottom: 14.3rem;
                }
            }
        }

        .zoom-sound {
            position: absolute;
            top: 0.5rem;
            left: 0.5rem;
            width: 1.4rem;
            @include media-breakpoint-up(md) {
                top: 1.6rem;
                left: 1.6rem;
                width: 2.4rem;
            }
            @include media-breakpoint-up(lg) {
                width: 4.4rem;
            }
        }

        span {
            position: absolute;
            font-size: 0.75rem;
            font-weight: 600;
            left: 1.4rem;
            bottom: 0.8rem;
            @include media-breakpoint-up(sm) {
                font-size: 1.4rem;
                left: 3.2rem;
                bottom: 2.4rem;
            }
        }
    }
    &__top {
        @include media-breakpoint-up(sm) {
            position: relative;
            top: -3px;
        }
        @include media-breakpoint-up(lg) {
            position: static;
        }
    }
    &__bottom {
        position: relative;
        bottom: 0;
        @include media-breakpoint-up(lg) {
            bottom: -7px;
        }
    }
}

.zoom-desc {
    position: relative;

    @include media-breakpoint-up(md) {
        padding-top: 4rem;
    }
    @media screen and (min-width: 680px) {
        margin-bottom: 16.4rem;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
    .text-part {
        max-width: 60rem;
    }
    .section__title {
        text-align: left;
        &--small {
            max-width: unset;
        }
    }
    .section__subtitle {
        // @include media-breakpoint-down(lg) {
            font-size: 2.8rem;
        // }
    }
    .section__text {
        @include media-breakpoint-up(lg) {
            max-width: 680px;
        }

        ul.list-simple {
            @include media-breakpoint-down(md) {
                margin-top: 3.2rem;
            }
        }
    }
    .anchor {
        position: absolute;
        top: -9rem;
    }
}

.zoom-quote {
    border: 1px solid #EBEEF9;
    border-radius: 8px;
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 4.8rem;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: unset;
        text-align: left;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
        padding: 4.8rem;
    }

    &__img {
        position: relative;
        margin-bottom: 2.4rem;
        &:before {
            content: '';
            display: block;
            width: 3.2rem;
            height: 3.2rem;
            border-radius: 50%;
            top: 0;
            right: 0;
            z-index: 2;
            position: absolute;
            background-image: url(../images/blockquote-dark.svg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            margin-right: 4.8rem;
        }
        img {
            width: 12rem;
            height: 12rem;
            border-radius: 50%;
        }
    }
    &__text {
        font-style: italic;
        font-weight: 500;
        p {
            font-size: 1.8rem;
            margin-bottom: 3.2rem;
        }
        span {
           color:  $font-lighter;
           font-size: 1.6rem;
        }
    }
}

